import { Field, Form, Formik } from 'formik'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { Step, Task, TaskMentor } from '../types/types'
import { KTSVG } from '_metronic/helpers'
import { useParams } from 'react-router-dom'
import { assignTasks, getMenteeInfo } from '../api/requests'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getSteps } from 'api/requests'
import clsx from 'clsx'
interface IMenteeInfo {
  id: number
  username: string
  total_availability_for_period: number
  company: string
  availability_per_week: number
  mentorship_period: string
  filled_hours: number
  requested_mentorship_period_end: string
  requested_mentorship_period_start: string
}
interface Props {
  tasks: TaskMentor[] | null
  assignedTasks: Task[]
  filledHours: number | undefined
  availabilityPerWeek: number | undefined
  totalAvailable: number | undefined
  setTasks: any
  setAssignedTasks: any
  setMenteeInfo: Dispatch<SetStateAction<IMenteeInfo | null>>
}
const AssignTaskCard: React.FC<Props> = ({
  setMenteeInfo,
  tasks,
  setTasks,
  filledHours,
  totalAvailable,
  availabilityPerWeek,
  assignedTasks,
  setAssignedTasks,
}) => {
  let { mentorshipId, menteeId } = useParams()
  const [taskName, setTaskName] = useState<string | null>(null)
  const [steps, setSteps] = useState<Step[]>([])
  const [searchQuery, setSearchQuery] = useState('');

  const [isModalLoading, setIsModalLoading] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [availHours, setAvailHours] = useState<number>(totalAvailable || 0)
  const [fillHours, setfillHours] = useState<number>(filledHours || 0)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [isAssigned, setIsAssigned] = useState<boolean>(false)
  const [fill, setFill] = useState<number>(0)
  const filteredTasks = tasks?.filter(task =>
    task.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  console.log(fillHours)

  const submitHandler = async (values: any) => {
    console.log(values)
    setIsLoading(true)
    const tasks = values.selectedTasks.map((str) => parseInt(str, 10))
    try {
      const response = await assignTasks(mentorshipId, tasks)
      if (response.data) {
        setAssignedTasks(response.data.assigned_tasks)
      }
      const fetchedMenteeInfo = await getMenteeInfo(menteeId)
      setMenteeInfo(fetchedMenteeInfo.data)
      toast.success('Tasks were successfully assigned!')
      setIsAssigned(true)
      window.location.replace(`/program`)
    } catch (err: any) {
      toast.error(err.response.data.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <div className='card mt-10'>
        <div className='card-header'>
          <div className='card-title'>
            <h3 className='card-label'>Assign Task</h3>
          </div>
        </div>
        <div className='card-body container'>
          <div className='d-flex row'>
            <Formik
              initialValues={{
                selectedTasks: assignedTasks.map((task) => task.task_id.toString()),
              }}
              onSubmit={async (values) => {
                await submitHandler(values)
              }}
            >
              {({ values }) => (
                <Form>
                  <div className='d-flex row'>
                    <div className='mx-10 col-xl-5 col-md-12 d-flex flex-column justify-content-center'>
                      <h3>Mentee Availability</h3>
                      <p style={{ width: '70%', textAlign: 'left' }}>
                        Mentee has {availHours} hours of availability. You can only assign tasks
                        that will fulfill that. After you fulfill the hours you won't be able to add
                        any other tasks:
                      </p>
                      <p>
                        Total Available Hours: <span className='fw-bold'>{availHours}</span>
                      </p>
                      <p>
                        Filled hours: <span className='fw-bold'>{fillHours}</span>
                      </p>
                      <p>
                        Available hours:
                        <span className='fw-bold'>
                          {availHours && fillHours && (availHours - fillHours).toFixed(1)}
                        </span>
                      </p>
                    </div>
                    <div className='col form-check form-check-custom form-check-solid d-flex flex-column justify-content-start align-items-start'>
                      <input
                        type='text'
                        className='form-control form-control-white'
                        name='search'
                        placeholder='Search...'
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                        data-kt-search-element='input'
                      />

                      <div className='d-flex flex-column justify-content-start' style={{ minHeight: 400 }}>
                        {tasks &&
                          filteredTasks?.map((task) => (
                            <div key={task.id} className='mx-3 mt-3 d-flex flex-row'>
                              <Field
                                type='checkbox'
                                className='form-check-input'
                                name='selectedTasks'
                                value={task.id.toString()}
                                id={`task_${task.id.toString()}`}
                                disabled={isLoading}
                                onClick={(e) => {
                                  console.log(availHours)

                                  const isChecked = e.target.checked
                                  const selectedTaskId = parseInt(e.target.value, 10)
                                  const selectedTask = tasks.find(
                                    (task) => task.id === selectedTaskId
                                  )

                                  if (
                                    isChecked &&
                                    selectedTask &&
                                    selectedTask.total_hours != null
                                  ) {
                                    setFill((prevValue) => { console.log('1 ', prevValue + selectedTask.total_hours); return prevValue + selectedTask.total_hours })
                                    setfillHours((prevFillHours) => {
                                      if (
                                        availHours - (prevFillHours + selectedTask.total_hours) < 0
                                      ) {
                                        setIsDisabled(true)
                                      } else {
                                        setIsDisabled(false)
                                      }
                                      return (prevFillHours || 0) + selectedTask.total_hours
                                    })
                                  } else if (
                                    !isChecked &&
                                    selectedTask &&
                                    selectedTask.total_hours != null
                                  ) {
                                    setFill((prevValue) => { console.log('2 ', prevValue - selectedTask.total_hours); return prevValue - selectedTask.total_hours })
                                    setfillHours((prevFillHours) => {
                                      console.log(availHours - ((prevFillHours || 0) + selectedTask.total_hours))

                                      if (
                                        availHours - (prevFillHours - selectedTask.total_hours) > 0
                                      ) {
                                        setIsDisabled(false)
                                      } else {
                                        setIsDisabled(true)
                                      }
                                      return (prevFillHours || 0) - selectedTask.total_hours
                                    })
                                  }
                                }}
                              />
                              <label
                                onClick={async (e) => {
                                  e.preventDefault()
                                  setIsModalLoading(true)
                                  const stepsInfo = await getSteps(task.id)
                                  setSteps(stepsInfo.data)
                                  setTaskName(task.name)
                                  setIsModalLoading(false)
                                }}
                                data-bs-toggle='modal'
                                data-bs-target='#view-steps'
                                htmlFor={`task_${task.id}`}
                                className='cursor-pointer form-check-label text-hover-primary'
                              >
                                {task.total_hours == null
                                  ? `${task.name}`
                                  : `${task.name}-${task.total_hours} hours`}
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className='card-footer d-flex justify-content-end mt-10'>
                    <button
                      disabled={isLoading || isAssigned }
                      type='submit'
                      className={clsx(`${(isDisabled || fill === 0) ? 'disabled' : ''} btn btn-lg btn-primary`)}
                    >
                      {isLoading ? 'Please wait...' : 'Assign'}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className='modal fade' tabIndex={-1} id='view-steps'>
          <div className='modal-dialog mw-600px d-flex align-items-center'>
            <div className='modal-content'>
              {isModalLoading ? (
                <div className='v-100 position-relative' style={{ minHeight: '200px' }}>
                  <div className='modal-header w-100 d-flex justify-content-between'>
                    <h3 className='modal-title'></h3>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='page-loading position-absolute' style={{ top: '65%', left: '50%' }}>
                    <span
                      className='spinner-border text-primary'
                      data-kt-app-page-loading='on'
                      role='status'
                    >
                      <span className='visually-hidden'>Loading...</span>
                    </span>
                  </div>
                </div>
              ) : (
                <div>
                  <div className='modal-header w-100 d-flex justify-content-between'>
                    <h3 className='modal-title'>{taskName}</h3>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body'>
                    <div className=''>
                      <h2 className='mb-5'>Steps</h2>
                      {isModalLoading ? (
                        <div className='v-100 position-relative' style={{ minHeight: '100px' }}>
                          <div
                            className='page-loading position-absolute'
                            style={{ top: '50%', left: '50%' }}
                          >
                            <span
                              className='spinner-border text-primary'
                              data-kt-app-page-loading='on'
                              role='status'
                            >
                              <span className='visually-hidden'>Loading...</span>
                            </span>
                          </div>
                        </div>
                      ) : steps.length > 0 ? (
                        steps.map((step) => (
                          <div
                            className='mb-5 px-3 fw-bold'
                            key={step.id}
                            style={{ borderLeft: '3px solid #E4E6EF', color: '#3F4254' }}
                          >
                            <p className=''>{step.name}</p>
                          </div>
                        ))
                      ) : (
                        <div>
                          <p className='px-3 py-1' style={{ borderLeft: '3px solid #E4E6EF' }}>
                            There are no steps on this tasks
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className='modal-footer'>
                <div className='h-10px'></div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  )
}

export default AssignTaskCard
