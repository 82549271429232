import { Route, Routes } from 'react-router-dom';
import ProgramInfo from './pages/MentorshipTasks.page';
import MenteeStepInfo from './pages/MenteeStepInfo.page';
import Programs from './pages/Programs.page';

const MenteeProgramWrapper = () => {
    return (
        <Routes>
            <Route
                path='/*'
                element={<Programs />}>
            </Route>
            <Route
                path='/mentorship/:mentorshipId'
                element={<ProgramInfo />}>
            </Route>
            {/* <Route
                path='/mentorship/:id/mentee/:menteeId'
                element={<MenteeInfo />}>
            </Route> */}
            <Route
                path='/mentorship/:mentorshipId/mentorship-task-step/:mentorshipTaskStepId'
                element={<MenteeStepInfo />}>
            </Route>
        </Routes>
    );
};

export default MenteeProgramWrapper;