import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { SubmitTaskReviewValues } from 'types/types';
import { submitTaskReview } from 'api/requests';

const initialValues = {
    message: '',
    level: 0
};
const validationSchema = Yup.object().shape({
    message: Yup.string().required('Textarea is required'),
    level: Yup.number().required('Please rate it')
});


const SubmitReview: React.FC<{ setIsToastSuccess: (message: string) => void, setIsToastError: (message: string) => void, user: string }> = ({ setIsToastSuccess, setIsToastError, user }) => {
    let { mentorshipTaskStepId } = useParams();
    const [isSent, setIsSent] = useState<boolean>(false);
    const submitForm = async (values: SubmitTaskReviewValues) => {
        if (mentorshipTaskStepId) {
            await submitTaskReview(mentorshipTaskStepId, values)
        }
    }
    return (
        <div className='card'>
            <div className="card-header m-0">
                <div className="card-title">
                    <h3 className="card-label">
                        Submit Task Review
                    </h3>
                </div>
            </div>

            <div className='card-body m-0 p-0 pt-3 pb-5'>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(true);
                        try {
                            await submitForm(values);
                            window.location.reload()
                        } catch (err: any) {
                            setIsToastError(err.response.data.message);
                        }
                        setIsSent(true)
                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting, values, setFieldValue }) => (
                        <Form className=''>
                            <div className="rating w-100 mx-8" style={{ display: 'inline' }}>
                                {[1, 2, 3, 4, 5].map((value) => (
                                    <React.Fragment key={value}>
                                        <input
                                            className="rating-input"
                                            name="level"
                                            value={value}
                                            type="radio"
                                            id={`kt_rating_input_${value}`}
                                            checked={values.level + 1 === value}
                                            onChange={() => setFieldValue('level', value)}
                                        />
                                        <label
                                            className="rating-label"
                                            htmlFor={`kt_rating_input_${value}`}
                                            onClick={() => setFieldValue('level', value)}
                                        >
                                            <i className="ki-duotone ki-star fs-1"></i>
                                        </label>
                                    </React.Fragment>
                                ))}
                                <ErrorMessage name="level" component="div" className="text-danger" />

                            </div>
                            <div className="separator my-3"></div>
                            <div className="form-group mb-1 px-8">
                                <Field as="textarea" rows={5} placeholder="Type a review..." className="form-control" id="message" name="message" />
                                <ErrorMessage name="message" component="div" className="text-danger" />
                            </div>
                            <div className="card-footer">
                                <button type="submit" className="btn btn-primary" disabled={isSent}>
                                    {isSubmitting ? 'Please wait...' : 'Send'}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default SubmitReview;