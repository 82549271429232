import {Dropdown1} from '_metronic/partials'
import {KTIcon} from '_metronic/helpers/components/KTIcon'
import React, { useState } from 'react'
import {convertTimestamp} from 'utils/stringDateFunctions'
import {IUpcomingMeetings} from '../DashboardWrapper'
import { UpcomingMeetingModal } from './UpcomingEventModal.component'
import { IUpcomingEvents } from 'app/pages-mentee/dashboard/DashboardWrapper'
type Props = {
  className: string
  meetings: IUpcomingMeetings[]
}
const UpcomingMeetings: React.FC<Props> = ({className, meetings}) => {

   const [upcomingMeeting, setUpcomingMeeting] = useState<IUpcomingMeetings>();

  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5' style={{display: 'block'}}>
        <h3 className='card-title text-dark'>Upcoming Meeting</h3>
        <p className='text-secondary'>Upcoming events from your schedule.</p>
      </div>

      <div className='card-body pt-0'>
        {meetings?.length <= 0 ? (<h3>No upcoming events</h3>) : (
          meetings?.map((event) => (
            <div className='d-flex align-items-center rounded p-5 mb-5'>
              <span className=' text-warning me-5 rounded' style={{backgroundColor: '#DADCE4'}}>
                <KTIcon iconName='category' className='text-secondary fs-1 m-4' />
              </span>
              <div className='flex-grow-1 me-2' style={{width: '50%'}}>
                <a
                  href='#'
                  className='fw-bold text-gray-800 text-hover-primary fs-6'
                  style={{whiteSpace: 'pre-line'}}
                >
                  {event.program}
                </a>
                <span className='text-muted fw-semibold d-block'>
                  Moderator: {event.with_user.name}
                </span>
              </div>
              <div className='flex-grow-1 me-2'>
                <span className='fw-bold text-gray-800 text-hover-primary fs-6'>
                  {convertTimestamp(event.start_date).date}
                </span>
                <span className='text-muted fw-semibold d-block'>
                  {convertTimestamp(event.start_date).time}
                </span>
              </div>
  
              <button
                className='btn btn-light py-2 px-2 '
                data-bs-toggle='modal'
                data-bs-target='#upcoming_event'
                onClick={() => {
                  setUpcomingMeeting(event)
                }}
              >
                <KTIcon iconName='arrow-right' className='text-success fs-1' />
              </button>
            </div>
          ))
        )}
        
      </div>

      <UpcomingMeetingModal upcomingMeeting={upcomingMeeting} />
    </div>
  )
}

export default UpcomingMeetings
