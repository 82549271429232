import { toAbsoluteUrl } from '_metronic/helpers';
import React from 'react';

const LargeBtn = ({ isDisable, modal, text, imgUrl }) => {
    console.log(isDisable)
    return (
        <div className='card' style={{ width: '30%', maxHeight: 200 }}>
            <div className='px-10 card-body d-flex align-self-center justify-content-center'>
                <img className='d-flex align-self-center' alt='Pic' src={toAbsoluteUrl(`${imgUrl}`)} />
            </div>
            <div className='card-footer p-0 m-0'>
                <button type='button' className='btn btn-primary h-100 w-100' data-bs-toggle="modal" data-bs-target={modal} disabled={isDisable}>
                    {text}
                </button>
            </div>
        </div>
    );
};

export default LargeBtn;