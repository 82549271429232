import { useState } from "react";
import { KTIcon } from "../../_metronic/helpers";
import { ChatInner } from "../../_metronic/partials";
import axios from "axios";
import { API_URL } from "app/consts/consts";

const ContactAdmin = () => {
    const [adminInfo, setAdminInfo] = useState<any>(null);
    const getAdminInfo = async () => {
        await axios
            .get(`${API_URL}/users/management/get-admin`).then((res) => {
                setAdminInfo(res.data);
            }
            )
    }

    const chatToggle = () => {
        const chat = document.getElementById('kt_drawer_admin_chat_messenger');
        if (chat) {
            if (chat.style.display === 'none') {
                chat.style.display = 'block';
            } else {
                chat.style.display = 'none';
            }
        }
    }

    const allChat = () => {
        // kt_drawer_chat_messenger_component
        chatToggle();
        const chat = document.getElementById('kt_drawer_chat_messenger_component');
        if (chat) {
            if (chat.style.display === 'none') {
                chat.style.display = 'block';
            } else {
                chat.style.display = 'none';
            }
        }
    }
    getAdminInfo();

    return (
        <>
            <div className='btn btn-primary d-flex justify-content-end'
                onClick={() => chatToggle()}
            >
                <KTIcon iconName='bi bi-chat-right-text-fill' className='text-white fs-1 p-0' />
                <p className='p-0 my-0' style={{ marginLeft: 7 }}>Contact Admin</p>
            </div>
            <div
                id='kt_drawer_admin_chat_messenger'
                style={{
                    display: "none",
                    position: "fixed",
                    bottom: "5px",
                    right: "20px",
                    // Size
                    width: "400px",
                    // width: "420px",
                    height: "75vh",
                    maxWidth: "calc(100% - 48px)",
                    maxHeight: "calc(100% - 48px)",
                    backgroundColor: "white",
                    // Border
                    borderRadius: "12px",
                    overflow: "hidden",
                    // Shadow
                    boxShadow: "0px 0px 16px 6px rgba(0, 0, 0, 0.10)",
                    zIndex: 9999,
                }}
            >
                <div className='card w-100 h-100 rounded-0' id='kt_drawer_chat_messenger'>
                    <div className='card-header pe-5' id='kt_drawer_chat_messenger_header'>
                        <div className='card-title'>

                            <div className='d-flex justify-content-center flex-column me-3'>
                                <h1>Chat with Admin</h1>
                            </div>
                        </div>

                        <div className='card-toolbar'>
                            <div className='me-2'>
                                <button
                                    className='btn btn-sm btn-icon btn-active-light-primary'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                                    onClick={() => { allChat(); }}
                                >
                                    <KTIcon iconName='arrow-left' className='fs-2' />
                                </button>
                            </div>

                            <div className='btn btn-sm btn-icon btn-active-light-primary' id='kt_drawer_chat_close' onClick={() => chatToggle()}>
                                <KTIcon iconName='cross' className='fs-2' />
                            </div>
                        </div>




                    </div>
                    <ChatInner
                        setIntervalId={function () { }}
                        chat={
                            {
                                conversation_id: 43,

                                created_at: '2021-09-01',
                                last_message: { id: 1, body: 'Hello', created_at: '2021-09-01' },
                                participant: { id: adminInfo?.id, name: adminInfo?.name },
                                updated_at: '2021-09-01'
                            }
                        }
                    />
                </div>
            </div>
        </>
    )
}

export default ContactAdmin;