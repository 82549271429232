import { toAbsoluteUrl } from '_metronic/helpers';
import React from 'react';

const LargeLink = ({ isDisable, url, text, imgUrl, newTab = false }) => {
    return (
        <div className='card' style={{ width: '30%', maxHeight: 200 }}>
            <div className='px-10 card-body d-flex align-self-center justify-content-center'>
                <img className='d-flex align-self-center' alt='Pic' src={toAbsoluteUrl(`${imgUrl}`)} />
            </div>
            <div className='card-footer p-0 m-0'>
                <a href={url}
                    target={newTab ? '_blank' : '_self'}
                    style={{ opacity: `${isDisable ? 0.65 : 1}`, pointerEvents: `${isDisable ? 'none' : 'auto'}` }} className='btn btn-primary h-100 w-100'>
                    {text}
                </a>
            </div>
        </div>
    );
};

export default LargeLink;