import { MasterLayout } from '_metronic/layout/MasterLayout';
import { useAuth } from 'app/modules/auth';
import Register from 'app/modules/register/pages/Register';
import TrainerProgramWrapper from 'app/pages-trainer/program/ProgramWrapperTrainer';
import InstructionalDesignWrapper from 'app/pages-trainer/instructional-design/InstructionalDesignWrapper';

import { DashboardWrapper } from 'app/pages/dashboard/DashboardWrapper';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import TrainerProfile from 'app/pages-trainer/profile/TrainerProfile';
import CalendarTrainer from 'app/pages-trainer/calendar/pages/CalendarTrainer.page';
import { TrainerDashboardPage } from 'app/pages-trainer/dashboard/DashboardWrapper';

const TrainerRoutes = () => {
    const { currentUser } = useAuth()
    console.log(currentUser?.registration_completed)
    return (
        <>
            {currentUser?.registration_completed === true ? (
                <Routes>
                    <Route element={<MasterLayout />}>
                        <Route
                            path='*'
                            element={<Navigate to='/dashboard' />}>
                        </Route>
                        <Route
                            path='/dashboard'
                            element={<TrainerDashboardPage />}>
                        </Route>
                        <Route
                            path='/calendar'
                            element={<CalendarTrainer />}>
                        </Route>
                        <Route
                            path='/program/*'
                            element={<TrainerProgramWrapper />}>
                        </Route>
                        <Route
                            path='/instructional-design/*'
                            element={<InstructionalDesignWrapper />}
                        >
                        </Route>
                        <Route
                            path='/profile'
                            element={<TrainerProfile/>}>
                        </Route>
                    </Route>
                </Routes >) :
                (
                    <Routes>
                        <Route
                            path='*'
                            element={<Navigate to='/trainer-register' />}
                        />
                        <Route
                            path='/trainer-register'
                            element={<Register role='trainer' />}
                        />
                    </Routes>
                )
            }
        </>
    );
};

export default TrainerRoutes;