import React, { useEffect, useMemo, useState } from 'react'
import { API_URL } from 'app/consts/consts'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import TaskCollapse from 'app/pages-trainer/instructional-design/component/TaskCollapse.component'
import { KTSVG } from '../../../../_metronic/helpers'
import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import DatePickerPeriods from 'app/pages-mentee/calendar/pages/components/DatePickerPeriods.component'
import TaskCollapseBox from 'app/pages-trainer/instructional-design/component/TaskCollapseBox.component'

const ProgramInfo = () => {
  const { programId } = useParams()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [program, setProgram] = useState<any>([])
  const [applyingTasks, setApplyingTasks] = useState<number[]>([]) // Массив для хранения задач, на которые идет применение
  const [applyingSchedules, setApplyingSchedules] = useState<number[]>([]) // Массив для хранения задач, на которые идет применение

  const [trainings, setTrainings] = useState<any[]>([])
  const getTrainings = async () => {
    setIsLoading(true)
    const response = await axios.get(`${API_URL}/trainings/tasks/${programId}`)
    setTrainings(response.data.data?.tasks)
    setProgram(response.data.data?.program)
    setIsLoading(false)
  }

  const toDoTrainings = useMemo(() => {
    return trainings?.filter(
      (training) => training.status === 'todo' || training.status === 'need_action'
    )
  }, [trainings])
  const inProgressTrainings = useMemo(() => {
    return trainings.filter((training) => training.status === 'in_progress')
  }, [trainings])
  const completedTrainings = useMemo(() => {
    return trainings.filter((training) => training.status === 'completed')
  }, [trainings])

  const applyTask = async (task_id: number) => {
    setApplyingTasks((prevTasks) => [...prevTasks, task_id])
    const response = await axios.post(`${API_URL}/trainings/tasks/${task_id}/apply`)
    setApplyingTasks((prevTasks) => prevTasks.filter((id) => id !== task_id))
    getTrainings()
  }

  const applySchedule = async (task_id: number, date: string, period: string) => {
    console.log(date, period)
    setApplyingSchedules((prevTasks) => [...prevTasks, task_id])
    const response = await axios.post(`${API_URL}/trainings/tasks/${task_id}/schedule`, {
      start_date: moment(date).format('YYYY-MM-DD HH:mm:ss'),
      duration: 40,
      quota: 25,
    })
    setApplyingSchedules((prevTasks) => prevTasks.filter((id) => id !== task_id))
    getTrainings()
  }

  useEffect(() => {
    getTrainings()
  }, [])

  return (
    <div>
      {isLoading ? (
        <div className='v-100 position-relative' style={{ minHeight: '80vh' }}>
          <div
            className='page-loading position-absolute'
            style={{
              top: '50%',
              left: '50%',
            }}
          >
            <span
              className='spinner-border text-primary'
              data-kt-app-page-loading='on'
              role='status'
            >
              <span className='visually-hidden'>Loading...</span>
            </span>
          </div>
        </div>
      ) : (
        <div className='tab-content'>
          <h1 className='mb-1 fs-1'>{program?.name}</h1>
          <h2 className='mb-5 mt-10'>Trainings</h2>
          <div id='kt_project_targets_card_panel' className='tab-pane fade show active'>
            <div className='row g-9'>
              <div className='col-md-4 col-lg-12 col-xl-4'>
                <div className='mb-7'>
                  <div className='d-flex flex-stack'>
                    <div className='fw-bold fs-4 w-100'>
                      Yet to start
                      <div className='h-3px w-100 bg-warning'></div>
                    </div>
                  </div>
                </div>
                {toDoTrainings &&
                  toDoTrainings.map((item, index) => (
                    <div className='card shadow-sm mb-3'>
                      <div className='card-header cursor-pointer d-flex flex-column'>
                        <div className='d-flex flex-row align-items-center py-5'>
                          <h1 className='card-title fw-bold lh-base '>{item.name}</h1>
                        </div>
                      </div>
                      <div className='card-footer py-3 px-7'>
                        {/* 
                                                    Applying button
                                                */}
                        {item.trainer_can_apply && item.trainer_can_schedule !== true && (
                          <>
                            <ApplyModal
                              id={item.id}
                              taskName={item.name}
                              programName={item.program?.name}
                              description={item.training_description}
                              duration={item.duration}
                              level={item.program?.level}
                              pax={item.pax}
                              applyingTasks={applyingTasks}
                              applyTask={applyTask}
                            />
                          </>
                        )}
                        {item.trainer_can_apply === false && item.trainer_can_schedule === false && (
                          <button
                            type='button'
                            className='btn btn-lg disabled px-15 py-3 mx-3 my-3'
                            style={{ backgroundColor: '#009EF6', color: 'white' }}
                            disabled={true}
                          >
                            Applied
                          </button>
                        )}

                        {/* 
                                                    Scheduling button
                                                */}
                        {item.trainer_can_apply === false && item.trainer_can_schedule === true ? (
                          <>
                            <ScheduleModal
                              id={item.id}
                              taskName={item.name}
                              programName={item.program?.name}
                              description={item.training_description}
                              duration={item.duration}
                              level={item.program?.level}
                              pax={item.pax}
                              applyingTasks={applyingTasks}
                              applySchedule={applySchedule}
                            />
                          </>
                        ) : item.trainer_can_apply === false &&
                          item.trainer_can_schedule === true ? (
                          <button
                            type='button'
                            className='btn btn-lg disabled px-15 py-3 mx-3 my-3'
                            style={{ backgroundColor: '#009EF6', color: 'white' }}
                            disabled={true}
                          >
                            Scheduled
                          </button>
                        ) : null}
                      </div>
                    </div>
                  ))}
              </div>

              <div className='col-md-4 col-lg-12 col-xl-4'>
                <div className='mb-7'>
                  <div className='d-flex flex-stack'>
                    <div className='fw-bold fs-4 w-100'>
                      In Progress
                      <div className='h-3px w-100' style={{ backgroundColor: '#009EF6' }}></div>
                    </div>
                  </div>
                </div>
                {inProgressTrainings &&
                  inProgressTrainings.map((item, index) => (
                    <div>
                      <TaskCollapseBox
                        index={`${index}`}
                        isModal={false}
                        isProgress={true}
                        task={item}
                      />
                    </div>
                  ))}
              </div>

              <div className='col-md-4 col-lg-12 col-xl-4'>
                <div className='mb-7'>
                  <div className='d-flex flex-stack'>
                    <div className='fw-bold fs-4 w-100'>
                      Completed
                      <div className='h-3px w-100' style={{ backgroundColor: '#50CD89' }}></div>
                    </div>
                  </div>
                </div>
                {completedTrainings &&
                  completedTrainings.map((item, index) => (
                    <div className='card shadow-sm mb-3'>
                      {JSON.stringify(item)}

                      <a
                        className='card-header collapsible cursor-pointer rotate collapsed d-flex flex-column'
                        href={`#1`}
                      >
                        <div className='d-flex flex-row align-items-center py-5'>
                          <h1 className='card-title fw-bold lh-base '>{item.name}</h1>
                        </div>
                      </a>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const ApplyModal = ({
  id,
  taskName,
  programName = '',
  description = '',
  duration = '',
  level = '',
  pax = 0,
  applyingTasks,
  applyTask,
}: {
  id: number
  taskName: string
  programName: string
  description?: string
  duration?: string
  pax?: number
  level?: string
  applyingTasks: number[]
  applyTask: (task_id: number) => void
}) => {
  const [motivationLetter, setMotivationLetter] = useState<string>('')

  return (
    <>
      <button
        type='button'
        className='btn btn-lg btn-primary px-15 py-3 mx-3'
        data-bs-toggle='modal'
        data-bs-target={`#kt_modal_${id}`}
      >
        {applyingTasks.includes(id) ? 'Applying...' : 'Apply'}
      </button>
      <div className='modal fade' tabIndex={-1} id={`kt_modal_${id}`}>
        <div className='modal-dialog '>
          <div className='modal-content p-5'>
            <div className='modal-header'>
              <div>
                <h5 className='modal-title'> {taskName}</h5>
                <p className='text-secondary '> {programName}</p>
              </div>
            </div>
            <div className='modal-body'>
              <h5 className='modal-title'>Training Description</h5>
              <p>
                {description ? description : 'No description'}
              </p>
              <div className='d-flex gap-3'>
                <p className='badge p-3' style={{ backgroundColor: '#EEF1F5', color: '#5E6278' }}>
                  {duration} Minutes
                </p>
                <p className='badge' style={{ backgroundColor: '#EEF1F5', color: '#5E6278' }}>
                  Level {level}
                </p>
                <p className='badge' style={{ backgroundColor: '#EEF1F5', color: '#5E6278' }}>
                  {pax} pax
                </p>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>

              <button
                type='button'
                className='btn btn-primary'
                data-bs-dismiss='modal'
                onClick={() => {
                  applyTask(id) // motivationLetter'ı applyTask'a parametre olarak geçirin
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const ScheduleModal = ({
  id,
  taskName,
  programName = '',
  description = '',
  duration = '',
  level = '',
  pax = 0,
  applyingTasks,
  applySchedule,
}: {
  id: number
  taskName: string
  programName: string
  description?: string
  duration?: string
  pax?: number
  level?: string
  applyingTasks: number[]
  applySchedule: (task_id: number, date: string, period: string) => void
}) => {
  const options = [
    '00:00-00:40',
    '00:40-01:20',
    '01:20-02:00',
    '02:00-02:40',
    '02:40-03:20',
    '03:20-04:00',
    '04:00-04:40',
    '04:40-05:20',
    '05:20-06:00',
    '06:00-06:40',
    '06:40-07:20',
    '07:20-08:00',
    '08:00-08:40',
    '08:40-09:20',
    '09:20-10:00',
    '10:00-10:40',
    '10:40-11:20',
    '11:20-12:00',
    '12:00-12:40',
    '12:40-13:20',
    '13:20-14:00',
    '14:00-14:40',
    '14:40-15:20',
    '15:20-16:00',
    '16:00-16:40',
    '16:40-17:20',
    '17:20-18:00',
    '18:00-18:40',
    '18:40-19:20',
    '19:20-20:00',
    '20:00-20:40',
    '20:40-21:20',
    '21:20-22:00',
    '22:00-22:40',
    '22:40-23:20',
    '23:20-00:00',
  ]

  const [date, setDate] = useState<string>('')
  const [period, setPeriod] = useState<string>('')

  const handleChange = (event: any, picker: any) => {
    setDate(picker.startDate.format('YYYY-MM-DD'))
  }

  return (
    <>
      <button
        type='button'
        className='btn btn-lg px-15 py-3 mx-3 my-3'
        style={{ backgroundColor: '#009EF6', color: 'white' }}
        data-bs-toggle='modal'
        data-bs-target={`#kt_modal_${id}`}
      >
        {applyingTasks.includes(id) ? 'Scheduling...' : 'Schedule'}
      </button>
      <div className='modal fade' tabIndex={-1} id={`kt_modal_${id}`} aria-hidden='true'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Schedule Training</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <h5 className='modal-title mb-3'>{taskName}</h5>
              <div className='input-group input-group-solid mb-3 d-flex flex-column'>
                <label className='form-label'>Date</label>

                <DatePickerPeriods startDate={date} setStartDate={setDate} />
              </div>
              <div className='text-start fw-bold fs-6 mb-3'>
                <span className='badge badge-light-dark'>Level {level}</span>
                <span className='badge badge-light-dark'>{50} Minutes</span>
                <span className='badge badge-light-dark'>{25} pax</span>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>

              <button
                type='button'
                className='btn btn-primary'
                data-bs-dismiss='modal'
                onClick={() => {
                  applySchedule(id, new Date(date).toISOString().split('T')[0], period)
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProgramInfo
