import { StepperComponent } from '_metronic/assets/ts/components/_StepperComponent';
import { useAuth } from 'app/modules/auth';
import { ICreateAccount, createAccountSchemas, inits } from 'app/modules/wizards/components/CreateAccountWizardHelper';
import { Form, Formik, FormikValues } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { firstRegisterStep, secondRegisterStep, thirdRegisterStep } from '../utils/registerHelpers';
import { Button, Col, Dropdown, Row, Toast } from 'react-bootstrap';
import InterestStep from '../steps/InterestStep';
import DocumentsStep from '../steps/DocumentsStep';
import SkillStep from '../steps/SkillStep';
import { toAbsoluteUrl } from '_metronic/helpers/AssetHelpers';
import { KTIcon } from '_metronic/helpers/components/KTIcon';
import { HeaderUserMenu } from '@metronic/partials';
import clsx from 'clsx';
import { ErrorMessage, Field, useFormikContext } from 'formik';

interface RegisterProps {
    role: string;
}
const Register = ({ role }: RegisterProps) => {
    const { currentUser, logout } = useAuth()
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const [isDraft, setIsDraft] = useState<boolean>(false);
    const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
    const [initValues] = useState<ICreateAccount>(inits)
    const [isSubmitButton, setSubmitButton] = useState(false)
    const [stepperIndex, setStepperIndex] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);

    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)

        if (currentUser?.registration_status.programs && currentUser?.registration_status.documents && currentUser?.registration_status.skills && stepper.current) {
            window.location.replace('/dashboard')
        } else if (currentUser?.registration_status.programs && currentUser.registration_status.documents && stepper.current) {
            setStepperIndex(3)
            setCurrentSchema(createAccountSchemas[2])

            stepper.current.goto(3)
        } else if (currentUser?.registration_status.programs && stepper.current) {
            setStepperIndex(2)
            setCurrentSchema(createAccountSchemas[1])

            stepper.current.goto(2)
        }
    }

    const prevStep = () => {
        if (!stepper.current) {
            return
        }
        stepper.current.goPrev()
        setStepperIndex(stepper.current.currentStepIndex)

        setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
        setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)
    }

    const submitStep = async (values: ICreateAccount, actions: FormikValues) => {
        console.log('draft', isDraft)
        if (!stepper.current) return;
        let skillLength = 15
        // if (skillLength != null) {
        //     skillLength = localStorage.getItem('skillsLen')
        // } else {
        //     skillLength = 120
        // }
        const newInterests = values.programs.map(Number)
        console.log(isDraft, values.skills.length < skillLength)
        console.log(!isDraft || !(values.skills.length < skillLength))

        if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
            setIsLoading(true)

            switch (stepperIndex) {
                case 0:
                case 1: {
                    const result = await firstRegisterStep(values, role, newInterests);
                    break
                }
                case 2: {
                    const result = await secondRegisterStep(values);
                    break
                }
                case 3: {
                    const result = await thirdRegisterStep(values.skills);
                    if (isDraft) {
                        setIsLoading(false)
                        setIsDraft(false)
                        if (values.skills.length >= skillLength) {
                            window.location.replace('/dashboard')
                        }
                        return;
                    }

                    if (values.skills.length < skillLength) {
                        setShow(true)
                        setIsLoading(false)
                        setIsDraft(false)
                        return;
                    }
                    window.location.replace('/dashboard')
                    break
                }
            }
        }
        if (!(isDraft || (values.skills.length < skillLength)) || stepperIndex !== 3) {
            console.log('here')
            setStepperIndex(stepper.current.currentStepIndex + 1)
        }
        setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)

        setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])

        setIsLoading(false)

        stepper.current.goNext()
    }

    useEffect(() => {
        if (!stepperRef.current) {
            return
        }

        loadStepper()
    }, [stepperRef])


    return (
        <div className='stepper stepper-pills stepper-column d-flex flex-column flex-lg-row flex-xl-row flex-row-fluid' ref={stepperRef} id='kt_create_account_stepper'>
            <Toast bg={'warning'} className='position-absolute' style={{ zIndex: 999, right: 0 }} onClose={() => setShow(false)} show={show} delay={3000} autohide>
                <Toast.Body className='text-center stepper-title'><p>You didn't fully set the form <br /> It was saved as a draft!</p></Toast.Body>
            </Toast>
            <div
                className='d-flex flex-lg-row-fluid w-lg-30 order-1 order-lg-2'
                style={{
                    position: 'relative', backgroundColor: "#F3F5F9", boxShadow: "0px 0px 40px 0px rgba(177, 187, 208, 0.15)"
                }}
            >
                <div className='d-flex flex-column flex-center justify-content-between py-10 px-5 px-md-10 w-100 bg-white' style={{ boxShadow: "0px 0px 40px 0px rgba(177, 187, 208, 0.15)" }}>
                    <div className='d-flex flex-column w-100'>
                        <Link to='/' className='d-flex flex-row gap-5'>
                            <img alt='Logo' src={toAbsoluteUrl('/media/svg/logo/logo2.svg')} className='w-70px' />
                            <h3 className='h-100 p-0 m-0 d-flex align-items-center'>Mentorship</h3>
                        </Link>
                        <div className='stepper-item completed mt-13'>
                            <div className='stepper-wrapper'>
                                <div className='stepper-icon w-40px h-40px'>
                                    <i className='stepper-check fas fa-check'></i>
                                    <span className='stepper-number'>1</span>
                                </div>
                                <div className='stepper-label'>
                                    <h3 className='stepper-title'>ILA Authentication</h3>

                                    <div className='stepper-desc fw-semibold'>Login With ILA</div>
                                </div>
                            </div>
                            <div className='stepper-line h-20px'></div>
                        </div>
                        <div className='stepper-nav'>

                            <div className='stepper-item current' data-kt-stepper-element='nav'>
                                <div className='stepper-wrapper'>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>2</span>
                                    </div>
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Account Settings</h3>
                                        <div className='stepper-desc fw-semibold'>Setup Your Account Settings</div>
                                    </div>
                                </div>
                                <div className='stepper-line h-20px'></div>
                            </div>
                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-wrapper'>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>3</span>
                                    </div>
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Upload Document</h3>
                                        <div className='stepper-desc fw-semibold'>Upload Your Docs</div>
                                    </div>
                                </div>
                                <div className='stepper-line h-20px'></div>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-wrapper'>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>4</span>
                                    </div>
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Skill Assessment</h3>
                                        <div className='stepper-desc fw-semibold'>Test Your Skills</div>
                                    </div>
                                </div>
                                <div className='stepper-line h-20px'></div>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-wrapper'>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>5</span>
                                    </div>
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Completed</h3>
                                        <div className='stepper-desc fw-semibold'>Setup</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <img
                        className='mx-auto d-none d-md-block'
                        src={toAbsoluteUrl('/media/misc/S_Dialogue.png')}
                        alt=''
                        style={{ position: 'absolute', bottom: 0, left: 0, right: 0, width: '45%' }}
                    />
                </div>
            </div>

            <div className={`d-flex flex-column flex-lg-row flex-column-fluid w-lg-50 order-2 order-lg-2 w-100`} style={{ backgroundColor: "rgba(243, 245, 249, 1)", minHeight: '100vh' }}>

                <div className='d-flex flex-column flex-lg-row-fluid align-right w-66 px-md-15 h-100' >

                    <div className='topbar m-auto d-flex justify-content-end align-items-end pb-0 pt-3 cursor-pointer mb-10' style={{ width: '80%' }}>
                        <Dropdown style={{ background: 'transparent' }}>
                            <Dropdown.Toggle className='cursor-pointer symbol btn-secondary color-transparent active symbol-30px symbol-md-40px fs-7'>
                                <div className='d-flex flex-row align-items-center h-100' style={{ fontSize: '12px', fontWeight: '400' }}>
                                    <div className='d-flex flex-column'>
                                        <p className='text-gray-900 p-0 m-0 mx-3 text-black text-end text-capitalize'>{currentUser?.username}</p>
                                        <p className='text-gray-600 p-0 m-0 mx-3 text-black text-end text-capitalize'>{currentUser?.role}</p>
                                    </div>

                                    <img
                                        className='h-30px w-30px rounded'
                                        src={toAbsoluteUrl('/media/avatars/default-avatar-icon.jpg')}
                                        alt='metronic'
                                    />
                                </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className='dropdown-menu-custom'>
                                <Dropdown.Item onClick={logout}>Log Out</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className='w-100 h-100'>
                        <Formik validationSchema={currentSchema} initialValues={initValues} enableReinitialize={true} onSubmit={submitStep}>
                            {({ errors, touched }) => (
                                <Form className='d-flex flex-column h-100 pb-15' id='kt_create_account_form'>
                                    <div className='min-h-500px position-relative'>
                                        <div className='current' data-kt-stepper-element='content'>
                                            <InterestStep role={role} errors={errors} touched={touched} />
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            <DocumentsStep role={role} errors={errors} touched={touched} />
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            <SkillStep role={role} errors={errors} touched={touched} />
                                        </div>
                                    </div>
                                    <div className='d-flex flex-stack mx-auto mt-5 ' style={{ width: '90%' }}>
                                        <div className='mr-2'>
                                            <button
                                                onClick={prevStep}
                                                type='button'
                                                disabled={isLoading}
                                                className='btn btn-lg btn-light-primary me-3'
                                                data-kt-stepper-action='previous'
                                            >
                                                <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                                                {isLoading ? 'Please wait...' : 'Previous'}
                                            </button>
                                        </div>
                                        <div className='mt-5'>
                                            {(stepperIndex === 3) &&
                                                <button type='submit' className='btn btn-lg btn-light-primary mx-3' onClick={(e) => { setIsDraft(true) }} disabled={isLoading}>
                                                    <span className='indicator-label'>
                                                        {isLoading ? 'Please wait...' : 'Save as draft'}
                                                    </span>
                                                </button>
                                            }

                                            <button type='submit' className='btn btn-lg btn-primary' disabled={isLoading}>
                                                <span className='indicator-label'>
                                                    {isLoading ? 'Please wait...' : (stepperIndex === 3 ? 'Complete' : 'Continue')}
                                                    <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                                                </span>
                                            </button>
                                        </div>

                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Register;