import { Navigate, Route, Routes } from "react-router-dom"
import { RegisterLayout } from "./RegisterLayout"
import RoleSelect from "./pages/RoleSelect"


const RegisterPage = () => {
    return (
        <Routes>
            <Route element={<RegisterLayout />}>
                <Route path="/register" element={<RoleSelect />} />
                <Route path="/*" element={<Navigate to={'/register'} />} />
            </Route>
        </Routes>
    )
}

export { RegisterPage }
