import React, { useMemo } from 'react';
import { IMentorshipAdditional } from '../types/types';
import { KTSVG } from '_metronic/helpers';
import TaskCard from 'components/program/TaskCard.component';

const ProgramTasks: React.FC<{ mentorship: IMentorshipAdditional | null }> = ({ mentorship }) => {
    const toDoTasks = useMemo(() => {
        return mentorship?.assigned_tasks?.filter(task => task?.status === "todo");
    }, [mentorship?.assigned_tasks])
    const inProgressTasks = useMemo(() => {
        return mentorship?.assigned_tasks?.filter(task => task?.status === "in_progress");
    }, [mentorship?.assigned_tasks])
    const completedTasks = useMemo(() => {
        return mentorship?.assigned_tasks?.filter(task => task?.status === "completed");
    }, [mentorship?.assigned_tasks])
    return (
        <div>
            <div className="modal" tabIndex={-1} id="view-all-tasks">
                <div className="modal-dialog d-flex align-items-center" style={{minWidth:'1100px'}}>
                    <div className="modal-content d-flex align-items-center">
                        <div className="modal-header w-100 d-flex justify-content-between">
                            <h1 className="modal-title">{mentorship?.program?.name}</h1>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body w-100">
                            <div className="tab-content">
                                <h2 className='mb-10'>Tasks</h2>
                                <div id="kt_project_targets_card_pane" className="tab-pane fade show active">
                                    <div className="row g-9">
                                        <div className="col-4">
                                            <div className="mb-7">
                                                <div className="d-flex flex-stack">
                                                    <div className="fw-bold fs-4 w-100">
                                                        Yet to start
                                                        <div className="h-3px w-100 bg-warning"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            {toDoTasks && toDoTasks.map(task => (
                                                <TaskCard isModal={true} key={task.id} index={`${task.id}`} task={task} isProgress={false}/>
                                            ))}

                                        </div>

                                        <div className="col-4">
                                            <div className="mb-7">
                                                <div className="d-flex flex-stack">
                                                    <div className="fw-bold fs-4 w-100">
                                                        In Progress
                                                        <div className="h-3px w-100" style={{ backgroundColor: '#009EF6' }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            {inProgressTasks && inProgressTasks.map(task => (
                                                <TaskCard isModal={true} key={task.id} index={`${task.id}`} task={task} isProgress={true}/>
                                            ))}
                                        </div>


                                        <div className="col-4">
                                            <div className="mb-7">
                                                <div className="d-flex flex-stack w-100">
                                                    <div className="fw-bold fs-4 w-100">
                                                        Completed
                                                        <div className="h-3px w-100" style={{ backgroundColor: '#50CD89' }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            {completedTasks && completedTasks.map(task => (
                                                <TaskCard isModal={true} key={task.id} index={`${task.id}`} task={task} isProgress={false}/>
                                            ))}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className='h-10px'></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProgramTasks;