import React from 'react';
import { MentorProfileCard } from './components/MentorProfileCard';
import { MentorCompletedMentorships } from './components/MentorCompletedMentorships';

const MentorProfile = () => {
    return (
        <div>
            <MentorProfileCard/>
            <MentorCompletedMentorships/>
        </div>
    );
};

export default MentorProfile;