import {Dropdown1} from '_metronic/partials'
import {KTIcon} from '_metronic/helpers/components/KTIcon'
import React, {useState} from 'react'
import {IUpcomingEvents} from '../DashboardWrapper'
import {convertTimestamp} from 'utils/stringDateFunctions'
import {UpcomingEventModal} from './UpcomingEventModal.component'
type Props = {
  className: string
  events: IUpcomingEvents[]
}
const UpcomingEvents: React.FC<Props> = ({className, events}) => {
  console.log(events)
  const [upcomingEvent, setUpcomingEvents] = useState<IUpcomingEvents>()
  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5' style={{display: 'block'}}>
        <h3 className='card-title text-dark'>Upcoming Events</h3>
        <p className='text-secondary'>Upcoming events from your schedule.</p>
      </div>

      <div className='card-body pt-0 scroll-y' style={{maxHeight:500}}>
        {events.length <= 0 && <h3 className='fw-normal'>No upcoming events yet</h3>}
        {events?.map((event) => (
          <div className='d-flex align-items-center rounded p-5 mb-5'>
            <span className=' text-warning me-5'>
              <KTIcon iconName='category' className='text-info fs-1 me-5' />
            </span>
            <div className='flex-grow-1 me-2' style={{width: '50%'}}>
              <a
                href='#'
                className='fw-bold text-gray-800 text-hover-primary fs-6'
                style={{whiteSpace: 'pre-line'}}
              >
                {event.name}
              </a>
              <span className='text-muted fw-semibold d-block'>Moderator: {event.owner.name}</span>
            </div>
            <div className='flex-grow-1 me-2'>
              <span className='fw-bold text-gray-800 text-hover-primary fs-6'>
                {convertTimestamp(event.start_date).date}
              </span>
              <span className='text-muted fw-semibold d-block'>
                {convertTimestamp(event.start_date).time}
              </span>
            </div>

            <button
              className='btn btn-light py-2 px-2 '
              data-bs-toggle='modal'
              data-bs-target='#upcoming_event'
              onClick={() => {
                setUpcomingEvents(event)
              }}
            >
              <KTIcon iconName='arrow-right' className='text-success fs-1' />
            </button>
          </div>
        ))}
      </div>

       <UpcomingEventModal upcomingEvent={upcomingEvent} />
    </div>
  )
}

export default UpcomingEvents
