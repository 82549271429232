import {KTIcon} from '_metronic/helpers'
import React from 'react'
import {IStepActivities} from '../DashboardWrapper'
import {convertTimestamp} from 'utils/stringDateFunctions'

export const StepActivity = ({activities}: {activities: IStepActivities[]}) => {
  return (
    <div className={`card p-0`}>
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>Step Activity</span>
        </h3>
      </div>
      <div className='card-body pt-5'>
        {activities?.length <= 0 ? (
          <p>There is no any steps yet</p>
        ) : (
          <div className='timeline-label'>
            {activities?.map((activity) => (
              <div className='timeline-item'>
                <div className='timeline-label fw-bold text-gray-800 fs-6'>
                  {convertTimestamp(activity.created_at).date}
                </div>
                <div className='timeline-badge'>
                  <i style={{color: '#663259'}} className='fa fa-genderless fs-1'></i>
                </div>
                <div className='timeline-content d-flex'>
                  <span className='fw-bold text-gray-800 ps-3'>{activity.description}</span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
