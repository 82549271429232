import { API_URL } from 'app/consts/consts';
import { IMentorshipAdditional } from 'app/pages-mentor/program/types/types';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import TaskCollapse from '../component/TaskCollapse.component';
import { KTSVG } from '../../../../_metronic/helpers';
export interface IContentApplication {
    admin_can_approve_reject: boolean
    approved: boolean
    applicant_user: {
        id: number
        name: string
    }
}
export interface ITask {
    id: number
    name: string
    training_description: string
    status: string
    content_application: IContentApplication
    steps: Array<any>
    trainer_can_apply: boolean
    program: {
        name: string
    }
}

const DesignProgramTasks = () => {
    const { programId } = useParams()
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [applyingTasks, setApplyingTasks] = useState<number[]>([]); // Массив для хранения задач, на которые идет применение
    const [program,setProgram] = useState<any>({}) 
    const [tasks, setTasks] = useState<ITask[]>([])


    const applyTask = async (task_id: number, motivationLetter: string) => {
        setApplyingTasks((prevTasks) => [...prevTasks, task_id]);
        const response = await axios.post(`${API_URL}/programs/instructional-design/${programId}/apply`, {
            task_id: task_id,
            motivation_letter: motivationLetter // motivationLetter parametresini kullanın
        })
        setApplyingTasks((prevTasks) => prevTasks.filter((id) => id !== task_id));
        getProgramInfo()
    }


    const toDoTasks = useMemo(() => {
        return tasks?.filter((task) => task.status === 'waiting_task' || task.status === 'need_action')
    }, [tasks])
    const inProgressTasks = useMemo(() => {
        return tasks?.filter((task) => task.status === 'in_progress')
    }, [tasks])
    const completedTasks = useMemo(() => {
        return tasks?.filter((task) => task.status === 'completed')
    }, [tasks])
    const getProgramInfo = async () => {
        setIsLoading(true)
        const response = await axios.get(`${API_URL}/programs/instructional-design/${programId}/tasks`)
        setTasks(response.data?.data?.tasks)
        setProgram(response.data?.data?.program)
        setIsLoading(false)
    }

    useEffect(() => {
        getProgramInfo()
    }, [])
    return (
        <div>
            {isLoading ? (<div className='v-100 position-relative' style={{ minHeight: '80vh' }}>
                <div className="page-loading position-absolute" style={{
                    top: '50%',
                    left: "50%"
                }}>
                    <span className="spinner-border text-primary" data-kt-app-page-loading='on' role="status" >
                        <span className="visually-hidden">Loading...</span>
                    </span>
                </div>
            </div>) : (
                <div className="tab-content">
                    <h1 className='mb-1 fs-1'>
                        {program.name}
                    </h1>
                    <h2 className='mb-5 mt-10'>Trainings</h2>
                    <div id="kt_project_targets_card_panel" className="tab-pane fade show active">
                        <div className="row g-9">
                            <div className="col-md-4 col-lg-12 col-xl-4">
                                <div className="mb-7">
                                    <div className="d-flex flex-stack">
                                        <div className="fw-bold fs-4 w-100">
                                            Yet to start
                                            <div className="h-3px w-100 bg-warning"></div>
                                        </div>
                                    </div>
                                </div>
                                {toDoTasks &&
                                    toDoTasks.map((item, index) => (
                                        <div className="card shadow-sm mb-3">
                                            <div className="card-header cursor-pointer d-flex flex-column">
                                                <div className='d-flex flex-row align-items-center py-5'>
                                                    <h1 className="card-title fw-bold lh-base ">{item.name}</h1>
                                                </div>
                                            </div>

                                            <div className='card-footer py-3 px-7'>
                                                {item.trainer_can_apply ? (
                                                    <>
                                                        <ApplyModal
                                                            id={item.id}
                                                            taskName={item.name}
                                                            programName={item.program?.name}
                                                            applyingTasks={applyingTasks}
                                                            applyTask={applyTask}
                                                        />

                                                    </>

                                                ) : (
                                                    <button type='button' className='btn btn-lg btn-primary disabled px-15 py-3' disabled={true}>Applied</button>
                                                )
                                                }

                                                {/* <button type='button' className='btn btn-lg btn-primary' style={{ backgroundColor: "#00A3FF" }}>Schedule</button> */}
                                            </div>
                                        </div>
                                    ))}
                            </div>

                            <div className="col-md-4 col-lg-12 col-xl-4">
                                <div className="mb-7">
                                    <div className="d-flex flex-stack">
                                        <div className="fw-bold fs-4 w-100">
                                            In Progress
                                            <div className="h-3px w-100" style={{ backgroundColor: '#009EF6' }}></div>
                                        </div>
                                    </div>
                                </div>
                                {inProgressTasks &&
                                    inProgressTasks.map((item, index) => (
                                        <div>                                            
                                            <TaskCollapse index={`${index}`} isModal={false} isProgress={true} task={item} />
                                        </div>
                                    ))}
                            </div>


                            <div className="col-md-4 col-lg-12 col-xl-4">
                                <div className="mb-7">
                                    <div className="d-flex flex-stack">
                                        <div className="fw-bold fs-4 w-100">
                                            Completed
                                            <div className="h-3px w-100" style={{ backgroundColor: '#50CD89' }}></div>
                                        </div>
                                    </div>
                                </div>
                                {completedTasks &&
                                    completedTasks.map((item, index) => (
                                        <div className='card shadow-sm mb-3'>
                                            <a
                                                className='card-header collapsible cursor-pointer rotate collapsed d-flex flex-column'
                                                href={`#1`}
                                            >
                                                <div className='d-flex flex-row align-items-center py-5'>
                                                    <h1 className='card-title fw-bold lh-base '>{item.name}</h1>
                                                </div>
                                            </a>
                                        </div>
                                    ))}
                            </div>

                        </div>
                    </div>
                </div>
            )
            }
        </div >
    );
};



const ApplyModal = (
    {
        id,
        taskName,
        programName,
        applyingTasks,
        applyTask,
    }: {
        id: number,
        taskName: string,
        programName: string,
        applyingTasks: number[],
        applyTask: (task_id: number, motivationLetter: string) => void // applyTask fonksiyonunun yeni imzasını kullanın
    }
) => {
    const [motivationLetter, setMotivationLetter] = useState<string>('')

    return (
        <>
            <button type='button' className='btn btn-lg btn-primary px-15 py-3'
                data-bs-toggle="modal"
                data-bs-target={`#kt_modal_${id}`}

            >
                {applyingTasks.includes(id) ? 'Applying...' : 'Apply'}
            </button>
            <div className="modal fade" tabIndex={-1}
                id={`kt_modal_${id}`}
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                {taskName}
                                <span className="text-start text-muted fw-semibold d-block fs-7">
                                    {programName}
                                </span>
                            </h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body text-start">
                            <div
                                className='text-start fw-bold fs-6 mb-3'
                            >
                                Motivation Letter
                            </div>
                            <textarea
                                className="form-control w-100"
                                style={{ backgroundColor: '#EEF1F5' }}
                                placeholder='Please write your motivation to develop this task content. Mention your knowledge, skill and experience based on the task topic.'
                                rows={10}
                                value={motivationLetter}
                                onChange={(e) => setMotivationLetter(e.target.value)}
                            >
                            </textarea>



                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>

                            <button
                                type="button"
                                className="btn btn-primary"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    applyTask(id, motivationLetter) // motivationLetter'ı applyTask'a parametre olarak geçirin
                                }}
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DesignProgramTasks;