import {useIntl} from 'react-intl'
import {PageTitle} from '_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  MixedWidget10,
  MixedWidget11,
  MixedWidget2,
  MixedWidget8,
  TablesWidget10,
  TilesWidget1,
  TilesWidget2,
  TilesWidget3,
  TilesWidget4,
  TilesWidget5,
} from '_metronic/partials/widgets'
import DashboardTiles from './components/DashboardTiles'
import UpcomingEvents from './components/UpcomingEvents'
import {DashboardBackgroundTiles} from './components/DasboardBackgroundTiles'
import {ProgressTItle} from './components/ProgressTItle.component'
import {useEffect, useState} from 'react'
import axios from 'axios'
import {API_URL} from 'app/consts/consts'
import {useAuth} from 'app/modules/auth'
import LoadingSpinner from 'components/LoadingSpinner.component'
import {IProgram} from 'app/pages-mentor/dashboard/DashboardWrapper'
import { UpcomingMeetingModal } from './components/UpcomingEventModal.component'
import { INotification } from '_metronic/partials/layout/activity-drawer/NotificationModal'
import { LatestNotification } from 'app/pages-mentor/dashboard/components/LatestNotification.component'
interface ITraining {
  duration: number
  id: number
  name: string
  program_name: string
  start_date: string
}
const TrainerDashboardPage = () => {
  const {currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [trainings, setTrainings] = useState<ITraining[]>([])
  const [programs, setPrograms] = useState<IProgram[]>([])
  const [programId, setProgramId] = useState<number>(0)
  const [notifications, setNotifications] = useState<INotification[]>([])

  const getNotifications = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(`${API_URL}/notifications`)
      setNotifications(response.data.data)
    } catch (err) {
    } finally {
      setIsLoading(false)
    }
  }
  const getDasboard = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(`${API_URL}/dashboard/trainer`)
      const programs = await axios.get(`${API_URL}/users/${currentUser?.id}/programs`)
      setTrainings(response.data)
      setPrograms(programs?.data?.data)
    } catch (err) {
    } finally {
      setIsLoading(false)
    }
  }

  const getDasboardId = async (id: number) => {
    try {
      setIsLoading(true)
      const response = await axios.get(`${API_URL}/dashboard/trainer?program_id=${id}`)
      console.log(response.data)
      setTrainings(response.data)
    } catch (err) {
    } finally {
      setIsLoading(false)
    }
  }

  const handleProgramChange = (event) => {
    setProgramId(event.target.value)
  }

  useEffect(() => {
    getDasboard()
    getNotifications()
  }, [])
  useEffect(() => {
    console.log(programId)
    if (programId == 0) {
      getDasboard()
    } else {
      getDasboardId(programId)
    }
  }, [programId])
  return (
    <>
      <div className='row'>
        <div className='col-xl-10'></div>

        <div className='col-xl-2'>
          <select
            className='form-select'
            aria-label='Select example'
            onChange={handleProgramChange}
          >
            <option value={0}>Overall</option>
            {programs?.map((program) => {
              return <option value={program.id}>{program.name}</option>
            })}
          </select>
        </div>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div>
            <div className='row g-5 g-xl-8 mt-5'>
              <div className='col-xl-5'>
                <LatestNotification notification={notifications[0]} />
              </div>
              <div className='col-xl-7'>
                <UpcomingEvents trainings={trainings} className='card-xl-stretch mb-xl-8' />
              </div>
            </div>

            <div className='row g-5 g-xl-8'>
              <div className='col-xl-6'></div>
              <div className='col-xl-6'>
                {/* <ProgressTItle /> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

const MenteeDashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <TrainerDashboardPage />
    </>
  )
}

export {TrainerDashboardPage}
