import { ChatInner, getTimeAgo } from '_metronic/partials/chat/ChatInner'
import { KTIcon, toAbsoluteUrl } from '_metronic/helpers'
import ChatLists from '_metronic/partials/chat/ChatLists'
import React, { useContext, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { API_URL, PUSHER_APP_KEY } from 'app/consts/consts'
import { convertTimestamp } from 'utils/stringDateFunctions'
import Pusher from 'pusher-js'
import { AUTH_LOCAL_STORAGE_KEY, useAuth } from 'app/modules/auth'
import { showInfoToaster, showSuccessToaster } from 'utils/toasters'
import useSound from 'use-sound'
import notification from '../../../../sounds/notification.mp3'
import { GlobalContext } from 'GlobalState'


interface IProps {
  isShowed: boolean
  setIsShowed: React.Dispatch<React.SetStateAction<boolean>>
}

export interface INotification {
  date: string
  message: string
  source: string
}
const NotificationsModal = ({ isShowed, setIsShowed }) => {
  const [state, setState] = useContext(GlobalContext);


  const [selectedChat, setSelectedChat] = useState<any>(null)
  const [intervalId, setIntervalId] = useState<any>(null)
  const { currentUser } = useAuth()
  const [display, setDisplay] = useState<string>('')
  const drawerRef = useRef<HTMLDivElement>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [notifications, setNotifications] = useState<INotification[]>([])
  const [play] = useSound(notification);
  const chatToggle = () => {
    const chat = document.getElementById('kt_drawer_notifications')
    if (chat) {
      if (chat.style.display === 'none') {
        chat.style.display = 'block'
      } else {
        chat.style.display = 'none'
      }
    }
  }

  const getNotifications = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(`${API_URL}/notifications`)
      setNotifications(response.data.data)


    } catch (err) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isShowed) {
      chatToggle()
    } else {
      chatToggle()
    }
  }, [isShowed])
  const handleNotificationSound = () => {
    play();
  };
  useEffect(() => {
    var pusher = new Pusher(`7aff9b0e115310d278d7`, {
      cluster: 'eu',
      forceTLS: true,
      authEndpoint: `${API_URL}/pusher/auth`,
      auth: {
        headers: {
          Authorization:
            'Bearer ' +
            JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '')?.access_token,
        },
      },
    })
    var channel = pusher.subscribe(`private-private-user.${currentUser?.id}`)
    channel.bind('App\\Events\\NotificationEvent', function (data) {
      handleNotificationSound()
      setState({ ...state, notificationCounter: state.notificationCounter + 1 })
      showInfoToaster(data.data)
      getNotifications()
    })
    getNotifications()
  }, [])

  // when getting notifications, wait 10 seconds and refresh the page
  useEffect(() => {
    const interval = setInterval(() => {
      getNotifications()
    }, 10000)
    return () => clearInterval(interval)
  }, [])


  return (
    <div
      id='kt_drawer_notifications'
      style={{
        display: 'block',
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        width: '400px',
        height: '75vh',
        maxWidth: 'calc(100% - 48px)',
        maxHeight: 'calc(100% - 48px)',
        backgroundColor: 'white',
        borderRadius: '12px',
        overflow: 'hidden',
        boxShadow: '0px 0px 16px 6px rgba(0, 0, 0, 0.10)',
        zIndex: 9999,
      }}
    >
      <div className='card w-100 h-100 rounded-0' id='kt_drawer_notifications'>
        <div className='card-header pe-5' id='kt_drawer_notifications'>
          <div className='card-title'>
            <div className='d-flex justify-content-center flex-column me-3'>
              <h1>Notifications</h1>
            </div>
          </div>

          <div className='card-toolbar'>
            <div
              className='btn btn-sm btn-icon btn-active-light-primary'
              id='kt_drawer_chat_close'
              onClick={chatToggle}
            >
              <KTIcon iconName='cross' className='fs-2' />
            </div>
          </div>
        </div>
        <div className='card-body p-0'>
          {notifications?.map((notification) => {
            return (
              <div className='p-3' style={{ borderBottom: '0.5px solid #F0F0F0' }}>
                <div className='d-flex justify-content-between'>
                  <h3 className='card-title'>{JSON.parse(notification.message)}</h3>
                  <p>{getTimeAgo(notification.date)}</p>

                </div>
                <p>{convertTimestamp(notification.date).date}</p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default NotificationsModal
