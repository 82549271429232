import { Dropdown1 } from '_metronic/partials'
import { KTIcon } from '_metronic/helpers/components/KTIcon'
import React, { useState } from 'react'
import { ITraining, UpcomingMeetingModal } from './UpcomingEventModal.component'
import { convertTimestamp } from 'utils/stringDateFunctions'
type Props = {
  className: string
  trainings: ITraining[]
}
const UpcomingEvents: React.FC<Props> = ({ className, trainings }) => {
  const [upcomingTraining, setUpcomingTraining] = useState<ITraining>({
    duration: 0,
    id: 0,
    name: '',
    program_name: '',
    start_date: '',
  })

  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5' style={{ display: 'block' }}>
        <h3 className='card-title text-dark'>Upcoming Training</h3>
        <p className='text-secondary'>Upcoming training from your schedule.</p>
      </div>

      <div className='card-body pt-0'>
        {trainings.length === 0 ? (
          <div className='col-12'>
            <div className='card card-custom card-stretch'>
              <div className='card-body'>
                <div className='d-flex flex-column align-items-center'>
                  <div className="symbol symbol-50px me-2">
                    <span className="symbol-label bg-light-primary">
                      <i className="ki-duotone ki-compass fs-2x text-primary">
                        <span className="path1">
                        </span>
                        <span className="path2">
                        </span>
                      </i>
                    </span>
                  </div>
                  <h1 className='fw-bold'>
                    No Upcoming Training
                  </h1>
                  <p className='text-muted'>
                    You have no upcoming training scheduled
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          trainings?.map((event) => (
            <div className='d-flex align-items-center rounded p-5 mb-5'>
              <span className=' text-warning me-5 rounded' style={{ backgroundColor: '#DADCE4' }}>
                <KTIcon iconName='category' className='text-secondary fs-1 m-4' />
              </span>
              <div className='flex-grow-1 me-2' style={{ width: '50%' }}>

                <a
                  href='#'
                  className='fw-bold text-gray-800 text-hover-primary fs-6'
                  style={{ whiteSpace: 'pre-line' }}
                >
                  {event.name}
                </a>
                <span className='text-muted fw-semibold d-block'>{event.program_name}</span>
              </div>
              <div className='flex-grow-1 me-2'>
                <span className='fw-bold text-gray-800 text-hover-primary fs-6'>
                  {convertTimestamp(event.start_date).date}
                </span>
                <span className='text-muted fw-semibold d-block'>
                  {convertTimestamp(event.start_date).time}
                </span>
              </div>

              <button
                className='btn btn-light py-2 px-2 '
                data-bs-toggle='modal'
                data-bs-target='#upcoming_event'
                onClick={() => {
                  setUpcomingTraining(event)
                }}
              >
                <KTIcon iconName='arrow-right' className='text-success fs-1' />
              </button>
            </div>
          ))
        )}
      </div>
      {/* end::Body */}
      <UpcomingMeetingModal upcomingMeeting={upcomingTraining} />
    </div>
  )
}

export default UpcomingEvents
