import {useAuth} from 'app/modules/auth'
import {Field, useField, useFormikContext} from 'formik'
import React, {useEffect} from 'react'

const HoursCounter = () => {
  const [field] = useField('availability_per_week')
  const {currentUser} = useAuth()

  const {setFieldValue, values} = useFormikContext()
  const decreaseHours = () => {
    setFieldValue('availability_per_week', Math.max(0, field.value - 1))
  }

  const increaseHours = () => {
    setFieldValue('availability_per_week', +field.value + 1)
  }

  useEffect(() => {
    console.log('useEffect')
    setFieldValue(
      'availability_per_week',
      currentUser?.availability_per_week ? currentUser?.availability_per_week : 1
    )
  }, [])
  return (
    <div className='input-group input-group-lg mb-5 ' style={{width: 200}}>
      <span className='input-group-text cursor-pointer left' onClick={decreaseHours}>
        -
      </span>
      <Field
        type='text'
        className='form-control form-control-white'
        placeholder='Hours'
        value={`${field.value} hours`}
        readOnly
        name='availability_per_week'
        id='kt_create_account_form_availability_per_week'
      />
      <span className='input-group-text cursor-pointer right' onClick={increaseHours}>
        +
      </span>
    </div>
  )
}

export default HoursCounter
