import { toAbsoluteUrl } from '_metronic/helpers'
import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  id: number
  name: string
  eqf_level: number
  total_tasks: number
  vacant_tasks: number
}

const InstructionalCard = ({ id, name, eqf_level, total_tasks, vacant_tasks }: Props) => {
  return (
    <div className='col-xl-4 cursor-pointer'>
      <Link to={`${id}/tasks`}>
        <div className='card card-flush h-xl-100' style={{ borderBottom: '3px solid #50CD89' }}>
          <div className='card-body py-9'>
            <div className='row gx-9 h-100'>
              <div className='col-sm-12'>
                <div className='d-flex flex-column h-100'>
                  <div className='mb-7'>
                    <div className='d-flex flex-column justify-content-start mb-6'>
                      <div className='flex-shrink-0 me-5 d-flex flex-row'>
                        <div className='w-50px h-50px me-3'>
                          <div className="symbol symbol-50px me-2">
                            <span className="symbol-label bg-light-primary">
                              <i className="ki-duotone ki-abstract-41 fs-2x text-primary">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </span>
                          </div>
                        </div>
                        <div>
                          <span className='text-gray-800 fs-1 fw-bold'>{name}</span>
                          <span className='text-gray-500 fs-7 fw-bold me-2 d-block lh-1 pb-1'>
                            Level {eqf_level}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='mb-3'>
                    <div className='d-flex'>
                      <div className='border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 me-6 mb-3'>
                        <span className='fs-6 text-gray-700 fw-bold'>{total_tasks}</span>

                        <div className='fw-semibold text-gray-500'>Total Tasks</div>
                      </div>

                      <div className='border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 mb-3'>
                        <span className='fs-6 text-gray-700 fw-bold'>{vacant_tasks}</span>

                        <div className='fw-semibold text-gray-500'>Vacant Tasks</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default InstructionalCard
