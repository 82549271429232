import React, { useEffect, useState } from 'react'
import ViewTraining from '../components/ViewTraining.modal'
import ViewTeamwork from '../components/JoinTeamwork.modal'
import SubmitTask from '../components/SubmitTask.component'
import { getStepInfoMentee } from '../api/request'
import { useParams } from 'react-router-dom'
import { IMentorshipAdditional } from 'app/pages-mentor/program/types/types'
import { getMentorship } from 'app/pages-mentor/program/api/requests'
import StepActivity from 'components/program/StepActivity.component'
import SubmitReview from 'components/program/SubmitReview.components'
import { showErrorToaster, showSuccessToaster } from 'utils/toasters'
import RejectedBanner from '../components/RejectedBanner.component'
import WaitingBanner from '../components/WaitingBanner.component'
import ClosedBanner from '../components/ClosedBanner.component'
import LargeBtn from 'components/program/LargeBtn.component'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ReviewBanner from '../components/ReviewBanner.component'
import DoneBanner from '../components/DoneBanner.component'
import LargeLink from 'components/program/LargeLink.component'
import { Step } from 'types/types'
import ContactMentor from 'components/chat-contact/ContactMentor.component'
import { countDays } from 'utils/stringDateFunctions'
import FailedBanner from '../components/FailedBanner.component'

interface ITeamwork {
  id: number
  join_url: string
  meeting_password: string
  owner: {
    id: number
    name: string
  }
  participants: Array<any>
  start_url: string
  zoom_meeting_id: string
}

const MenteeStepInfo = () => {
  let { mentorshipTaskStepId, mentorshipId } = useParams()

  const [step, setStep] = useState<Step | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [mentorship, setMentorship] = useState<IMentorshipAdditional | null>(null)
  const [isToastSuccess, setIsToastSuccess] = useState<string>('')
  const [isToastFalse, setIsToastFalse] = useState<string>('')
  const fetchInfo = async () => {
    setIsLoading(true)
    const fetchedStep = await getStepInfoMentee(mentorshipTaskStepId)
    if (fetchedStep) {
      setStep(fetchedStep.data)
    }
    const fetchedMentorships = await getMentorship(mentorshipId)
    if (fetchedMentorships.data) {
      setMentorship(fetchedMentorships.data)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchInfo()
  }, [])

  useEffect(() => {
    if (isToastSuccess !== '') {
      showSuccessToaster(isToastSuccess)
    }
  }, [isToastSuccess])

  useEffect(() => {
    if (isToastFalse !== '') {
      showErrorToaster(isToastFalse)
    }
  }, [isToastFalse])

  return (
    <div>
      {isLoading ? (
        <div className='v-100 position-relative' style={{ minHeight: '80vh' }}>
          <div
            className='page-loading position-absolute'
            style={{
              top: '50%',
              left: '50%',
            }}
          >
            <span
              className='spinner-border text-primary'
              data-kt-app-page-loading='on'
              role='status'
            >
              <span className='visually-hidden'>Loading...</span>
            </span>
          </div>
        </div>
      ) : (
        <div>
          <div className='w-100 row g-5 g-xl-8 row mb-10'>
            <div className='col-xl-6 d-flex flex-column'>
              <h1>{step?.name}</h1>
              <span className='text-secondary'>{step?.task?.name}</span>
              <span className='text-secondary'>{mentorship?.program.name}</span>
            </div>
            <div className='col-xl-6 d-flex flex-row align-items-center justify-content-sm-start justify-content-xl-end p-0'>
              <span className='bg-light-danger mx-lg-5 color-danger rounded d-flex align-items-center p-3 d-flex justify-content-end mx-3' style={{ color: 'red' }}>Due in {countDays(step?.started_at, step?.estimated_end_date)}</span>
              <ContactMentor
                mentorId={mentorship?.mentor_user.id}
                mentorName={mentorship?.mentor_user.name}
              />
            </div>
          </div>
          <div className='separator my-10'></div>

          <div className='row g-5 g-xl-8 mb-15 w-100'>
            <div className='col-xl-6 d-flex flex-column '>
              <p style={{ color: 'red' }}>Remaining Revisions:{step?.remaining_submission_count}</p>
              <h1>Step Description</h1>
              <div dangerouslySetInnerHTML={{ __html: step ? step?.description : '-' }}></div>
            </div>
            <div className='col-xl-6 d-flex justify-content-sm-start gap-5 justify-content-xl-end p-0'>
              <LargeLink
                isDisable={step?.moodle_url === null ? true : false}
                imgUrl={'/media/svg/mos.svg'}
                url={step?.moodle_url}
                text={'Go To Course'}
              />
              <LargeBtn
                isDisable={step?.training === null ? true : false}
                imgUrl={'/media/svg/CombinedShape.svg'}
                modal={'#view-training'}
                text={'View Training'}
              />
              <LargeBtn
                isDisable={(step?.teamworks?.length || 0) <= 0}
                imgUrl={'/media/svg/general/Group.svg'}
                modal={'#view-teamwork'}
                text={'View Teamwork'}
              />
            </div>
          </div>
          <div className='row g-5 g-xl-8 mb-7'>
            {(step?.status === 'done' && step?.phase != 'completed') && (
              <div className='col-xl-6 mt-10'>
                {
                  step?.failed ? (
                    <FailedBanner />

                  ) : <DoneBanner />
                }

              </div>
            )}

            {((step?.phase === 'pending_submission' &&
              step?.submissions[0] &&
              step?.submissions[0].approved === null) ||
              (step?.phase === 'pending_submission' && step?.submissions.length === 0)) && (
                <div className='col-xl-6 mt-10'>
                  <SubmitTask
                    step={step}
                    setStep={setStep}
                    setIsLoading={setIsLoading}
                    setIsToastSuccess={setIsToastSuccess}
                    setIsToastError={setIsToastFalse}
                  />
                </div>
              )}
            {step?.phase === 'pending_submission' &&
              step?.submissions[0] &&
              step?.submissions[0].approved === false && (
                <div className='col-xl-6'>
                  <RejectedBanner />
                  <div className='mt-5'>
                    <SubmitTask
                      step={step}
                      setStep={setStep}
                      setIsLoading={setIsLoading}
                      setIsToastSuccess={setIsToastSuccess}
                      setIsToastError={setIsToastFalse}
                    />
                  </div>
                </div>
              )}
            {step?.phase === 'pending_submission_reply' && (
              <div className='col-xl-6'>
                <WaitingBanner />
              </div>
            )}


            {step?.phase === 'completed' ? (
              <div className='col-xl-6 mt-10'>
                <ClosedBanner />
              </div>
            ) : null}
            {(step?.phase === 'pending_review_both' || step?.phase === 'pending_review_mentee') && (
              <div className='col-xl-6 mt-10'>
                <ReviewBanner />
                <div className='mt-5'>
                  <SubmitReview
                    user={'Mentee'}
                    setIsToastSuccess={setIsToastSuccess}
                    setIsToastError={setIsToastFalse}
                  />
                </div>
              </div>
            )}
            {step?.phase === null && <div className='col-xl-6'></div>}
            <div className='col-xl-6'>
              <StepActivity activities={
                step?.failed ? [
                  ...(step?.activities || []),
                  {
                    type: 'step_assigned',
                    description: 'Failed to complete the task',
                    action: null,
                    created_at: new Date().toISOString()
                  }
                ] : (step?.activities || [])
              }
              />
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
      <ViewTraining step={step} />
      <ViewTeamwork step={step} />
    </div>
  )
}

export default MenteeStepInfo
