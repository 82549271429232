import React, { useEffect, useState } from 'react'
import { useAuth } from 'app/modules/auth'
import { Link } from 'react-router-dom'
import { IMentorshipAdditional } from 'app/pages-mentor/program/types/types'
import { getMentorshipsMentee } from 'api/requests'
import LoadingSpinner from 'components/LoadingSpinner.component'
import MenteeProgramCard from '../components/MenteeProgramCard.component'

const Programs = () => {
  const { currentUser } = useAuth()
  const [mentorships, setMentorships] = useState<IMentorshipAdditional[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  useEffect(() => {
    const fetchMentorship = async () => {
      try {
        setIsLoading(true)
        const fetchedMentorships = await getMentorshipsMentee(currentUser?.id)
        setMentorships(fetchedMentorships?.data)
      } catch (err) {
        setMentorships([])
      } finally {
        setIsLoading(false)
      }
    }

    fetchMentorship()
  }, [])
  return (
    <div>
      <h1 className='fw-bold'>Programs</h1>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className='row g-5 g-xl-8 '>
          {mentorships?.length === 0 && (
            <div className='col-12'>
              <div className='card card-custom card-stretch'>
                <div className='card-body'>
                  <div className='d-flex flex-column align-items-center'>
                    <div className="symbol symbol-50px me-2">
                      <span className="symbol-label bg-light-primary">
                        <i className="ki-duotone ki-compass fs-2x text-primary">
                          <span className="path1">
                          </span>
                          <span className="path2">
                          </span>
                        </i>
                      </span>
                    </div>
                    <h1 className='fw-bold'>No Programs</h1>
                    <p className='text-muted'>
                      You have not been assigned to any programs yet
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )
          }
          {mentorships?.map((mentorship, index) => {
            return (
              <Link
                key={index}
                className='col-xl-4 cursor-pointer'
                to={`mentorship/${mentorship.id}`}
              >
                <MenteeProgramCard
                  status={
                    mentorship.failed === true ? 'cancelled' :
                      mentorship.status}
                  completed_tasks_count={mentorship.completed_tasks_count}
                  mentorship_id={mentorship.id}
                  program_id={mentorship.program.id}
                  name={mentorship.program.name}
                  tasks_count={mentorship.tasks_count}
                  mentor_name={mentorship.mentor_user.name}
                  company={currentUser?.company}
                />
              </Link>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default Programs
