import {useIntl} from 'react-intl'
import {PageTitle} from '_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  MixedWidget10,
  MixedWidget11,
  MixedWidget2,
  MixedWidget8,
  TablesWidget10,
  TilesWidget1,
  TilesWidget2,
  TilesWidget3,
  TilesWidget4,
  TilesWidget5,
} from '_metronic/partials/widgets'
import DashboardTiles from './components/DashboardTiles'
import UpcomingEvents from './components/UpcomingEvents'
import {StepActivity} from './components/StepActivity.component'
import {LatestNotification} from './components/LatestNotification.component'
import axios from 'axios'
import {API_URL} from 'app/consts/consts'
import {useEffect, useState} from 'react'
import {DashboardBackgroundTilesPercentage} from './components/DasboardBackgroundTitlePercentage'
import {DashboardBackgroundTiles} from './components/DasboardBackgroundTiles'
import {ProfileModal} from './components/ProfileModal.component'
import {CurrentStepModal} from './components/CurrentStepModal.component'
import ContactMentor from './components/ContactMentor.component'
import {useAuth} from 'app/modules/auth'
import LoadingSpinner from 'components/LoadingSpinner.component'
import { INotification } from '_metronic/partials/layout/activity-drawer/NotificationModal'

export interface ICurrentStep {
  id: number
  task: {
    id: number
    name: string
    mentorship: {
      id: number
      status: string
      mentor: {
        id: number
        name: string
      }
      mentee: {
        id: number
        name: string
      }
      program: {
        id: number
        name: string
      }
    }
  }
  phase: string
  name: string
  description: string
  status: string
  teamworks: Array<any>
}
interface IMentorship {
  id: number
  tasks_count: number
  completed_tasks_count: number
  program: {id: number; name: string}
}

interface IStats {
  completed_course: number
  registered_teamwork: number
  registered_training: number
  steps: {
    total: number
    completed: number
  }
}
export interface IUpcomingEvents {
  type: string
  id: number
  name: string
  start_date: string
  owner: {
    id: number
    name: string
  }
  duration: number
}

export interface IStepActivities {
  type: string
  description: string
  action: string
  created_at: string
  mentor_user: {
    id: number
    name: string
  }
}

const MenteeDashboardPage = () => {
  const {currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [upcomingEvents, setUpcomingEvents] = useState<IUpcomingEvents[]>([])
  const [activities, setActivities] = useState<IStepActivities[]>([])
  const [currentStep, setCurrentStep] = useState<ICurrentStep>()
  const [mentorships, setMentorships] = useState<IMentorship[]>([])
  const [mentorship, setMentorship] = useState<IMentorship>()
  const [stats, setStats] = useState<IStats>({
    completed_course: 0,
    registered_teamwork: 0,
    registered_training: 0,
    steps: {completed: 0, total: 0},
  })
  const [mentorshipId, setMentorshipId] = useState<number>(0)
  const getDasboard = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(`${API_URL}/dashboard/mentee`)
      const mentorships = await axios.get(`${API_URL}/users/${currentUser?.id}/mentorships`)
      setMentorship(response.data?.mentorship)
      setMentorships(mentorships.data?.data)
      setUpcomingEvents(response.data?.upcoming_events)
      setActivities(response.data?.current_step.activities)
      setCurrentStep(response.data?.current_step)
      setStats(response.data?.stats)
    } catch (err) {
    } finally {
      setIsLoading(false)
    }
  }

  const getDasboardId = async (id: number) => {
    try {
      setIsLoading(true)

      const response = await axios.get(`${API_URL}/dashboard/mentee?program_id=${id}`)
      console.log(response.data?.upcoming_events)
      const mentorships = await axios.get(`${API_URL}/users/${currentUser?.id}/mentorships`)
      setMentorship(response.data?.mentorship)
      setMentorships(mentorships.data?.data)
      setUpcomingEvents(response.data?.upcoming_events)
      setActivities(response.data?.current_step.activities)
      setCurrentStep(response.data?.current_step)
    } catch (err) {
    } finally {
      setIsLoading(false)
    }
  }
  const handleMentorshipChange = (event) => {
    setMentorshipId(event.target.value)
  }

  useEffect(() => {
    getDasboard()
    getNotifications()
  }, [])
  const [notifications, setNotifications] = useState<INotification[]>([])

  useEffect(() => {
    getDasboardId(mentorshipId)
  }, [mentorshipId])
  const getNotifications = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(`${API_URL}/notifications`)
      setNotifications(response.data.data)
    } catch (err) {
    } finally {
      setIsLoading(false)
    }
  }
  console.log(currentStep?.task?.mentorship?.mentor)
  return (
    <>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-10'></div>
        <div className='col-xl-2'>
          <select
            className='form-select'
            aria-label='Select example'
            onChange={handleMentorshipChange}
            value={mentorship?.program.id}
          >
            {mentorships?.map((mentorshipItem) => {
              console.log(mentorshipItem.program.id === mentorship?.program.id)
              console.log(mentorshipItem)
              return (
                <option
                  value={`${mentorshipItem.program.id}`}
                  selected={mentorshipItem.program.id === mentorship?.program.id}
                >
                  {mentorshipItem.program.name}
                </option>
              )
            })}
          </select>
        </div>

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className='row mt-5'>
            <div className='col-xl-7'>
              <UpcomingEvents events={upcomingEvents} className='card-xl-stretch mb-xl-8' />
            </div>
            <div className='col-xl-5'>
              <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
                <div className='col-xl-12'>
                  <div className='row gx-xl-8'>
                    <div className='col-xl-6 gx-2'>
                      <DashboardTiles
                        href='#'
                        isModal={true}
                        modalName='#current_step'
                        color='transperent'
                        className='card-xxl-stretch'
                        textColor='#1BC5BD'
                        icon='media/misc/Equalizer1.svg'
                        isDisabled={currentStep?.description == undefined}
                        title='View Your
                  Current Step'
                      />
                    </div>
                    <div className='col-xl-6 gx-2'>
                        <ContactMentor
                          mentorId={currentStep?.task?.mentorship?.mentor?.id}
                          mentorName={currentStep?.task?.mentorship?.mentor?.name}
                        />
                    </div>
                  </div>
                </div>
                <div className='col-xl-12 gy-2'>
                  <div className='row gx-xl-8'>
                    <div className='col-xl-6 gx-2'>
                      <DashboardTiles
                        href='#'
                        isModal={false}
                        link={'/calendar'}
                        modalName='#current_step'
                        color='transperent'
                        textColor='#1BC5BD'
                        className='card-xxl-stretch'
                        icon='media/misc/Equalizer1.svg'
                        title='Create Teamwork'
                      />
                    </div>
                    <div className='col-xl-6 gx-2'>
                      <DashboardTiles
                        isDisabled={currentStep?.task?.mentorship?.mentor == undefined}
                        href='#'
                        isModal={true}
                        modalName='#kt_modal_1'
                        color='transperent'
                        textColor='#1BC5BD'
                        className='card-xxl-stretch'
                        icon='media/misc/Equalizer1.svg'
                        title='View Mentor Profile'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row g-5 g-xl-8'>
              <div className='col-xl-3'>
              <LatestNotification notification={notifications[0]} />
              </div>
              <div className='col-xl-4'>
                <StepActivity activities={activities} />
              </div>
              <div className='col-xl-5'>
                <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
                  <div className='col-xl-12'>
                    <div className='row gx-xl-8'>
                      <div className='col-xl-6 gx-2'>
                        <DashboardBackgroundTilesPercentage
                          className='card-xl-stretch'
                          text='Completed Steps'
                          total={stats?.steps.total}
                          active={stats?.steps.completed}
                        />
                      </div>
                      <div className='col-xl-6 gx-2'>
                        <DashboardBackgroundTiles
                          active={stats?.registered_teamwork}
                          title={'Registered Teamwork'}
                          className='card-xl-stretch'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-12'>
                    <div className='row gx-xl-8'>
                      <div className='col-xl-6 gx-2'>
                        <DashboardBackgroundTiles
                          active={stats?.registered_training}
                          title={'Registered Trainings'}
                          className='card-xl-stretch'
                        />
                      </div>
                      <div className='col-xl-6 gx-2'>
                        <DashboardBackgroundTiles
                          active={stats?.completed_course}
                          title={'Completed courses'}
                          className='card-xl-stretch'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <ProfileModal id={currentStep?.task.mentorship.mentor.id || 0} role={'mentor'} />
      <CurrentStepModal currentStep={currentStep} />
    </>
  )
}

const MenteeDashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <MenteeDashboardPage />
    </>
  )
}

export {MenteeDashboardWrapper}
