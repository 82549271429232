import {KTIcon, KTSVG, toAbsoluteUrl} from '_metronic/helpers'
import {API_URL} from 'app/consts/consts'
import {ITask} from 'app/pages-trainer/instructional-design/pages/DesignProgramTasks.page'
import axios from 'axios'
import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {ITraining, Step} from 'types/types'
import {convertTimestamp} from 'utils/stringDateFunctions'

const ViewTraining = ({step}: {step: Step | null}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  return (
    <div>
      <div className='modal fade' tabIndex={-1} id='view-training'>
        <div className='modal-dialog mw-900px d-flex align-items-center'>
          <div className='modal-content d-flex align-items-center'>
            <div className='modal-header w-100 d-flex justify-content-between'>
              <h3 className='modal-title'>{step?.task?.name}</h3>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row g-9'>
                <div className='col-md-5 col-lg-12 col-xl-5'>
                  <div className='d-flex flex-column justify-content-center h-100'>
                    <div>
                      <p className='fw-bold'>Trainer: {step?.training?.trainer?.name}</p>
                      <h3>Training Description</h3>
                      <p>{step?.task?.name}</p>
                    </div>
                    <div className='d-flex flex-row gap-3'>
                      {new Date().getTime() <= new Date(step?.started_at || '').getTime() ? (
                        <span
                          className='badge p-3 fs-7 fw-semibold'
                          style={{backgroundColor: '#50CD89', color: '#F5F5F5'}}
                        >
                          Scheduled
                        </span>
                      ) : (
                        <span
                          className='badge p-3 fs-7 fw-semibold'
                          style={{backgroundColor: '#F23F44', color: '#fff'}}
                        >
                          Expired
                        </span>
                      )}

                      <span
                        className='badge p-3 fs-7 fw-semibold'
                        style={{backgroundColor: '#EEF1F5'}}
                      >
                        {convertTimestamp(step?.training?.start_date || '').date}
                      </span>
                      <span
                        className='badge p-3 fs-7 fw-semibold'
                        style={{backgroundColor: '#EEF1F5'}}
                      >
                        {convertTimestamp(step?.training?.start_date || '').time}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col-md-7 col-lg-12 col-xl-7'>
                  <div className='d-flex flex-row gap-3'>
                    <a
                      className={clsx(`btn btn-lg btn-primary py-5 ${new Date().getTime() <= new Date(step?.started_at || '').getTime() ? '' : 'disabled'}`)}
                      href={step?.training?.meeting.join_url}
                    >
                      <div className='d-flex flex-column' style={{height: '90%'}}>
                        <div className='card-body d-flex flex-column justify-content-center align-items-center'>
                          <img
                            className='d-flex align-self-center'
                            alt='Pic'
                            width="50"
                            height="50"
                            src={toAbsoluteUrl('/media/logos/zoomIcon.png')}
                          />
                          <span className='w-100' style={{whiteSpace: 'nowrap'}}>
                            Join Training
                          </span>
                        </div>
                      </div>
                      <KTIcon iconName='arrow-right' className='text-white fs-1' />
                    </a>
                    <div className='card shadow-sm'>
                      <div className='card-body'>
                        <p>Join Zoom Meeting</p>
                        <a>{step?.training?.meeting?.join_url}</a>

                        <p>Meeting ID: {step?.training?.meeting?.zoom_meeting_id}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <div className='h-10px'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewTraining
