import { toAbsoluteUrl } from '_metronic/helpers';
import React from 'react';

const ReviewBanner = () => {
    return (
        <div className='card m-0 cursor-default bg-light btn btn-outline btn-outline-dashed btn-outline-default p-2 d-flex flex-column align-items-start'
        >
            <div className='d-flex flex-row gap-5 '>
                <img alt='banner' className='' src={toAbsoluteUrl('/media/svg/Oval.svg')} />
                <div>
                    <p className='text-align-left mb-2 p-0 fw-bold' style={{ textAlign: 'left' }}>Congratulations!</p>
                    <p className='text-secondary p-0 m-0' style={{ textAlign: 'left' }}>You completed the program. Please write a review about your Mentor. </p>
                </div>
            </div>
        </div>
    );
};

export default ReviewBanner;