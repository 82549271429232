import { toAbsoluteUrl } from '_metronic/helpers';
import { getMentorships, getTrainingPrograms } from 'api/requests';
import { useAuth } from 'app/modules/auth';
import { IMentorships } from 'app/pages-mentor/program/types/types';
import LoadingSpinner from 'components/LoadingSpinner.component';
import ProgramCard from 'components/program/ProgramCard.component';
import TrainingProgramCard from 'components/program/TrainingProgramCard.component';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Programs = () => {
    const { currentUser } = useAuth();
    const [programs, setPrograms] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false);
    useEffect(() => {
        const fetchMentorship = async () => {
            setIsLoading(true)
            const response = await getTrainingPrograms(currentUser?.id)
            setPrograms(response.data)
            setIsLoading(false);
        }

        fetchMentorship();
    }, [])
    return (
        <div>
            {isLoading ? (<LoadingSpinner />) :
                (
                    <div>
                        <h1 className='fw-bold'>Programs</h1>
                        <div className='row g-5 g-xl-8 '>
                            {programs.map((program) => {
                                return <Link className='col-xl-4 cursor-pointer' to={`${program.id}`}>
                                    <TrainingProgramCard program_id={program.id} name={program.name} activeTraining={program.active_training} totalTraining={program.total_training} company={currentUser?.company} />
                                </Link>
                            })}
                            {
                                programs.length === 0 && (
                                    <div className='col-12'>
                                        <div className='card card-custom card-stretch'>
                                            <div className='card-body'>
                                                <div className='d-flex flex-column align-items-center'>
                                                    <div className="symbol symbol-50px me-2">
                                                        <span className="symbol-label bg-light-primary">
                                                            <i className="ki-duotone ki-compass fs-2x text-primary">
                                                                <span className="path1">
                                                                </span>
                                                                <span className="path2">
                                                                </span>
                                                            </i>
                                                        </span>
                                                    </div>
                                                    <h1 className='fw-bold'>No Programs</h1>
                                                    <p className='text-muted'>
                                                        You have not been assigned to any programs yet
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            }
            {/* TEST */}
            {/* <div className='row g-5 g-xl-8 '>
                <div className='col-xl-4 cursor-pointer'>
                    <div className="card card-flush h-xl-100" style={{ borderBottom: '3px solid #50CD89', }}>
                        <div className="card-body py-9">
                            <div className="row gx-9 h-100">
                                <div className="col-sm-12">
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-7">
                                            <div className="d-flex flex-column justify-content-start mb-6">
                                                <div className="flex-shrink-0 me-5 d-flex flex-row">
                                                    <div className="w-50px h-50px me-3">
                                                        <img src={toAbsoluteUrl("/media/mentorship/mentorship-icon.png")} className="w-50px h-50px" alt="" />
                                                    </div>
                                                    <div>
                                                        <span className="text-gray-800 fs-1 fw-bold">name</span>
                                                        <span className="text-gray-500 fs-7 fw-bold me-2 d-block lh-1 pb-1">company</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="mb-3">
                                            <div className="d-flex">
                                                <div className="border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 me-6 mb-3">
                                                    <span className="fs-6 text-gray-700 fw-bold">1</span>

                                                    <div className="fw-semibold text-gray-500">Total Mentorship</div>
                                                </div>

                                                <div className="border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 mb-3">
                                                    <span className="fs-6 text-gray-700 fw-bold">1</span>

                                                    <div className="fw-semibold text-gray-500">Active Mentorship</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* TEST */}

        </div>
    );
};

export default Programs;