import {KTIcon} from '_metronic/helpers'
import React from 'react'
interface IMentorship {
  id: number
  tasks_count: number
  mentee: {
    id: number
    name: string
  }
  completed_tasks_count: number
  program: {id: number; name: string}
  teamworks: number
  trainings: number
  progress: number
  tasks: {
    total: number
    completed: number
  }
  steps: {
    total: number
    completed: number
  }
}
export const ProgressTitle = ({mentorships}: {mentorships: IMentorship[]}) => {
  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5' style={{display: 'block'}}>
        <h3 className='card-title text-dark'>Progress</h3>
        <p className='text-secondary'>Your content completion and training delivery</p>
      </div>

      <div className='card-body pt-0'>
        {mentorships?.length <= 0 ? (
          <h3>No progress yet</h3>
        ) : (
          mentorships?.map((mentorship) => (
            <div className='d-flex align-items-center rounded p-5 mb-5'>
              <span className=' text-warning me-5 rounded' style={{backgroundColor: '#DADCE4'}}>
                <KTIcon iconName='category' className='text-secondary fs-1 m-4' />
              </span>

              <div className='flex-grow-1 me-2'>
                <a
                  href='#'
                  className='fw-bold text-gray-800 text-hover-primary fs-6'
                  style={{whiteSpace: 'nowrap'}}
                >
                  {mentorship.mentee.name}
                </a>
                <span className='text-muted fw-semibold d-block'>{mentorship.program.name}</span>
              </div>
              <div className='flex-grow-1 me-2 d-flex flex-row mb-6'>
                <div className='d-flex flex-column'>
                  <span className='fw-bolder'>Steps</span>
                  <span className='text-center fw-bolder'>
                    {mentorship.steps.completed}/{mentorship.steps.total}
                  </span>
                </div>
                <div
                  className='mx-3'
                  style={{borderRight: '3px solid black', backgroundColor: 'black', height: 40}}
                ></div>
                <div className='d-flex flex-column'>
                  <span className='fw-bolder'>Courses</span>
                  <span className='text-center fw-bolder'>{mentorship.steps.total}</span>
                </div>
                <div
                  className='mx-3'
                  style={{borderRight: '3px solid black', backgroundColor: 'black', height: 40}}
                ></div>

                <div className='d-flex flex-column'>
                  <span className='fw-bolder'>Teamworks</span>
                  <span className='text-center fw-bolder'>{mentorship.teamworks}</span>
                </div>
                <div
                  className='mx-3'
                  style={{borderRight: '3px solid black', backgroundColor: 'black', height: 40}}
                ></div>

                <div className='d-flex flex-column'>
                  <span className='fw-bolder'>Trainings</span>
                  <span className='text-center fw-bolder'>{mentorship.trainings}</span>
                </div>
                <div
                  className='mx-3'
                  style={{borderRight: '3px solid black', backgroundColor: 'black', height: 40}}
                ></div>

                <div className='d-flex flex-column'>
                  <span className='fw-bolder'>% Progress</span>
                  <span className='text-center fw-bolder'>{mentorship.progress}</span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  )
}
