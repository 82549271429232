import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { ErrorsPage } from '../app/modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../app/modules/auth'
import { App } from '../App'
import { RegisterPage } from 'app/modules/register/RegisterPage'
import TrainerRoutes from './TrainerRoutes'
import MentorRoutes from './MentorRoutes'
import MenteeRoutes from './MenteeRoutes'

const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { currentUser } = useAuth()
  console.log(currentUser)
  return (
      <BrowserRouter basename={PUBLIC_URL}>
        <Routes>
          <Route element={<App />}>

            {currentUser?.role === 'mentee' ? (
              <>
                <Route path='/*' element={<MenteeRoutes />} />
                <Route index element={<Navigate to='/mentee-register' />} />
              </>
            ) : currentUser?.role === 'mentor' ? (
              <>
                <Route path='/*' element={<MentorRoutes />} />
                <Route index element={<Navigate to='/mentor-register' />} />
              </>

            ) : currentUser?.role === 'trainer' ? (
              <>
                <Route path='/*' element={<TrainerRoutes />} />
                <Route index element={<Navigate to='/trainer-register' />} />
              </>

            ) : (
              <>
                <Route path='error/*' element={<ErrorsPage />} />
                <Route path='*' element={<RegisterPage />} />
                <Route path='auth/*' element={<AuthPage />} />
              </>
            )
            }
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='logout' element={<Logout />} />
          </Route>
        </Routes>
      </BrowserRouter>
  )
}

export { AppRoutes }
