import { API_URL } from "app/consts/consts"
import axios from "axios"

export const getTasks = async (id: number | undefined | string) => {
  try {
    const response = await axios.get(`${API_URL}/tasks?program_id=${id}`)
    console.log(response)
    if (!response) {
      return null
    }
    return response.data
  } catch (err) {
    return null
  }
}


  export const getMentorship = async (id: string | undefined | number) => {
    try {
      const response = await axios.get(`${API_URL}/mentorships/${id}`)
      console.log(response)
      if (!response) {
        return null
      }
      return response.data
    } catch (err) {
      return null
    }
  }
export const getMenteeInfo = async(id:number | undefined | string) => {
  try {
    const response = await axios.get(`${API_URL}/users/${id}`)
    console.log(response)
    if (!response) {
      return null
    }
    return response.data
  } catch (err) {
    return null
  }
}
export const getMenteeFilesList = async (id:number | undefined | string) => {
  try {
    const response = await axios.get(`${API_URL}/users/${id}/documents`)
    if (!response) {
      return null
    }
    console.log(response)
    return response.data
  } catch (err) {
    console.log(err)
    return null
  }
}
export const getMenteeFile = async (id:number | undefined | string) => {
  try {
    const response = await axios.get(`${API_URL}/files/${id}/downloads`)
    if (!response) {
      return null
    }
    console.log(response)
    return response.data
  } catch (err) {
    console.log(err)
    return null
  }
}
export const getMentorshipTaskStepInfo=async(id:number | undefined | string)=> {
  try {
    const response = await axios.get(`${API_URL}/mentorship-task-steps/${id}`)
    console.log(response)
    if (!response) {
      return null
    }
    return response.data
  } catch (err) {
    return null
  }
}
export const getStepInfo = async(id: number | undefined | string) => {
  try {
    const response = await axios.get(`${API_URL}/steps/${id}`)
    console.log(response)
    if (!response) {
      return null
    }
    return response.data
  } catch (err) {
    return null
  }
}

export const getTaskInfo = async(id: number | undefined | string) => {
  try {
    console.log(id)
    const response = await axios.get(`${API_URL}/tasks/${id}`)
    console.log(response)
    if (!response) {
      return null
    }
    return response.data
  } catch (err) {
    return null
  }
}

export const getProgramInfo = async(id: number | undefined | string) => {
  try {
    console.log(id)
    const response = await axios.get(`${API_URL}/programs/${id}`)
    console.log(response)
    if (!response) {
      return null
    }
    return response.data
  } catch (err) {
    return null
  }
}

export const getMentorshipInfo = async(user_id: string | number | undefined, id: number | undefined | string) => {
  try {
    console.log(id)
    const response = await axios.get(`${API_URL}/users/${user_id}/mentorships?program_id=${id}`)
    console.log(response)
    if (!response) {
      return null
    }
    return response.data
  } catch (err) {
    return null
  }
}

export const assignTasks = async(mentorship_id: string | number | undefined, values: Array<number>) => {
  const response = await axios.post(`${API_URL}/mentorships/${mentorship_id}/assign-tasks`, {
    tasks: values,
    rectract_other_tasks: true
  })
  return response.data
}

export const submitReview = async(id: string | number | undefined, approved: boolean) => {
  const response = await axios.post(`${API_URL}/submission/${id}/reply`, {
    approved: approved,
  })
  return response.data
}