import React from 'react';
import { Link } from 'react-router-dom';
import MenteeCard from './MenteeCard.component';

const TodoListMenteeCard = ({ programInfo, toDoTasks, inProgressTasks, completedTasks }) => {
    console.log(toDoTasks)
    return (
        <div className="tab-content">
            <h1 className='mb-10 fs-1 fw-bold'>{programInfo[0]?.program?.name}</h1>
            <h2 className='mb-5'>Mentees</h2>
            <div id="kt_project_targets_card_pane" className="tab-pane fade show active">
                <div className="row g-9">
                    <div className="col-md-4 col-lg-12 col-xl-4">
                        <div className="mb-6">
                            <div className="d-flex flex-stack">
                                <div className="fw-bold fs-4 w-100">
                                    Yet to start
                                    <div className="h-3px w-100 bg-warning"></div>
                                </div>
                            </div>
                        </div>
                        {toDoTasks && toDoTasks.map((item, index) => (
                            <Link key={index} to={item.current_mentorship_task_step_id === null ? `mentorship/${item.id}/mentee/${item.mentee_user.id}` : `mentorship/${item.id}/mentee/${item.mentee_user.id}/mentorship-task-steps/${item.current_mentorship_task_step_id}`}>
                                <MenteeCard tasksLength={item.assigned_tasks.length} name={item.mentee_user.name} id={item.mentee_user.id} />
                            </Link>
                        ))}
                    </div>
                    <div className="col-md-4 col-lg-12 col-xl-4">
                        <div className="mb-6">
                            <div className="d-flex flex-stack">
                                <div className="fw-bold fs-4 w-100">
                                    In Progress
                                    <div className="h-3px w-100" style={{ backgroundColor: '#009EF6' }}></div>
                                </div>
                            </div>
                        </div>
                        {inProgressTasks && inProgressTasks.map((item, index) => (
                            <Link key={index} to={item.current_mentorship_task_step_id === null ? `mentorship/${item.id}/mentee/${item.mentee_user.id}` : `mentorship/${item.id}/mentee/${item.mentee_user.id}/mentorship-task-steps/${item.current_mentorship_task_step_id}`}>
                                <MenteeCard
                                    failed={item.failed}
                                    tasksLength={item.assigned_tasks.length} name={item.mentee_user.name} id={item.mentee_user.id} />
                            </Link>
                        ))}

                    </div>
                    <div className="col-md-4 col-lg-12 col-xl-4">
                        <div className="mb-6">
                            <div className="d-flex flex-stack">
                                <div className="fw-bold fs-4 w-100">
                                    Completed
                                    <div className="h-3px w-100" style={{ backgroundColor: '#50CD89' }}></div>
                                </div>
                            </div>
                        </div>
                        {completedTasks && completedTasks.map((item, index) => (
                            <Link key={index} to={programInfo[0]?.current_mentorship_task_step_id === null ? `mentorship/${item.id}/mentee/${item.mentee_user.id}` : `mentorship/${item.id}/mentee/${item.mentee_user.id}/mentorship-task-steps/${programInfo[0]?.current_mentorship_task_step_id}`}>
                                <MenteeCard
                                    failed={item.failed}
                                    tasksLength={item.assigned_tasks.length} name={item.mentee_user.name} id={item.mentee_user.id} />
                            </Link>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default TodoListMenteeCard;