import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick

import moment from 'moment';
import CreateTeamwork from './components/CreateTeamwork.modal';
import axios from 'axios';
import { API_URL } from 'app/consts/consts';
import { useEffect, useState } from 'react';
import { KTIcon, KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { useAuth } from 'app/modules/auth';
const CalendarMentor = () => {
    const [events, setEvents] = useState<any>([])
    const [selectedEvent, setSelectedEvent] = useState<any>(null)
    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState<any>([])
    const [selectedUser, setSelectedUser] = useState<any>(null)
    const handleDateClick = (arg: { dateStr: any; }) => {
        alert(arg.dateStr)
    }
    var todayDate = moment().startOf('day');
    var YM = todayDate.format('YYYY-MM');


    const eventClick = (info: any) => {
        // alert('Event: ' + info.event.title)
        // alert('Event: ' + info.event?.extendedProps.description)
        setSelectedEvent(info.event)
        document.getElementById('kt_modal_teamwork_button')?.click()
    }
    const eventTypeColorResolver = (eventType: string) => {
        switch (eventType) {
            case 'training':
                return '#00A3FF'
            case 'teamwork':
                return '#FFA800'
            case 'meeting':
                return '#6C7293'
            default:
                return 'purple'
        }
    }

    const getCalendarEvents = async (mentee_id: any) => {
        const firstDateOfYear = moment().startOf('year').format('YYYY-MM-DD')
        const lastDateOfYear = moment().endOf('year').format('YYYY-MM-DD')
        setLoading(true)
        try {
            await axios.get(`${API_URL}/calendar`, {
                params: {
                    start_date: firstDateOfYear,
                    end_date: lastDateOfYear,
                    view_as: mentee_id ? mentee_id : null
                }
            }).then((response) => {
                console.log(response.data)

                setEvents(Object.keys(response.data).map((key) => {
                    return response.data[key].map((event) => {

                        const time = new Date(event.date).toISOString().split('T')[1].split('Z')[0]
                        const add40Minutes = moment(time, 'HH:mm').add(40, 'minutes').format('HH:mm')
                        return {
                            allDay: true,
                            color: eventTypeColorResolver(event.event_type),
                            title: event.title,
                            eventType: event.event_type,
                            className: "fc-event-primary my-1 py-1 px-2 rounded-2",
                            start: new Date(event.date).toISOString(),
                            end: new Date(event.date).toISOString().split('T')[0] + 'T' + add40Minutes + 'Z',
                            description: event.title,
                            teamworkId: event.action.teamwork.id
                        }
                    })
                }
                ).flat())



            }
            )
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const getUsers = async () => {
        try {
            const response = await axios.get(`${API_URL}/users/related-mentees`)
            console.log(response.data)
            setUsers(response.data)
            setSelectedUser(response?.data[0]?.id)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getUsers()

        getCalendarEvents(selectedUser)
    }
        , [])

    //  when selected user changes
    useEffect(() => {
        getCalendarEvents(selectedUser)
    }
        , [selectedUser])




    return (
        <div>
            <TeamworkModal
                event={selectedEvent}
            />
            <div className='w-100 d-flex flex-row justify-content-between mb-9'>
                <h1>Calendar</h1>
                <select className='form-select form-select w-25'
                    onChange={(e) => setSelectedUser(e.target.value)}
                    value={selectedUser}
                >
                    {users.map((user: any) => {
                        return (
                            <option key={user.id} value={user.id}>{user.name}</option>
                        )
                    })
                    }
                </select>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <FullCalendar
                        eventClick={eventClick}
                        plugins={[dayGridPlugin, timeGridPlugin]}
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            end: 'dayGridMonth,timeGridWeek,timeGridDay'
                        }}
                        navLinks={true} // can click day/week names to navigate views
                        selectMirror={true}
                        editable={true}
                        dayMaxEvents={true} // allow "more" link when too many events
                        events={events}
                    />
                </div>
            </div>
        </div>
    )
};

const TeamworkModal = (
    {
        event
    }
) => {

    // current user
    const { currentUser } = useAuth();

    const [teamwork, setTeamwork] = useState<any>(null)
    const [loading, setLoading] = useState(false)

    const getTeamwork = async (teamworkId: number) => {
        setLoading(true)
        try {
            const response = await axios.get(`${API_URL}/teamwork/${teamworkId}`)
            setTeamwork(response.data?.data)
            console.log(response.data)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (event) {

            getTeamwork(event.extendedProps?.teamworkId)
        }
    }
        , [event])


    return (
        <>
            <button type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_teamwork"
                id="kt_modal_teamwork_button"
                style={{ display: 'none' }}
            >
            </button>
            <div className="modal fade" tabIndex={-1} id="kt_modal_teamwork">
                {loading ? (
                    <div
                        className='modal-dialog'
                    >
                        <div className="modal-content">

                            <div className='v-30 position-relative' style={{ minHeight: '30vh' }}>
                                <div className="page-loading position-absolute" style={{
                                    top: '50%',
                                    left: "50%"
                                }}>
                                    <span className="spinner-border text-primary" data-kt-app-page-loading='on' role="status" >
                                        <span className="visually-hidden">Loading...</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                ) : (
                    <div className="modal-dialog modal-dialog-centered">

                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {event?.extendedProps?.description}
                                </h5>

                                <div
                                    className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <KTSVG
                                        path="media/icons/duotune/arrows/arr061.svg"
                                        className="svg-icon svg-icon-2x"
                                    />
                                </div>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <p>Moderator: {teamwork?.owner?.name}
                                    </p>
                                </div>
                                <div>
                                    <h3>Description</h3>
                                    <p
                                        className='text-muted'
                                    >
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                    </p>
                                </div>

                                <div className='d-flex flex-row gap-3'>
                                    <span className='badge p-3 fs-7 fw-semibold' style={{ backgroundColor: '#50CD89', color: '#F5F5F5' }}>Scheduled</span>
                                    <span className='badge p-3 fs-7 fw-semibold' style={{ backgroundColor: '#EEF1F5' }}>
                                        {event?.start?.toISOString().split('T')[0]}
                                    </span>
                                    <span className='badge p-3 fs-7 fw-semibold' style={{ backgroundColor: '#EEF1F5' }}>
                                        {
                                            event?.start?.toISOString().split('T')[1].split(':')[0] + ':' + event?.start?.toISOString().split('T')[1].split(':')[1]
                                        }
                                        {' - '}
                                        {
                                            moment(

                                                event?.start?.toISOString().split('T')[0] + ' ' + event?.start?.toISOString().split('T')[1].split(':')[0] +
                                                ':' +
                                                event?.start?.toISOString().split('T')[1].split(':')[1]
                                            ).add(40, 'minutes').format('HH:mm')

                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                >
                                    Close
                                </button>

                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default CalendarMentor;