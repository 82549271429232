import { MasterLayout } from '_metronic/layout/MasterLayout';
import { useAuth } from 'app/modules/auth';
import Register from 'app/modules/register/pages/Register';
import CalendarMentee from 'app/pages-mentee/calendar/pages/CalendarMentee.page';
import { MenteeDashboardWrapper } from 'app/pages-mentee/dashboard/DashboardWrapper';
import MenteeProfile from 'app/pages-mentee/profile/MenteeProfile';
import MenteeProgramWrapper from 'app/pages-mentee/program/ProgramWrapper';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const MenteeRoutes = () => {
    const { currentUser } = useAuth()
    return (
        <>
            {currentUser?.registration_completed === true ?
                (
                    <Routes>
                        <Route element={<MasterLayout />}>
                            <Route
                                path='*'
                                element={<Navigate to='/dashboard' />}>
                            </Route>
                            <Route
                                path='/dashboard'
                                element={<MenteeDashboardWrapper />}>
                            </Route>
                            <Route
                                path='/calendar'
                                element={<CalendarMentee />}>
                            </Route>
                            <Route
                                path='/program/*'
                                element={<MenteeProgramWrapper />}>
                            </Route>
                            <Route
                                path='/profile'
                                element={<MenteeProfile />}>
                            </Route>
                        </Route>
                    </Routes>) :
                (
                    <Routes>
                        <Route
                            path='*'
                            element={<Navigate to='/mentee-register' />}
                        />
                        <Route
                            path='/mentee-register'
                            element={<Register role='mentee' />}
                        />
                    </Routes>
                )
            }
        </>
    );
};

export default MenteeRoutes;