import React, {useEffect, useState} from 'react'
import {ErrorMessage, Field, useField, useFormikContext} from 'formik'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css'
import {IInterest, RegisterProps} from '../types/registerTypes'
import {loadFilledList, loadInterestsList} from '../api/requests'
import DatePicker from '../components/DatePicker'
import HoursCounter from '../components/HoursCounter'
import {useAuth} from 'app/modules/auth'

const InterestStep = ({errors, touched, role}: RegisterProps) => {
  const {currentUser} = useAuth()
  const [company, setCompany] = useState<string | undefined>(currentUser?.company)

  const [searchText, setSearchText] = useState('')
  const {values, setFieldValue} = useFormikContext()
  const [interests, setInterests] = useState<IInterest[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const loadInterests = async () => {
    try {
      setLoading(true)
      const response = await loadInterestsList()
      response.data.forEach((item) => {
        item.is_active = false
      })
      if (currentUser) {
        const responseFilled = await loadFilledList(currentUser?.id)
        let initialValue: string[] = new Array()
        responseFilled.data.forEach((item) => {
          let index = response.data.findIndex((elem) => elem.id === item.id)
          if (index !== -1) {
            response.data[index].is_active = true
            initialValue.push(`${index + 1}`)
          }
        })
        setFieldValue('programs', initialValue)

        if (response) {
          setInterests(response.data)
        }
        setLoading(false)
      }
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    loadInterests()
    console.log(company)
    setFieldValue('company', company)
  }, [])

  const filtered = interests.filter((interest) =>
    interest.name.toLowerCase().includes(searchText.toLowerCase())
  )

  return (
    <div className='m-auto d-flex flex-column justify-content-between' style={{width: '90%'}}>
      <div>
        {loading ? (
          <span
            className='indicator-progress position-absolute top-50'
            style={{display: 'block', left: '50%'}}
          >
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        ) : (
          <>
            <form className='ms-3'>
              <label className='form-label'>Select Your Interest</label>

              <input
                type='text'
                className='form-control form-control-white'
                name='search'
                placeholder='Search...'
                onChange={(e) => setSearchText(e.target.value)}
                data-kt-search-element='input'
              />
            </form>
            <form className='mt-3 ms-5 position-relative' style={{minHeight: '460px'}}>
              <div>
                <div className='scroll-y mh-300px'>
                  {filtered.map((interest) => (
                    <div
                      key={interest.id}
                      className='form-check form-check-custom form-check-solid my-6'
                    >
                      <Field
                        type='checkbox'
                        className='form-check-input'
                        name='programs'
                        value={`${interest.id}`}
                        id={`kt_create_account_form_account_programs_${interest.name}`}
                      />
                      <label className='form-check-label '>{interest.name}</label>
                    </div>
                  ))}
                </div>
                {errors.programs ? (
                  <>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='interests' />
                    </div>
                  </>
                ) : null}
              </div>

              <div className='position-relative my-4'>
                <label className='required form-label'>Mentorship period</label>
                <DatePicker />
                {errors.requested_mentorship_period_start &&
                touched.requested_mentorship_period_start &&
                errors.requested_mentorship_period_end &&
                touched.requested_mentorship_period_end ? (
                  <>
                    <div className='text-danger mt-2 text-left w-100'>
                      <ErrorMessage name='requested_mentorship_period_start' />
                    </div>
                  </>
                ) : null}
              </div>
              <div className='my-4'>
                <label className='required form-label'>Your company/institution</label>
                <Field
                  style={{width: 300}}
                  placeholder={"Arcelic A.S"}
                  type='text'
                  className='form-control form-control-white'
                  name='company'
                  id='kt_create_account_form_account_interests_company'
                />
                {errors.company && touched.company ? (
                  <>
                    <div className='text-danger mt-2 text-left w-100'>
                      <ErrorMessage name='company' />
                    </div>
                  </>
                ) : null}
              </div>
              <div className='my-4'>
                <label className='required form-label'>Availability per week</label>
                <HoursCounter />
                {errors.availability_per_week && touched.availability_per_week ? (
                  <>
                    <div className='text-danger mt-2 text-left'>
                      <ErrorMessage name='availability_per_week' />
                    </div>
                  </>
                ) : null}
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  )
}

export default InterestStep
