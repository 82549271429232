import { KTIcon } from '_metronic/helpers';
import React, { useState } from 'react';
import { convertToTimestamp } from 'utils/stringDateFunctions';
import ActivityInfo from './ActivityInfo.modal';

const StepActivity = ({ activities }) => {
    const [activity, setActivity] = useState<any>();
    return (
        <div className={`card p-0`}>
            <div className='card-header align-items-center border-0 mt-4'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='fw-bold mb-2 text-dark'>Step Activity</span>
                </h3>
            </div>
            <div className='card-body pt-5'>
                <div className='timeline-label'>
                    {activities && activities.map((item) => (
                        <div className='timeline-item'>
                            <div className='timeline-label fw-bold text-gray-800 fs-6'>{convertToTimestamp(item?.created_at)}</div>
                            <div className='timeline-badge'>
                                <i style={{ color: '#663259' }} className='fa fa-genderless fs-1'></i>
                            </div>
                            <div className='timeline-content d-flex'>
                                <span className='fw-bold text-gray-800 ps-3'>{item?.description}</span>
                            </div>
                            {item?.action != null &&
                                <div className='btn btn-sm btn-light px-1 py-1' data-bs-toggle="modal" data-bs-target="#activity-info" onClick={()=>{setActivity(item)}}>
                                    <KTIcon iconName='arrow-right' className='text-success fs-1 p-0' />
                                </div>
                            }
                        </div>
                    ))}
                </div>
            </div>
            <ActivityInfo activity={activity}/>
        </div>
    );
};

export default StepActivity;