import axios from 'axios'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers/index'
import { API_URL } from 'app/consts/consts'
import { useAuth } from 'app/modules/auth'
import { useEffect, useState } from 'react'
interface ITrainings {
  id: number
  task: { name: string; program: { name: string } }
  created_at: string
}
const TrainerProfileMentorRecommendations = () => {
  const recommendations = [
    {
      mentorName: 'John Doe',
      mentorRole: 'Software Engineer',
      recommendationText:
        'John is a great mentor. He is very helpful and has a lot of experience in the field. I would recommend him to anyone who is looking for a mentor.',
    },
    {
      mentorName: 'Jane Doe',
      mentorRole: 'Data Scientist',
      recommendationText:
        'Jane is an amazing mentor. She is very knowledgeable and has helped me a lot in my career. I would highly recommend her to anyone who is looking for a mentor.',
    },
  ]

  const { currentUser } = useAuth()
  const [trainings, setTrainings] = useState<ITrainings[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getTasks = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(`${API_URL}/users/${currentUser?.id}/trainings/self`)
      setTrainings(response.data.data)
    } catch (err) {
      setTrainings([])
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getTasks()
  }, [])
  return (
    <>
      <div className='card card-custom card-flush my-5'>
        <div className='card-header'>
          <h3 className='card-title'>Developed Tasks</h3>
        </div>
        <div className='card-body py-5'>
          {isLoading ? (
            <div className='v-100 position-relative' style={{ minHeight: '30vh' }}>
              <div
                className='page-loading position-absolute'
                style={{
                  top: '50%',
                  left: '50%',
                }}
              >
                <span
                  className='spinner-border text-primary'
                  data-kt-app-page-loading='on'
                  role='status'
                >
                  <span className='visually-hidden'>Loading...</span>
                </span>
              </div>
            </div>
          ) : (
            <div className='px-5'>
              {trainings?.map((task, index) => {
                return (
                  <div key={index}>
                    <RecommendationItem mentorName={task.task.name} mentorRole={task.task.program.name} />
                    <div className='separator my-7'></div>
                  </div>
                )
              })}
              {
                trainings?.length === 0 && (
                  <div className='col-12'>
                    <div className='card card-custom card-stretch'>
                      <div className='card-body'>
                        <div className='d-flex flex-column align-items-center'>
                          <div className="symbol symbol-50px me-2">
                            <span className="symbol-label bg-light-primary">
                              <i className="ki-duotone ki-compass fs-2x text-primary">
                                <span className="path1">
                                </span>
                                <span className="path2">
                                </span>
                              </i>
                            </span>
                          </div>
                          <h1 className='fw-bold'>No Data</h1>
                          <p className='text-muted'>
                            You have not developed any tasks yet
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const RecommendationItem = ({ mentorName, mentorRole }) => {
  return (
    <>
      <div>
        <div className='mb-5'>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-45px me-5'>
              <KTIcon className='symbol-label' iconName='home' />
            </div>
            <div className='d-flex justify-content-start flex-column'>
              <a href='#' className='text-gray-900 fw-bold text-hover-primary fs-6'>
                {mentorName}
              </a>
              <span className='text-muted fw-semibold text-muted d-block fs-7'>{mentorRole}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TrainerProfileMentorRecommendations
