import { toAbsoluteUrl } from '_metronic/helpers';
import { API_URL } from 'app/consts/consts';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import InstructionalCard from '../component/InstructionalCard.component';
import { ApprovedAlert, WaitingAlert } from './StepDevelopment.page';
interface IDesign {
    id: number,
    name: string,
    task_count: number | null,
    created_at: string,
    deleted_at: string | null,
    total_tasks: number,
    eqf_level: number,
    vacant_tasks: number
  }
const DesignPrograms = () => {
    const [designs, setDesigns] = useState<IDesign[]>();
    const getDesigns = async () => {
      const response = await axios.get(`${API_URL}/programs/instructional-design`)
      console.log(response.data)
      setDesigns(response.data.data)
    }
    useEffect(() => {
      getDesigns()
    }, [])
    return (
      <div>
        <div className='row g-5 g-xl-8 '>
          {designs?.map((item,index)=> (
            <InstructionalCard id={item.id} name={item.name} eqf_level={item.eqf_level} total_tasks = {item.total_tasks} vacant_tasks={item.vacant_tasks}/>
          ))}
          {
            designs?.length === 0 && (
              <div className='col-12'>
                <div className='card card-custom card-stretch'>
                  <div className='card-body'>
                    <div className='d-flex flex-column align-items-center'>
                      <div className="symbol symbol-50px me-2">
                        <span className="symbol-label bg-light-primary">
                          <i className="ki-duotone ki-compass fs-2x text-primary">
                            <span className="path1">
                            </span>
                            <span className="path2">
                            </span>
                          </i>
                        </span>
                      </div>
                      <h1 className='fw-bold'>No Programs</h1>
                      <p className='text-muted'>
                        There are no programs available at the moment
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )
            
          }
        </div>
      </div>
    )
};

export default DesignPrograms;