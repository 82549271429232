import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Programs from '../program/pages/TrainerPrograms.page';
import StepDevelopment from './pages/StepDevelopment.page';
import DesignPrograms from './pages/DesignPrograms.page';
import DesignProgramTasks from './pages/DesignProgramTasks.page';

const InstructionalDesignWrapper = () => {
    return (
        <Routes>
            <Route
                path='/*'
                element={<DesignPrograms />}>
            </Route>
            <Route
                path='/:programId/tasks'
                element={<DesignProgramTasks />}>
            </Route>
            <Route
                path='/:programId/tasks/:stepId/step-info'
                element={<StepDevelopment />}
            >
            </Route>
        </Routes>
    );
};

export default InstructionalDesignWrapper;