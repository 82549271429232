import React from 'react';

const LoadingSpinner = () => {
    return (
        <div className='v-100 position-relative' style={{ minHeight: '80vh' }}>
            <div className="page-loading position-absolute" style={{
                top: '50%',
                left: "50%"
            }}>
                <span className="spinner-border text-primary" data-kt-app-page-loading='on' role="status" >
                    <span className="visually-hidden">Loading...</span>
                </span>
            </div>
        </div>
    );
};

export default LoadingSpinner;