import React from 'react';
import TaskCard from './TaskCard.component';

const TodoTasksList = ({mentorship, toDoTasks, inProgressTasks, completedTasks}) => {
    return (
        <div className="tab-content">
            <h1 className='mb-1 fs-1'>{mentorship?.program.name}</h1>
            <h2 className='mb-5 mt-10'>Tasks</h2>
            <div id="kt_project_targets_card_pane" className="tab-pane fade show active">
                <div className="row">
                    <div className="col-md-4 col-lg-12 col-xl-4">
                        <div className="mb-7">
                            <div className="d-flex flex-stack">
                                <div className="fw-bold fs-4 w-100">
                                    Yet to start
                                    <div className="h-3px w-100 bg-warning"></div>
                                </div>
                            </div>
                        </div>
                        {toDoTasks && toDoTasks.map(task => (
                            <TaskCard isModal={false}  key={task.id} index={`${task.id}`} task={task} isProgress={false}/>
                        ))}

                    </div>

                    <div className="col-md-4 col-lg-12 col-xl-4">
                        <div className="mb-7">
                            <div className="d-flex flex-stack">
                                <div className="fw-bold fs-4 w-100">
                                    In Progress
                                    <div className="h-3px w-100" style={{ backgroundColor: '#009EF6' }}></div>
                                </div>
                            </div>
                        </div>
                        {inProgressTasks && inProgressTasks.map(task => (
                            <TaskCard isModal={false} key={task.id} index={`${task.id}`} task={task} isProgress={true} />
                        ))}
                    </div>


                    <div className="col-md-4 col-lg-12 col-xl-4">
                        <div className="mb-7">
                            <div className="d-flex flex-stack">
                                <div className="fw-bold fs-4 w-100">
                                    Completed
                                    <div className="h-3px w-100" style={{ backgroundColor: '#50CD89' }}></div>
                                </div>
                            </div>
                        </div>
                        {completedTasks && completedTasks.map(task => (
                            <>
                            {/* {JSON.stringify(task)} */}
                            <TaskCard isModal={false} key={task.id} index={`${task.id}`} task={task} isProgress={false}/>
                            </>
                        ))}
                    </div>

                </div>
            </div>
        </div>
    );
};

export default TodoTasksList;