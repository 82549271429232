import { toAbsoluteUrl } from '_metronic/helpers';
import { IFile } from 'app/modules/register/types/registerTypes';
import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { SubmitTaskValues } from '../types/types';
import { getStepInfoMentee, submitTask } from '../api/request';
import { useParams } from 'react-router-dom';
import { Step } from 'types/types';

const initialValues = {
    message: '',
    selectedFile: null
};

const allowedFileTypesList = [
    'application/pdf',
    'application/msword',
    'text/plain',
    'image/jpg',
    'image/jpeg',
    'image/png',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-powerpoint',
    'application/vnd.ms-powerpoint',
    'application/vnd.ms-excel',
    'application/vnd.ms-excel',
    'application/zip',
    'application/x-rar-compressed',
    'audio/mpeg',
    'video/mp4',
    'application/octet-stream',
    'application/x-zip-compressed',
    'application/x-rar-compressed',
    'application/x-7z-compressed',
];

const validationSchema = Yup.object().shape({
    message: Yup.string().required('Textarea is required'),
    selectedFile: Yup.mixed()
        .test('fileType', 'Invalid file type. Please choose a PDF, DOC, or TXT file.', (value: any) => {
            if (!value) return true; // Allow empty file
            return [
                ...allowedFileTypesList,
            ].includes(value.type);
        })
});

interface Props {
    step: Step | null;
    setStep: Dispatch<SetStateAction<Step | null>>;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    setIsToastSuccess: (message: string) => void;
    setIsToastError: (message: string) => void
}
const SubmitTaskForm: React.FC<Props> = ({ step, setStep, setIsLoading, setIsToastSuccess, setIsToastError }) => {
    let { mentorshipTaskStepId } = useParams();

    const submitForm = async (values: SubmitTaskValues) => {
        if (step) {
            await submitTask(step.id, values)
            setIsToastSuccess('Task was submitted successfully!')
        }
    }

    return (
        <div className='card'>
            <div className="card-header m-0">
                <div className="card-title p-0">
                    <h3 className="p-0 card-label">
                        Submit Task
                    </h3>
                </div>
            </div>

            <div className='card-body'>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        console.log("Form submission attempted", values);
                        setSubmitting(true);
                        try {
                            await submitForm(values);
                            console.log("Form submitted successfully");
                            const fetchedStep = await getStepInfoMentee(mentorshipTaskStepId);
                            setStep(fetchedStep.data);
                        } catch (err) {
                            console.error("Submission error", err);
                            setIsToastError('Sorry, something went wrong!');
                        }
                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting }) => (
                        <SubmitTask isSubmitting={isSubmitting} setIsLoading={setIsLoading} setStep={setStep} />
                    )}

                </Formik>
            </div>
        </div>
    );
};

interface PropsSubmit {
    isSubmitting: any;
    setStep: Dispatch<SetStateAction<Step | null>>;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
}
const SubmitTask: React.FC<PropsSubmit> = ({ isSubmitting, setStep, setIsLoading }) => {
    const { setFieldValue, values } = useFormikContext()
    const [selectedFile, setSelectedFile] = useState<IFile | null>(null);
    useEffect(() => {
        setFieldValue('selectedFile', selectedFile?.file);
        console.log(values)
    }, [selectedFile])

    const handleDivClick = (fileType) => {
        document?.getElementById(`${fileType}`)?.click();
    };
    const handleFileChange = async (e, fileType) => {
        try {
            e.preventDefault();
            const allowedFileTypes = [...allowedFileTypesList];
            if (e.target.files[0]) {
                const selectedFile = e.target.files[0];
                console.log(e.target.id);
                if (allowedFileTypes.includes(selectedFile.type)) {
                    setSelectedFile({
                        file_id: fileType,
                        file: selectedFile,
                        name: selectedFile.name,
                    });
                } else {
                    console.log('Invalid file type. Please choose a PDF, DOC, or TXT file.');
                }
            }
        } catch (e) {
            console.log('errr', e);
        }
    };
    return (
        <Form className=''>
            <div className="form-group mb-1">
                <Field as="textarea" rows={5} placeholder="Type a message..." className="form-control" id="message" name="message" />
                <ErrorMessage name="message" component="div" className="text-danger" />
            </div>

            <label
                className='mt-3 bg-light btn btn-outline btn-outline-dashed btn-outline-default w-100 p-2 d-flex flex-row justify-content-between'
                htmlFor='selectedFile'
                onClick={() => handleDivClick(`task`)}
            >
                <div className='d-flex flex-row'>
                    <img
                        src={toAbsoluteUrl('/media/icons/duotune/files/fil009.svg')}
                        className='w-50px h-40px align-self-center'
                        alt=''
                    />
                    <input
                        type="file"
                        accept=".pdf, .doc, .docx, .txt, .jpg, .png, .xlsx, .ppt, .pptx, .mp4, .mp3, .zip, .rar"
                        multiple={false}
                        id={`task`}
                        style={{ display: 'none' }}
                        onChange={(e) => handleFileChange(e, `task`)}
                    />
                    <div className='text-start align-self-end pt-3'>
                        <label className='form-label'>Quick file uploader</label>
                        <p className='text-secondary fs-7'>
                            {selectedFile ? `File: ${selectedFile.file.name}` : ('Drag & Drop or choose files from computer')}
                        </p>
                    </div>
                </div>
            </label>

            <div className="card-footer px-0">
                <button type="submit" className="btn btn-primary">
                    {isSubmitting ? 'Please wait...' : 'Send'}
                </button>

            </div>
        </Form>
    );
};


export default SubmitTaskForm;