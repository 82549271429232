import { TilesWidget5 } from '../../../../_metronic/partials/widgets/tiles/TilesWidget5'
import React from 'react';
import { Link } from 'react-router-dom';

const RoleSelect = () => {
    const AUTH_URL = process.env.REACT_APP_ILA_AUTH_URL
    return (
        <div >

            <h1 className='stepper-label'>Select Your Role</h1>

            <div className='row w-100 mt-10' style={{ gap: "15px", }}>
                <a className='col-sm' href={`${AUTH_URL}&state=mentee`} >
                    <TilesWidget5
                        href={`${AUTH_URL}&state=mentee`}
                        className='card-xxl-stretch bg-body text-center'
                        svgIcon='rocket'
                        titleClass='text-dark'
                        descriptionClass='text-muted'
                        iconClass='text-success'
                        title='Mentee'
                        description='I want to be an intern.'
                    />
                </a>
                <a className='col-sm' href={`${AUTH_URL}&state=mentor`} >
                    <TilesWidget5
                        href={`${AUTH_URL}&state=mentor`}
                        className='card-xxl-stretch bg-body text-center'
                        svgIcon='rocket'
                        titleClass='text-dark'
                        descriptionClass='text-muted'
                        iconClass='text-success'
                        title='Mentor'
                        description='I want to be Mentor.'
                    />
                </a>
                <a className='col-sm' href={`${AUTH_URL}&state=trainer`} >
                    <TilesWidget5
                        href={`${AUTH_URL}&state=trainer`}
                        className='card-xxl-stretch bg-body text-center'
                        svgIcon='rocket'
                        titleClass='text-dark'
                        descriptionClass='text-muted'
                        iconClass='text-success'
                        title='Trainer'
                        description='I want to be a Trainer.'
                    />
                </a>
            </div>
        </div>
    );
};

const styles = {
    roleDiv: {
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        borderRadius: '12px',
        width: '30%',
        height: '250px',
    }
}

export default RoleSelect;