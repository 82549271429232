import { KTIcon } from '_metronic/helpers'
import { ChatInner } from '_metronic/partials'
import {useState} from 'react'
import DashboardTiles from './DashboardTiles'

const ContactMentor = ({mentorId, mentorName}) => {
  const chatToggle = () => {
    const chat = document.getElementById('kt_drawer_mentee_chat_messenger')
    if (chat) {
      if (chat.style.display === 'none') {
        chat.style.display = 'block'
      } else {
        chat.style.display = 'none'
      }
    }
  }

  const allChat = () => {
    // kt_drawer_chat_messenger_component
    chatToggle()
    const chat = document.getElementById('kt_drawer_chat_messenger_component')
    if (chat) {
      if (chat.style.display === 'none') {
        chat.style.display = 'block'
      } else {
        chat.style.display = 'none'
      }
    }
  }
  console.log('1111', mentorId===undefined)
  return (
    <>

      <DashboardTiles
                    href='#'
                    isDisabled={mentorId === undefined}
                    isModal={true}
                    modalName={'#current_step'}
                    color='#1BC5BD'
                    className='card-xxl-stretch'
                    textColor='#fff'
                    icon='media/misc/Equalizer.svg'
                    title='Contact Mentor'
                    onClick={chatToggle}
                  />
      <div
        id='kt_drawer_mentee_chat_messenger'
        style={{
          display: 'none',
          position: 'fixed',
          bottom: '5px',
          right: '20px',
          // Size
          width: '400px',
          // width: "420px",
          height: '75vh',
          maxWidth: 'calc(100% - 48px)',
          maxHeight: 'calc(100% - 48px)',
          backgroundColor: 'white',
          // Border
          borderRadius: '12px',
          overflow: 'hidden',
          // Shadow
          boxShadow: '0px 0px 16px 6px rgba(0, 0, 0, 0.10)',
          zIndex: 9999,
        }}
      >
        <div className='card w-100 h-100 rounded-0' id='kt_drawer_chat_messenger'>
          <div className='card-header pe-5' id='kt_drawer_chat_messenger_header'>
            <div className='card-title'>
              <div className='d-flex justify-content-center flex-column me-3'>
                <h1>Chat with Mentor</h1>
                <span className='text-secondary'>{mentorName}</span>
              </div>
            </div>

            <div className='card-toolbar'>
              <div className='me-2'>
                <button
                  className='btn btn-sm btn-icon btn-active-light-primary'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  onClick={() => {
                    allChat()
                  }}
                >
                  <KTIcon iconName='arrow-left' className='fs-2' />
                </button>
              </div>

              <div
                className='btn btn-sm btn-icon btn-active-light-primary'
                id='kt_drawer_chat_close'
                onClick={() => chatToggle()}
              >
                <KTIcon iconName='cross' className='fs-2' />
              </div>
            </div>
          </div>
          <ChatInner
            setIntervalId={function () {}}
            chat={{
              conversation_id: 43,

              created_at: '2021-09-01',
              last_message: {id: 1, body: 'Hello', created_at: '2021-09-01'},
              participant: {
                id: mentorId,
                name: mentorName,
              },
              updated_at: '2021-09-01',
            }}
          />
        </div>
      </div>
    </>
  )
}

export default ContactMentor
