import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IMentorshipAdditional } from 'app/pages-mentor/program/types/types';
import { getMentorship } from 'app/pages-mentor/program/api/requests';
import TodoTasksList from 'components/program/TodoTasksList.component';

const ProgramInfo = () => {
    let { mentorshipId } = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [mentorship, setMentorship] = useState<IMentorshipAdditional | null>(null)
    useEffect(() => {
        const fetchMentorship = async () => {
            setIsLoading(true)
            const fetchedMentorships = await getMentorship(mentorshipId)
            setMentorship(fetchedMentorships.data)
            setIsLoading(false);
        }
        fetchMentorship()
    }, [])
    const toDoTasks = useMemo(() => {
        return mentorship?.assigned_tasks.filter(task => task.status === "todo");
    }, [mentorship?.assigned_tasks])
    const inProgressTasks = useMemo(() => {
        return mentorship?.assigned_tasks.filter(task => task.status === "in_progress");
    }, [mentorship?.assigned_tasks])
    const completedTasks = useMemo(() => {
        // eğer step.failed true ise step.status = "canceled" olarak değiştirilecek değilde olduğu gibi bırakılacak
        if (mentorship?.assigned_tasks) {
            mentorship.assigned_tasks.forEach(task => {
                task.steps.forEach(step => {
                    if (step.failed) {
                        step.status = "canceled"
                    }
                })
            })
        }

        return mentorship?.assigned_tasks.filter(task => task.status === "completed");
    }, [mentorship?.assigned_tasks])
    return (
        <div>
            {isLoading ? (<div className='v-100 position-relative' style={{ minHeight: '80vh' }}>
                <div className="page-loading position-absolute" style={{
                    top: '50%',
                    left: "50%"
                }}>
                    <span className="spinner-border text-primary" data-kt-app-page-loading='on' role="status" >
                        <span className="visually-hidden">Loading...</span>
                    </span>
                </div>
            </div>) : (
                <TodoTasksList mentorship={mentorship} toDoTasks={toDoTasks} inProgressTasks={inProgressTasks} completedTasks={completedTasks} />
            )}
        </div>
    );
};

export default ProgramInfo;