import {KTIcon, toAbsoluteUrl} from '_metronic/helpers'
import clsx from 'clsx'
import React from 'react'
import {Link} from 'react-router-dom'
type Props = {
  className?: string
  color: string
  icon: string
  title?: string
  href: string
  textColor: string
  isModal: boolean
  link?: string
  onClick?: () => void
  modalName: string
  isDisabled?: boolean
}
const DashboardTiles = (props: Props) => {
  const {isModal, className, icon, title, color, textColor, modalName, link, onClick, isDisabled} =
    props
  console.log('1', isDisabled)
  return (
    <span
      className={clsx('card h-100', className)}
      style={{backgroundColor: color, borderWidth: '1px', borderColor: '#1BC5BD'}}
    >
      <div className='card-body d-flex flex-column justify-content-between'>
        <div className='d-flex flex-column text-dark justify-content-center'>
          <div className='w-100 d-flex justify-content-center'>
            <img alt='icon' src={toAbsoluteUrl(icon)} className='w-50px h-50px' />
          </div>
          <div className={clsx('card-body fs-3 fw-bold text-center')} style={{color: textColor}}>
            {title}
          </div>
          <div className='w-100 d-flex justify-content-center'>
            {onClick ? (
              <button
                className='btn btn-light d-flex justify-content-center align-items-center w-35px h-35px'
                onClick={onClick}
                disabled={isDisabled}
              >
                <KTIcon iconName='arrow-right' className='text-success fs-1' />
              </button>
            ) : !isModal && link ? (
              <Link
                className='btn btn-light d-flex justify-content-center align-items-center w-35px h-35px'
                to={link}
              >
                <KTIcon iconName='arrow-right' className='text-success fs-1' />
              </Link>
            ) : (
              <button
                className='btn btn-light d-flex justify-content-center align-items-center w-35px h-35px'
                data-bs-toggle='modal'
                data-bs-target={modalName}
                disabled={isDisabled}
              >
                <KTIcon iconName='arrow-right' className='text-success fs-1' />
              </button>
            )}
          </div>
        </div>
      </div>
    </span>
  )
}

export default DashboardTiles
