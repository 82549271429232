import { FC, useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { AsideDefault } from './components/aside/AsideDefault'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { Toolbar } from './components/toolbar/Toolbar'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { PageDataProvider, useLayout } from './core'
import { ActivityDrawer, DrawerMessenger, InviteUsers, RightToolbar, UpgradePlan } from '../partials'
import { themeModeSwitchHelper, useThemeMode } from '../partials/layout/theme-mode/ThemeModeProvider'
import { MenuComponent } from '../../_metronic/assets/ts/components'
import clsx from 'clsx'
import { WithChildren, toAbsoluteUrl } from '../helpers'
import { Sidebar } from './components/Sidebar'
// import ChatModal from '_metronic/partials/layout/drawer-messenger/ChatModal'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ChatModal from '_metronic/partials/layout/drawer-messenger/ChatModal'
import NotificationsModal from '_metronic/partials/layout/activity-drawer/NotificationModal'
const MasterLayout: FC<WithChildren> = ({ children }) => {
  const { classes } = useLayout()
  const { mode } = useThemeMode()
  const location = useLocation()

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  useEffect(() => {
    themeModeSwitchHelper(mode)
  }, [mode])

  const [isShowed, setIsShowed] = useState<boolean>(false)
  const [isNotificationsShowed, setIsNotificationsShowed] = useState<boolean>(false)

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper
            isShowed={isShowed}
            setIsShowed={setIsShowed}
            isNotificationsShowed={isNotificationsShowed}
            setIsNotificationsShowed={setIsNotificationsShowed}
          />

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            {/* <Toolbar /> */}
            <div
              className={clsx(
                'd-flex flex-column-fluid align-items-start py-lg-15',
                classes.contentContainer.join(' ')
              )}
              id='kt_post'
            >
              <AsideDefault />
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
      {/* begin:: Drawers */}
      {/* <ActivityDrawer /> */}
      {/* <RightToolbar /> */}
      {/* <DrawerMessenger /> */}
      {/* end:: Drawers */}
      {/* begin:: Modals */}
      {/* <InviteUsers /> */}
      {/* <UpgradePlan /> */}
      {/* end:: Modals */}
      <ScrollTop />
      <ToastContainer />
      <ChatModal isShowed={isShowed} setIsShowed={setIsShowed} />
      <NotificationsModal isShowed={isNotificationsShowed} setIsShowed={setIsNotificationsShowed} />

    </PageDataProvider>
  )
}

export { MasterLayout }
