import {refreshToken} from '../api/_requests'
import {AuthModel} from '../types/_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token'
const ROLE_STORAGE_KEY = 'role'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }
  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem('refresh_token', auth.refresh_token ? auth.refresh_token : '')
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
    localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY)
    localStorage.removeItem(ROLE_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  const axiosApiInstance = axios.create();
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.access_token) {
        config.headers.Authorization = `Bearer ${auth.access_token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    async function (error) {
      const originalRequest = error.config
      console.log('int resp', error.response.status)
      const auth = getAuth()
      console.log('auth', auth)
      if ((error.response.status === 401 && !originalRequest._retry) && (auth && auth.refresh_token)) {
        originalRequest._retry = true
        console.log('o', Boolean(error.response.status === 401 && !originalRequest._retry))
        console.log('auth refresh token')
        refreshToken(auth?.refresh_token).then((tokenData)=>{
          console.log('refresh token')
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenData.data.access_token
          originalRequest.headers.Authorization = 'Bearer ' + tokenData.data.access_token;
          const newAuth = {access_token: tokenData.data.access_token, refresh_token: auth?.refresh_token, expires_in: tokenData.data.expires_in}
          setAuth(newAuth)
        }).catch(()=>{
          console.log('catch')
          window?.location?.reload()
          return Promise.reject(error)
        })
        return axiosApiInstance(originalRequest);        
      }
      console.log('error', error)
      return Promise.reject(error)
    }
  )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
