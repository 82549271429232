import { toAbsoluteUrl } from '_metronic/helpers'
import React from 'react'

interface Props {
    tasks_count: number,
    completed_tasks_count: number,
    name: string,
    status: string,
    company: string | undefined,
    program_id: number | undefined,
    mentorship_id: number | undefined,
    mentor_name: string | undefined,
}

interface StatusData {
    color: string;
    text: string;
    textColor: string;
}

const MenteeProgramCard: React.FC<Props> = ({ status, tasks_count, completed_tasks_count, name, company, program_id, mentorship_id, mentor_name }) => {
    function getStatusText(status: string): StatusData {
        switch (status) {
            case "todo":
                return { color: '#FFF5E8', text: 'Yet To Start', textColor: '#FFA800' };
            case "in_progress":
                return { color: '#EEF6FF', text: 'In Progress', textColor: '#3699FF' };
            case "cancelled":
                return { color: '#FFE2E5', text: 'Cancelled', textColor: 'red' };
            case "completed":
                return { color: '#E8FFF3', text: 'Completed', textColor: '#50CD89' };
            default:
                return { color: '#EEF6FF', text: 'In Progress', textColor: '#3699FF' };
        }
    }


    const statusInfo = getStatusText(status);

    return (
        <div className="card card-flush h-xl-100 cursor-pointer" style={{ borderBottom: '3px solid #008D8B', }}>
            <div className="card-body py-9">
                <div className="row gx-9 h-100">
                    <div className="col-sm-12">
                        <div className="d-flex flex-column h-100">
                            <div className="mb-7">
                                <div className="d-flex flex-column justify-content-start mb-6">
                                    <div className='mb-7'>
                                        <span className='badge fs-8 fw-bold text-center py-3 px-5' style={{ backgroundColor: statusInfo.color, color: statusInfo.textColor }}>{statusInfo.text}</span>
                                    </div>
                                    <div className="flex-shrink-0 me-5 d-flex flex-row">
                                        <div className="w-50px h-50px me-3">
                                            <img src={toAbsoluteUrl("media/stock/Icon.png")} className="w-50px h-50px" alt="" />
                                        </div>
                                        <div>
                                            <span className="text-gray-800 fs-1 fw-bold">{name}</span>
                                            <span className="text-gray-500 fs-7 fw-bold me-2 d-block lh-1 pb-1">{company}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="mb-3">
                                <div className="d-flex">
                                    <div className="border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 me-6 mb-3">
                                        <span className="fs-6 text-gray-700 fw-bold">{tasks_count}</span>

                                        <div className="fw-semibold text-gray-500">All Tasks</div>
                                    </div>

                                    <div className="border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 mb-3">
                                        <span className="fs-6 text-gray-700 fw-bold">{completed_tasks_count}</span>

                                        <div className="fw-semibold text-gray-500">Completed</div>
                                    </div>

                                </div>

                                <div className="d-flex align-items-center mt-5 me-5 me-xl-13">
                                    <img
                                        className='h-30px w-30px rounded me-3'
                                        src={toAbsoluteUrl('/media/avatars/blank.png')}
                                        alt='metronic'
                                    />
                                    <div className="m-0">
                                        <p className="fw-bold text-gray-700 text-hover-primary fs-5 p-0 m-0">{mentor_name}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MenteeProgramCard