import { toast } from 'react-toastify';

export const showSuccessToaster = (message: string) => {
    toast.success(message)
}
export const showErrorToaster = (message: string) => {
    toast.error(message);
}
export const showInfoToaster = (message: string) =>{ 
    toast.info(message)
}