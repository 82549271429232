import { ChatInner } from '_metronic/partials/chat/ChatInner';
import { KTIcon } from '_metronic/helpers';
import ChatLists from '_metronic/partials/chat/ChatLists';
import React, { useEffect, useRef, useState } from 'react';
interface IProps {
    isShowed: boolean;
    setIsShowed: React.Dispatch<React.SetStateAction<boolean>>;
}
const ChatModal = ({ isShowed, setIsShowed }) => {
    console.log(isShowed)
    const [selectedChat, setSelectedChat] = useState<any>(null);
    const [intervalId, setIntervalId] = useState<any>(null);
    const [display, setDisplay] = useState<string>('');
    const drawerRef = useRef<HTMLDivElement>(null);

    const chatToggle = () => {
        const chat = document.getElementById('kt_drawer_chat_messenger_component');
        if (chat) {
            if (chat.style.display === 'none') {
                chat.style.display = 'block';
            } else {
                chat.style.display = 'none';
            }
        }
    }

    const handleClickOutside = (event: MouseEvent) => {
        if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
            handleChatSelect(null);
            clearInterval(intervalId);
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    })
    const updateIntervalId = (id: any) => {
        setIntervalId(id);
    };
    const handleChatSelect = (chat: any) => {
        setSelectedChat(chat);
    };
    const deleteTimer = () => {
        clearInterval(intervalId);
        console.log('deleterd')
    }
    const deleteTimerAndClose = () => {
        setIsShowed(false)
        clearInterval(intervalId);
        console.log('deleterd')
    }

    // watch isShowed to change display

    useEffect(() => {
        if (isShowed) {
            chatToggle()
        } else {
            chatToggle()
        }
    }
        , [isShowed])


    return (
        <div
            id='kt_drawer_chat_messenger_component'
            style={{
                display: "block",
                position: "fixed",
                bottom: "5px",
                right: "20px",
                // Size
                width: "400px",
                // width: "420px",
                height: "75vh",
                maxWidth: "calc(100% - 48px)",
                maxHeight: "calc(100% - 48px)",
                backgroundColor: "white",
                // Border
                borderRadius: "12px",
                overflow: "hidden",
                // Shadow
                boxShadow: "0px 0px 16px 6px rgba(0, 0, 0, 0.10)",
                zIndex: 9999,
            }}
        >
            <div className='card w-100 h-100 rounded-0' id='kt_drawer_chat_messenger'>
                <div className='card-header pe-5' id='kt_drawer_chat_messenger_header'>
                    <div className='card-title'>
                        {selectedChat ?
                            <div className='d-flex justify-content-center flex-column me-3'>
                                <a href='#' className='fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1'>
                                    {selectedChat.participant.name}
                                </a>

                                <div className='mb-0 lh-1'>
                                    <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                                    <span className='fs-7 fw-semibold text-gray-400'>Active</span>
                                </div>
                            </div>
                            :
                            <div className='d-flex justify-content-center flex-column me-3'>
                                <h1>Chats</h1>
                            </div>
                        }

                    </div>

                    <div className='card-toolbar'>

                        {selectedChat ? (
                            <div className='me-2'>
                                <button
                                    className='btn btn-sm btn-icon btn-active-light-primary'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                                    onClick={() => {
                                        // chatToggle();
                                        setSelectedChat(null);
                                        deleteTimer();
                                    }}
                                >
                                    <KTIcon iconName='arrow-left' className='fs-2' />
                                </button>
                            </div>) : null}

                        <div className='btn btn-sm btn-icon btn-active-light-primary' id='kt_drawer_chat_close' onClick={chatToggle}>
                            <KTIcon iconName='cross' className='fs-2' />
                        </div>
                    </div>
                </div>
                {selectedChat ? (
                    <ChatInner chat={selectedChat} setIntervalId={updateIntervalId} />
                ) : (
                    <ChatLists onSelectChat={handleChatSelect} />
                )}
            </div>
        </div>
    );
};

export default ChatModal;