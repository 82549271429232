import {IFile, ISkill} from 'app/modules/register/types/registerTypes'
import * as Yup from 'yup'

export interface ICreateAccount {
  programs: number[]
  availability_per_week: number
  requested_mentorship_period_start: string
  requested_mentorship_period_end: string
  company: string
  documents: IFile[] | null
  skills: ISkill[]
}
export const inits: ICreateAccount = {
  programs: [],
  availability_per_week: 0,
  requested_mentorship_period_start: '',
  requested_mentorship_period_end: '',
  company: '',
  documents: null,
  skills: [],
}

const skillStorage = localStorage.getItem('skillsLen')
let skillLength = 0
if (skillStorage && !isNaN(parseInt(skillStorage))) {
  skillLength = 5
}

export const createAccountSchemas = [
  Yup.object({
    programs: Yup.array()
      .min(1)
      .required('Required')
      .typeError('programs are required')
      .label('programs'),
    availability_per_week: Yup.number().min(1).required().label('Availability Per Week'),
    requested_mentorship_period_start: Yup.string()
      .required('Required')
      .label('Mentorship Period Start'),
    requested_mentorship_period_end: Yup.string()
      .required('Required')
      .label('Mentorship Period End'),
    company: Yup.string().required('Required').label('Working Place'),
  }),
  Yup.object({
    // documents: Yup.array().min(1).required("Should fill in at least 2 files")
  }),
  Yup.object({
    // skills: Yup.array().length(2).required('You need to fill in all the skills')
  }),
]
