import React, { useMemo, useState } from 'react';
import { Task } from 'app/pages-mentor/program/types/types';
import { KTIcon, toAbsoluteUrl } from '_metronic/helpers';
import { Link, useNavigate } from 'react-router-dom';
import { ITask } from '../pages/DesignProgramTasks.page';

interface Props {
    index: string;
    task: ITask;
    isModal: boolean;
    isProgress: boolean;
}
function getStatusText(status: string) {
    switch (status) {
        case "need_action":
            return { color: '#FFF5E8', text: 'Yet To Start', textColor: '#FFA800' };
        case "in_progress":
            return { color: '#EEF6FF', text: 'In Progress ', textColor: '#3699FF' };
        case "canceled":
            return { color: '#FFE2E5', text: 'Canceled', textColor: 'red' };
        case "completed":
            return { color: '#E8FFF3', text: 'Completed', textColor: '#50CD89' };
        default:
            return { color: '#EEF6FF', text: 'In Progress', textColor: '#3699FF' };
    }
}
const TaskCollapse: React.FC<Props> = ({ index, task, isModal, isProgress }) => {
    const [isOpen, setIsOpen] = useState<Boolean>(isProgress);
    const toggleCard = () => {
        setIsOpen(!isOpen);
    };
    const sortedTasks = useMemo(() => {
        if (!task) return [];

        return task?.steps?.sort((a, b) => (a.status === "in_progress" ? -1 : b.status === "in_progress" ? 1 : 0));
    }, [task])
    console.log(isModal)
    return (
        <div className="card shadow-sm mb-3">
            <a className={`card-header collapsible cursor-pointer rotate align-items-center py-3 ${isProgress ? '' : 'collapsed'}`} href={`#${index}`} style={{ minHeight: 90, flexWrap: 'nowrap' }} onClick={toggleCard} data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls={index}>
                <div className='d-flex flex-row align-items-center h-100'>
                    <h3 className="card-title fw-bold lh-base ">{task.name}</h3>
                    {isOpen ? (
                        <img src={toAbsoluteUrl("/media/svg/TitleOpen.svg")} className="w-40px h-40px" alt="" />
                    ) : (
                        <img src={toAbsoluteUrl("/media/svg/Title.svg")} className="w-40px h-40px" alt="" />
                    )}
                </div>
                <div className="card-toolbar rotate-180">
                    <span className="svg-icon svg-icon-1">
                    </span>
                </div>
            </a>
            <div className={`collapse ${isProgress ? 'show' : ''}`} id={index}>
                <div className="card-body py-0">
                    <div className={`card py-0`}>
                        <div className='card-header border-0 p-0'>
                            <h3 className='card-title fw-bold text-dark p-0'>Steps</h3>
                        </div>
                        <div className='card-body pt-2 px-0'>
                            {task.steps?.length === 0 ? (
                                <div className='d-flex align-items-center mb-8'>
                                    <p className='text-gray-800 p-0 m-0 fw-bold fs-6'>
                                        There is no tasks yet
                                    </p>
                                </div>
                            ) : (sortedTasks?.map((step) => {
                                return <div className='d-flex align-items-center mb-8 row'>
                                    <div className='col-5 flex-grow-1 d-flex align-items-center'>
                                        <span className='bullet bullet-vertical h-40px' style={{ marginRight: 7 }}></span>
                                        <p className='text-gray-800 p-0 m-0 fw-bold fs-7'>
                                            {step.name}
                                        </p>

                                    </div>
                                    <div className='col-5 d-flex justify-content-center'>
                                        <span className='badge badge-light-success fs-8 fw-bold text-center py-3 px-6' style={{ backgroundColor: `${getStatusText(step.status).color}`, color: `${getStatusText(step.status).textColor}` }}>{getStatusText(step.status).text}</span>
                                    </div>
                                    <div className='col-1 mx-1'>
                                        <Link className='btn btn-sm btn-light px-1 py-1' to={`${step.id}/step-info`}>
                                            <KTIcon iconName='arrow-right' className='text-success fs-1 p-0' />
                                        </Link>
                                    </div>
                                </div>
                            }))}

                        </div>
                    </div>
                </div>

            </div>
        </div >
    );
};


export default TaskCollapse;