import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'
import axios from 'axios'
import { API_URL } from 'app/consts/consts'
import { useEffect, useState } from 'react'
import { KTIcon, KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import DatePickerPeriods from 'app/pages-mentee/calendar/pages/components/DatePickerPeriods.component'
import { useAuth } from 'app/modules/auth'
import { showErrorToaster } from 'utils/toasters'
const CalendarTrainer = () => {
  const [events, setEvents] = useState<any>([])
  const [selectedEvent, setSelectedEvent] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  const handleDateClick = (arg: { dateStr: any }) => {
    alert(arg.dateStr)
  }
  var todayDate = moment().startOf('day')
  var YM = todayDate.format('YYYY-MM')

  const eventClick = (info: any) => {
    // alert('Event: ' + info.event.title)
    // alert('Event: ' + info.event?.extendedProps.description)

    setSelectedEvent(info.event)
    document.getElementById('kt_modal_training_button')?.click()
  }

  const eventTypeColorResolver = (eventType: string) => {
    switch (eventType) {
      case 'training':
        return '#00A3FF'
      case 'teamwork':
        return '#FFA800'
      case 'meeting':
        return '#6C7293'
      default:
        return 'purple'
    }
  }

  const getCalendarEvents = async () => {
    const firstDateOfYear = moment().startOf('year').format('YYYY-MM-DD')
    const lastDateOfYear = moment().endOf('year').format('YYYY-MM-DD')
    setLoading(true)
    try {
      await axios
        .get(`${API_URL}/calendar`, {
          params: {
            start_date: firstDateOfYear,
            end_date: lastDateOfYear,
          },
        })
        .then((response) => {
          console.log(response.data)

          setEvents(
            Object.keys(response.data)
              .map((key) => {
                return response.data[key].map((event) => {
                  const time = new Date(event.date).toISOString().split('T')[1].split('Z')[0]
                  const add40Minutes = moment(time, 'HH:mm').add(40, 'minutes').format('HH:mm')
                  return {
                    title: event.title,
                    eventType: event.event_type,
                    color: eventTypeColorResolver(event.event_type),
                    start: new Date(event.date).toISOString(),
                    end:
                      new Date(event.date).toISOString().split('T')[0] + 'T' + add40Minutes + 'Z',
                    description: event.title,
                    location: event.location,
                    data: event,
                    allDay: true,
                  }
                })
              })
              .flat()
          )
        })
        .catch(() => {
          showErrorToaster('Sorry, something went wrong')
        })
    } catch (error) {
      showErrorToaster('Sorry, something went wrong')
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getCalendarEvents()
  }, [])

  return (
    <div>
      <div className='w-100 d-flex flex-row justify-content-between mb-9'>
        <h1>Calendar</h1>
        <TrainingViewModal event={selectedEvent} getCalendar={getCalendarEvents} />
        <a
          className='btn btn-sm btn-primary'
          data-bs-toggle='modal'
          data-bs-target='#create_training'
        >
          Create Training
        </a>
        <CreateTrainingModal getCalendar={getCalendarEvents} />
      </div>
      <div className='card'>
        <div className='card-body'>
          <FullCalendar
            eventClick={eventClick}
            plugins={[dayGridPlugin, timeGridPlugin]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              end: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            navLinks={true} // can click day/week names to navigate views
            selectMirror={true}
            editable={true}
            dayMaxEvents={true} // allow "more" link when too many events
            events={events}
          />
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

const TrainingViewModal = ({ event, getCalendar }) => {
  const currentUser = useAuth().currentUser
  const cancelTraining = async () => {
    try {
      await axios.post(
        `${API_URL}/trainings/${event?.extendedProps?.data?.action?.training?.id}/cancel`
      )
      getCalendar()
      document.getElementById('close_modal_training')?.click()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <button
        type='button'
        className='btn btn-primary'
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_training'
        id='kt_modal_training_button'
        style={{ display: 'none' }}
      ></button>
      <div className='modal fade' tabIndex={-1} id='kt_modal_training'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>{event?.title}</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                id='close_modal_training'
              >
                <KTSVG
                  path='media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div>
                <span className='fw-bold'>Trainer:</span>
                {event?.extendedProps?.data?.owner_user?.name}
                <h3 className='fs-5'>Training Description</h3>
                <span className='mx-1'>
                  {event?.extendedProps?.data?.action?.training?.description}
                </span>
              </div>
              <div>
                {new Date().getTime() <= new Date(event?.start || '').getTime() ? (
                  <span
                    className='badge p-3 fs-7 fw-semibold'
                    style={{ backgroundColor: '#50CD89', color: '#F5F5F5' }}
                  >
                    Scheduled
                  </span>
                ) : (
                  <span
                    className='badge p-3 fs-7 fw-semibold'
                    style={{ backgroundColor: '#F23F44', color: '#fff' }}
                  >
                    Expired
                  </span>
                )}

                <span className='badge badge-light-dark p-3' style={{ marginLeft: 5 }}>
                  {moment(event?.start).format('DD MMMM YYYY')}
                </span>
                <span className='badge badge-light-dark p-3' style={{ marginLeft: 5 }}>
                  {
                    event?.start?.toISOString().split('T')[1].split(':')[0] + ':' + event?.start?.toISOString().split('T')[1].split(':')[1]
                  }
                  {' - '}
                  {
                    moment(

                      event?.start?.toISOString().split('T')[0] + ' ' + event?.start?.toISOString().split('T')[1].split(':')[0] +
                      ':' +
                      event?.start?.toISOString().split('T')[1].split(':')[1]
                    ).add(40, 'minutes').format('HH:mm')

                  }
                </span>
                {/* <span className='badge badge-light-dark'>Level 5</span>
                <span className='badge badge-light-dark'>25 pax</span> */}
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              {currentUser?.id === event?.extendedProps?.data?.owner_user?.id ? (
                <button
                  type='button'
                  className='btn btn-danger'
                  data-bs-dismiss='modal'
                  onClick={cancelTraining}
                >
                  Cancel
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const TrainingViewActionModal = ({ event }) => {
  return (
    <>
      <button
        type='button'
        className='btn btn-primary'
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_training'
        id='kt_modal_training_button'
        style={{ display: 'none' }}
      ></button>
      <div className='modal fade' tabIndex={-1} id='kt_modal_training'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>{event?.title}</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div>
                <span className='fw-bold'>Trainer:</span>{' '}
                {event?.extendedProps?.data?.owner_user?.name}
              </div>
              <div>
                <span className='badge badge-success mx-2'>Scheduled</span>
                <span className='badge badge-light-dark mx-2'>
                  {moment(event?.start).format('DD MMMM YYYY')}
                </span>
                <span className='badge badge-light-dark mx-2'>
                  {moment(event?.start).format('HH:mm')}
                </span>
                <span className='badge badge-light-dark'>Level 5</span>
                <span className='badge badge-light-dark'>25 pax</span>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              <button type='button' className='btn btn-danger' data-bs-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const CreateTrainingModal = ({ getCalendar }) => {
  const [startDate, setStartDate] = useState(new Date())
  const [activeTasks, setActiveTasks] = useState<any>([])
  const [selectedTask, setSelectedTask] = useState<any>(null)
  const [fullSelectedTask, setFullSelectedTask] = useState<any>(null);
  const [loading, setLoading] = useState(false)
  const [IsToastSuccess, setIsToastSuccess] = useState('')
  const [IsToastError, setIsToastError] = useState('')
  //trainings/tasks/

  const getActiveTasks = async () => {
    setLoading(true)
    try {
      await axios.get(`${API_URL}/trainings/tasks`).then((response) => {
        console.log(response.data)
        setActiveTasks(
          response?.data?.data?.tasks?.map((item: any) => {
            return {
              title: item?.name,
              value: item?.id,
              program: item?.program,
              duration: item?.duration,
              pax: item?.pax,
              id: item?.id,
              level: item?.program?.level,
              trainer_can_schedule: item?.trainer_can_schedule
            }
          }).filter((item: any) => item?.trainer_can_schedule === true)
          || []
        )
      })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const scheduleTraining = async () => {
    setLoading(true)
    console.log(selectedTask)
    try {
      await axios
        .post(`${API_URL}/trainings/tasks/${selectedTask}/schedule`, {
          duration: fullSelectedTask?.duration,
          qouta: fullSelectedTask?.pax,
          start_date: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        })
        .then((response) => {
          console.log(response.data)
        })
      getCalendar()
      document.getElementById('create_training_button')?.click()
    } catch (e) {
      console.log(e)
      showErrorToaster('Sorry, something went wrong!')
      setIsToastError('Sorry, something went wrong!')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getActiveTasks()
  }, [])
  console.log(activeTasks)
  console.log(fullSelectedTask)
  return (
    <>
      <button
        type='button'
        className='btn btn-primary'
        data-bs-toggle='modal'
        data-bs-target='#create_training'
        id='create_training_button'
        style={{ display: 'none' }}
      ></button>
      <div className='modal fade' tabIndex={-1} id='create_training'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Schedule Training</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='col-md-12 col-lg-12 col-xl-12'>
                <div className='input-group input-group-solid mb-3 d-flex flex-column'>
                  <label className='form-label'>Task *</label>
                  <select
                    className='form-control w-100'
                    name='task'
                    onChange={(e) => {
                      setSelectedTask(e.target.value)
                      setFullSelectedTask(activeTasks.find(task => {
                        console.log(task)
                        console.log(e.target.value)
                        return task?.id == e.target.value
                      }))
                    }}
                    value={selectedTask}
                  >
                    <option value='' label='Select an active task' />
                    {activeTasks.map((item: any) => (
                      <option value={item.value} key={item.value}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='input-group input-group-solid mb-4 d-flex flex-column'>
                  <label className='form-label'>Date *</label>
                  <DatePickerPeriods startDate={startDate} setStartDate={setStartDate} />
                </div>
                {(selectedTask === null || selectedTask === '') || (new Date(startDate) < new Date()) || !fullSelectedTask?.trainer_can_schedule}
                <div className='d-flex flex-row justify-content-'>
                  <span className='badge badge-light-dark me-5 p-4'>{fullSelectedTask?.duration} minutes</span>
                  <span className='badge badge-light-dark me-5'>
                    Level {fullSelectedTask?.level}
                  </span>
                  <span className='badge badge-light-dark'>{fullSelectedTask?.pax} pax</span>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              <button
                type='button'
                className='btn btn-primary'
                disabled={(selectedTask === null || selectedTask === '') || (new Date(startDate) < new Date()) || !fullSelectedTask?.trainer_can_schedule}
                onClick={scheduleTraining}
              >
                Schedule
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CalendarTrainer
