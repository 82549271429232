import {KTSVG} from '_metronic/helpers'
import {API_URL} from 'app/consts/consts'
import DatePickerPeriods from 'app/pages-mentee/calendar/pages/components/DatePickerPeriods.component'
import axios from 'axios'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'

export const CreateZoom = ({otherUserId, sendMessage, setMessages, messages}) => {
  const [startDate, setStartDate] = useState(new Date())
  const initialValues = {
    date: '',
    details: '',
  }
  const validationSchema = Yup.object().shape({
    date: Yup.string()
      .required('Date is required')
      .test('date-validation', 'Date cannot be in the past', function (value) {
        const currentDate = new Date()
        const selectedDate = new Date(value)
        return selectedDate >= currentDate
      }),
    details: Yup.string().required('Meeting details is required'),
  })

  return (
    <div className='modal' tabIndex={-1} id='create-zoom'>
      <div className='modal-dialog d-flex align-items-center'>
        <div className='modal-content d-flex align-items-center'>
          <div className='modal-header w-100 d-flex justify-content-between'>
            <h1 className='modal-title'>Online Meeting</h1>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body w-100'>
            <div className='tab-content'>
              <div id='kt_project_targets_card_pane' className='tab-pane fade show active'>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={async (values, {setSubmitting}) => {
                    setSubmitting(true)
                    const response = await axios.post(`${API_URL}/users/${otherUserId}/meeting`, {
                      start_date: new Date(values?.date)
                        ?.toISOString()
                        .slice(0, 19)
                        .replace('T', ' '),
                      description: values?.details,
                    })
                    console.log(response?.data)
                    setMessages([
                      ...messages,
                      {
                        ...response?.data?.message,
                        meeting: response?.data?.meeting,
                      },
                    ])

                    setSubmitting(false)
                    document?.getElementById('close')?.click();
                  }}
                >
                  {({setFieldValue, handleChange, handleBlur, values, isSubmitting}) => (
                    <Form className=''>
                      <div className='row g-9'>
                        <div className='col-10 mb-8 mt-5'>
                          <DatePickerPeriods
                            startDate={startDate}
                            setStartDate={setStartDate}
                            setFieldValue={setFieldValue}
                          />
                          <ErrorMessage name='date' component='div' className='text-danger' />

                          <Field
                            as='textarea'
                            name='details'
                            className='form-control mt-3 w-100'
                            rows={3}
                            data-kt-element='input'
                            placeholder='Meeting Details'
                          ></Field>
                          <ErrorMessage name='details' component='div' className='text-danger' />
                        </div>
                      </div>
                      <div className='modal-footer p-2'>
                        <div className='d-flex flex-row justify-content-between w-100'>
                          <button
                            type='button'
                            className='btn btn-lg btn-light-primary'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                            id='close'
                          >
                            Close
                          </button>
                          <button type='submit' className='btn btn-lg btn-primary'>
                            {isSubmitting ? 'Creating...' : 'Create'}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
