import React, { useEffect, useState } from 'react';
import { MenteeInfoCard } from '../components/MenteeInfoCard.component';
import AssignTaskCard from '../components/AssignTaskCard.component';
import { useParams } from 'react-router-dom';
import { getMenteeFilesList, getMenteeInfo, getMentorship, getTasks } from '../api/requests';
import { Task, TaskMentor } from '../types/types';

interface IMenteeInfo {
    id:number
    username: string;
    total_availability_for_period: number;
    company: string;
    availability_per_week: number;
    mentorship_period: string;
    filled_hours: number;
    requested_mentorship_period_end: string;
    requested_mentorship_period_start: string;
}
interface IDocs {
    file_id: number;
    id: number;
    name: string;
    type: string;
}
const MenteeInfo = () => {
    let { menteeId, programId, mentorshipId } = useParams();
    const [menteeInfo, setMenteeInfo] = useState<IMenteeInfo | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tasks, setTasks] = useState<TaskMentor[] | null>(null)
    const [assignedTasks, setAssignedTasks] = useState<Task[]>([])
    const [programName, setProgramName] = useState<string>('');
    const [docs, setDocs] = useState<IDocs[]>([]);
    useEffect(() => {
        const fetchMentee = async () => {
            setIsLoading(true)
            const fetchedMenteeInfo = await getMenteeInfo(menteeId)
            const fetchedMenteeFilesList = await getMenteeFilesList(menteeId)
            if (fetchedMenteeInfo) {
                setMenteeInfo(fetchedMenteeInfo.data)
            }
            if (fetchedMenteeFilesList) {
                setDocs(fetchedMenteeFilesList.data)
            }
            const fetchTasks = await getTasks(programId)
            if (fetchTasks) {
                setTasks(fetchTasks.data)
            }
            const fetchedMentorship = await getMentorship(mentorshipId)
            if (fetchedMentorship) {
                setAssignedTasks(fetchedMentorship.data.assigned_tasks)
                setProgramName(fetchedMentorship.data.program.name)
            }
            setIsLoading(false)
        }

        fetchMentee();
    }, [])

    return (
        <div>
            {isLoading ? (
                <div className='v-100 position-relative' style={{ minHeight: '80vh' }}>
                    <div className="page-loading position-absolute" style={{
                        top: '50%',
                        left: "50%"
                    }}>
                        <span className="spinner-border text-primary" data-kt-app-page-loading='on' role="status" >
                            <span className="visually-hidden">Loading...</span>
                        </span>
                    </div>
                </div>) : (
                <div>
                    <h1>{programName}</h1>

                    <MenteeInfoCard
                        docs={docs}
                        id={menteeInfo?.id ?? 0}
                        programName={programName}
                        company={menteeInfo?.company ?? ""}
                        name={menteeInfo?.username ?? ""}
                        availability_per_week={menteeInfo?.availability_per_week ?? 0}
                        requested_mentorship_period_end={menteeInfo?.requested_mentorship_period_end ?? ''}
                        requested_mentorship_period_start={menteeInfo?.requested_mentorship_period_start ?? ''}
                    />
                    <AssignTaskCard setMenteeInfo={setMenteeInfo} setTasks={setTasks} availabilityPerWeek={menteeInfo?.availability_per_week ?? 0} filledHours={menteeInfo?.filled_hours} totalAvailable={menteeInfo?.total_availability_for_period} assignedTasks={assignedTasks} tasks={tasks} setAssignedTasks={setAssignedTasks} />
                </div>
            )}
        </div>
    );
};

export default MenteeInfo;