import { MasterLayout } from '_metronic/layout/MasterLayout';
import { useAuth } from 'app/modules/auth';
import Register from 'app/modules/register/pages/Register';
import { DashboardWrapper } from 'app/pages/dashboard/DashboardWrapper';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import MentorProgramWrapper from 'app/pages-mentor/program/MentorProgramWrapper';
import MentorProfile from 'app/pages-mentor/profile/MentorProfile';
import CalendarMentor from 'app/pages-mentor/calendar/pages/CalendarMentor.page';
import { MentorDashboardPage } from 'app/pages-mentor/dashboard/DashboardWrapper';
import MenteeProfile from 'app/pages-mentor/profile/mentee-profile/MenteeProfile';

const MentorRoutes = () => {
    const { currentUser } = useAuth()
    return (
        <>
            {currentUser?.registration_completed === true ? (
                <Routes>
                    <Route element={<MasterLayout />}>
                        <Route
                            path='*'
                            element={<Navigate to='/dashboard' />}>
                        </Route>
                        <Route
                            path='/dashboard'
                            element={<MentorDashboardPage />}>
                        </Route>
                        <Route
                            path='/calendar'
                            element={<CalendarMentor />}>
                        </Route>
                        <Route
                            path='/program/*'
                            element={<MentorProgramWrapper />}>
                        </Route>
                        <Route
                            path='/profile/*'
                            element={<MentorProfile />}>
                        </Route>
                        <Route
                            path='/mentee-profile/:menteeId'
                            element={<MenteeProfile />}>
                        </Route>
                    </Route>
                </Routes>
            ) :
                (
                    <Routes>
                        <Route
                            path='*'
                            element={<Navigate to='/mentor-register' />}
                        />
                        <Route
                            path='/mentor-register'
                            element={<Register role='mentor' />}
                        />
                    </Routes>
                )
            }
        </>
    );
};

export default MentorRoutes;