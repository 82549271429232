import { Route, Routes, useNavigate } from 'react-router-dom'
import { Registration } from './components/Registration'
import { ForgotPassword } from './components/ForgotPassword'
import { AuthLayout } from './AuthLayout'
import React, { useEffect } from 'react';
import axios from 'axios'
import { useAuth } from './hooks/Auth'
import { AuthModel, UserData, UserModel } from './types/_models'
import { API_URL } from 'app/consts/consts'
interface IProfileResponse {
  id: number,
  username: string,
  created_at: string,
  role: string | null,
  registration_status: {
    interest: boolean,
    files: boolean,
    skills: boolean,
  }
  registration_completed: boolean,
  availability_per_week: number | boolean,
  requested_mentorship_period_start: number | string | boolean,
  requested_mentorship_period_end: number | string | boolean,
  interests: Array<string>,
  documents: Array<string>,
  skills: Array<string>,
}

export const AuthPage = () => {
  const { saveAuth, setCurrentUser, currentUser, auth } = useAuth()
  const navigate = useNavigate()
  useEffect(() => {
    const state = new URLSearchParams(window.location.search).get('state')
    const code = new URLSearchParams(window.location.search).get('code')

    if (state && code) {
      axios.get<AuthModel>(`${API_URL}/get-token?code=${code}`).then((response) => {
        const auth = response.data
        //saveAuth(response.data)
        axios.get<UserData>(`${API_URL}/account/profile`, {
          headers: {
            'Authorization': `Bearer ${auth.access_token}`
          }
        }).then((response) => {
          const user = response.data.data;
          console.log(user.role === state || user.role === undefined || user.role === null)
          console.log(user.role === state, user.role === undefined, user.role === null)
          console.log(auth)
          if (user.role === state || user.role === undefined || user.role === null) {
            saveAuth(auth)

            localStorage.setItem('is_init', (user.role) ? user.role : '') // checking if user is already initialized and save his initialized role, otherwise save nothing
            localStorage.setItem('role', (!user.role) ? state : user.role); // checking if user is already initialized and save his role, otherwise save selected on a role screen role

            setCurrentUser({
              id: user.id,
              username: user.username,
              email: user.email,
              company: user.company,
              created_at: user.created_at,
              role: (!user.role) ? state : user.role,
              registration_status: {
                programs: user.registration_status.programs,
                documents: user.registration_status.documents,
                skills: user.registration_status.skills
              },
              registration_completed: user.registration_completed,
              availability_per_week: user.availability_per_week,
              requested_mentorship_period_start: user.requested_mentorship_period_start,
              requested_mentorship_period_end: user.requested_mentorship_period_end,
              programs: [],
              documents: [],
              skills: []
            })
          } else {
            navigate('/error/409')
          }
        })

      })
    }

    if (state) {
      console.log(state)
      console.log(auth)
      axios.get<UserData>(`${API_URL}/account/profile`, {
        headers: {
          'Authorization': `Bearer ${auth?.access_token}`
        }
      }).then((response) => {
        const user = response.data.data;
        console.log(user)
        console.log(user.role)
        localStorage.setItem('is_init', (user.role) ? user.role : '') // checking if user is already initialized and save his initialized role, otherwise save nothing

        localStorage.setItem('role', state); // checking if user is already initialized and save his role, otherwise save selected on a role screen role

        setCurrentUser({
          id: user.id,
          username: user.username,
          email: user.email,
          company: user.company,
          created_at: user.created_at,
          role: (!user.role) ? state : user.role,
          registration_status: {
            programs: user.registration_status.programs,
            documents: user.registration_status.documents,
            skills: user.registration_status.skills
          },
          registration_completed: user.registration_completed,
          availability_per_week: user.availability_per_week,
          requested_mentorship_period_start: user.requested_mentorship_period_start,
          requested_mentorship_period_end: user.requested_mentorship_period_end,
          programs: [],
          documents: [],
          skills: []
        })
      })
    }
  }, []);
  console.log(currentUser)
  return (
    <div className='vh-100 vw-100 position-relative'>
      <div className="page-loading position-absolute" style={{
        top: '50%',
        left: "50%"
      }}>
        <span className="spinner-border text-primary" data-kt-app-page-loading='on' role="status" >
          <span className="visually-hidden">Loading...</span>
        </span>
      </div>
    </div>
  )
}