import { toAbsoluteUrl } from '_metronic/helpers';
import { getFileDownloadLink } from 'app/modules/register/api/requests';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Link } from 'react-router-dom';
type Props = {
  id: number
  name: string
  availability_per_week: number;
  requested_mentorship_period_end: string;
  requested_mentorship_period_start: string;
  company: string;
  programName: string;
  docs: IDocs[];

}

interface IDocs {
  file_id: number;
  id: number;
  name: string;
  type: string;
}
const formatDate = (start: string, end: string): string => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  const startDateString = `${startDate.getDate()} ${getMonthName(startDate.getMonth())}`;
  const endDateString = `${endDate.getDate()} ${getMonthName(endDate.getMonth())}`;
  const year = endDate.getFullYear();

  const periodString = `${startDateString} - ${endDateString} ${year}`;

  function getMonthName(monthIndex) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months[monthIndex];
  }

  return periodString;
}
const MenteeInfoCard: FC<Props> = ({
  id,
  name,
  docs,
  programName,
  availability_per_week,
  requested_mentorship_period_start,
  requested_mentorship_period_end,
  company,
}) => {
  const [loadingBtns, setLoadingBtns] = useState<{ [key: string]: boolean }>({});

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='Metornic' />
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <p className='m-0 p-0 text-gray-800 fs-2 fw-bolder me-1'>
                    {name}
                  </p>
                </div>

                <div className='d-flex flex-column fw-bold fs-6 pe-2'>
                  <p
                    className='m-0 p-0 d-flex align-items-center text-gray-400 me-5 mb-2'
                  >
                    {programName}
                  </p>
                  <p
                    className='m-0 p-0 d-flex align-items-center text-gray-400 me-5 mb-2'
                  >
                    {company}
                  </p>
                </div>
              </div>

              <div className='d-flex my-4'>
                <Link
                  type='button'
                  className='btn btn-sm btn-primary me-3'
                  to={`/mentee-profile/${id}`}

                >
                  View Mentee Profile
                </Link>

              </div>
            </div>

            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-6 fw-bolder'>{formatDate(requested_mentorship_period_start, requested_mentorship_period_end)}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Mentorship period</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-6 fw-bolder'>{availability_per_week} hours</div>
                    </div>
                    <div className='fw-bold fs-6 text-gray-400'>Availability per week</div>
                  </div>
                </div>
              </div>

              <div className='d-flex align-items-center flex-column mt-3 me-20 pr-10s'>
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <div className='d-flex flex-row gap-5 h-100'>

                    {docs.map((doc, index) => {
                      return (
                        <div className='d-flex flex-row gap-3'>
                          <img
                            className='mt-1 align-self-start'
                            alt='Pic'
                            width='40'
                            src={toAbsoluteUrl(
                              '/media/icons/duotune/files/pdf.png'
                            )}
                          />
                          <div>
                            <p className='m-0 fw-bold'>{doc.type}</p>
                            <span className='btn p-0 m-0 text-hover-primary fw-thin' onClick={() => {
                              setLoadingBtns((prevLoadingBtns) => ({
                                ...prevLoadingBtns,
                                [doc.id]: true,
                              }));
                              getFileDownloadLink(doc?.id).finally(() => {
                                setLoadingBtns((prevLoadingBtns) => ({
                                  ...prevLoadingBtns,
                                  [doc.id]: false,
                                }));
                              });
                            }}> {loadingBtns[doc.id] ? 'Loading...' : 'View'}</span>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>



      </div>
    </div>
  )
}

export { MenteeInfoCard }