import { toAbsoluteUrl } from '../../helpers';
import { API_URL } from 'app/consts/consts'
import axios from 'axios'
import React, { RefObject, useEffect, useState } from 'react'

export interface IChat {
    conversation_id: number;
    created_at: string;
    last_message: { id: number; body: string; created_at: string };
    participant: { id: number; name: string };
    updated_at: string;
}
interface Props {
    onSelectChat: (chat: IChat) => void;
}
const ChatLists: React.FC<Props> = ({ onSelectChat }) => {
    const [chats, setChats] = useState<IChat[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    }
    const filteredChats = chats.filter(chat =>
        chat.participant.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const getChats = async () => {
        setIsLoading(true);
        axios.get(`${API_URL}/conversations`).then((response) => {
            setChats(response.data.data)
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setIsLoading(false);
        })
    }
    const handleChatSelect = (chat: IChat) => {
        onSelectChat(chat);
    };
    useEffect(() => {
        getChats()
    }, [])
    return (
        <div className='card-body'>
            {isLoading ? (
                <div className='v-100 position-relative' style={{ minHeight: '86%' }}>
                    <div className="page-loading position-absolute" style={{
                        top: '50%',
                        left: "50%"
                    }}>
                        <span className="spinner-border text-primary" data-kt-app-page-loading='on' role="status" >
                            <span className="visually-hidden">Loading...</span>
                        </span>
                    </div>
                </div>
            ) : (<div>
                <input className="form-control form-control-solid h-50px" placeholder="Search" value={searchTerm} onChange={handleSearch}></input>
                <div className="card-body card-scroll px-0">
                    <div className='users'>
                        {filteredChats.map(chat => (
                            <div key={chat.conversation_id} className='d-flex justify-content-between mb-4 overlay' style={{ alignItems: 'baseline' }}>
                                <div className='d-flex flex-row align-items-center'>
                                    <div className='w-50px h-50px rounded align-self-start' style={{ marginRight: 10 }}>
                                        <img
                                            className='h-50px w-50px rounded'
                                            src={toAbsoluteUrl('/media/avatars/blank.png')}
                                            alt='metronic'
                                        />
                                    </div>
                                    <div className='align-self-center'>
                                        <p className='p-0 m-0 fw-bolder text-hover-primary cursor-pointer fs-5' onClick={(event) => { event.stopPropagation(); handleChatSelect(chat); }}>{chat.participant.name}</p>
                                        <p className='fs-6'>{chat.last_message?.body}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>)}

        </div>
    )
}
export default ChatLists;
