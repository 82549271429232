import { KTIcon, KTSVG, toAbsoluteUrl } from '_metronic/helpers';
import LargeLink from 'components/program/LargeLink.component';
import React, { useEffect, useState } from 'react';
import SubmitDescription from '../component/SubmitDescription.component';
import fileDownload from 'js-file-download';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { API_URL } from 'app/consts/consts';
import path from 'path';
import ContactAdmin from 'components/chat-contact/ContactAdmin.component';
import StepActivity from './StepActivity.component';

export interface IStep {
    description: string | null;
    duration: number,
    id: number,
    moodle_url: string;
    moodle_number: number | null,
    name: string,
    practice: null | string,
    self_learning: number,
    step_code: number | null,
    task_id: number,
    training: string | null,
}

const StepDevelopment = () => {
    const { stepId } = useParams();
    const [stepInfo, setStepInfo] = useState<IStep>({ moodle_url: '', description: '', duration: 0, id: 0, moodle_number: 0, name: '', practice: '', self_learning: 0, step_code: 0, task_id: 0, training: '' })
    const [stepContentSubmission, setStepContentSubmission] = useState<any[]>([])
    const getStepInfo = async () => {
        const response = await axios.get(`${API_URL}/steps/${stepId}`)
        setStepInfo(response.data.data)
        console.log(response.data.data)
    }

    const getStepContentSubmission = async () => {
        const response = await axios.get(`${API_URL}/step-content-submissions?step_id=${stepId}`)
        setStepContentSubmission(response.data.data)
        console.log(response.data.data)
    }

    const getCourseIdFromMoodleUrl = (url: string) => {
        return new URL(url).searchParams.get('id')
    }

    const specialMoodleUrl = (url: string) => {
        // https://moodle.ilabour.masulink.in/?mentee_token=<TOKEN>&course_id=<ID>

        const courseId = getCourseIdFromMoodleUrl(url)
        let token = localStorage.getItem('kt-auth-react-v')
        token = token ? JSON.parse(token).access_token : null

        if (!courseId || !token) {
            return null
        }
        console.log('https://moodle.ilabour.masulink.in/?token=' + token + 'redirect_path=course/view.php?id=' + courseId)
        return 'https://moodle.ilabour.masulink.in/?token=' + token + '&redirect_path=course/view.php?id=' + courseId
    }


    useEffect(() => {
        getStepInfo()
        getStepContentSubmission()
    }, [])
    return (
        <div>
            <div>
                <div className='w-100 row g-5 g-xl-8 row mb-10'>
                    <div className='col-xl-6 d-flex flex-column'>
                        <h1>{stepInfo.name}</h1>
                        {/* <span className='text-secondary'>Step Task Name</span>
                        <span className='text-secondary'>Program Name</span> */}
                    </div>
                    <div className='col-xl-6 d-flex flex-row align-items-center justify-content-sm-start justify-content-xl-end p-0'>
                        <ContactAdmin />
                    </div>
                </div>
                <div className='row g-5 g-xl-8 mb-15 w-100'>
                    <div className='col-xl-6 d-flex flex-column '>
                        <h1>Step Description</h1>

                        <span>{
                            stepInfo.description ? stepInfo.description : <span className='text-muted'>No Description</span>
                        }</span>
                    </div>
                    <div className='col-xl-6 d-flex justify-content-sm-start justify-content-xl-end'>
                        {
                            stepInfo.moodle_url &&
                            <LargeLink
                                newTab={true}
                                isDisable={false} imgUrl={"/media/svg/mos.svg"} url={stepInfo.moodle_url && specialMoodleUrl(stepInfo.moodle_url) || ''} text={"Develop Course Content"} />

                        }
                    </div>
                </div>
                <div className='row g-5 g-xl-8 mb-7'>
                    <div className='col-xl-6 mt-10'>

                        {
                            stepContentSubmission.length > 0 ? (
                                // there is submission
                                stepContentSubmission[stepContentSubmission.length - 1].approved === null ?
                                    // there is submission but not approved yet
                                    // TODO: Pending Alert
                                    <>
                                        <WaitingAlert />
                                    </>

                                    : (
                                        // there is submission and approved or rejected
                                        stepContentSubmission[stepContentSubmission.length - 1].approved === true ?
                                            // approved
                                            // TODO: Approved Alert
                                            // TODO: Show the content
                                            <>
                                                <ApprovedAlert />
                                                <SubmissionContent submission={stepContentSubmission[stepContentSubmission.length - 1]} />
                                            </>
                                            : (
                                                // rejected
                                                stepContentSubmission[stepContentSubmission.length - 1].approved === false ?
                                                    // rejected
                                                    // TODO: Rejected Alert
                                                    // TODO: Show the content
                                                    // TODO: StepDescription component
                                                    <>
                                                        <RejectedAlert />
                                                        <SubmissionContent submission={stepContentSubmission[stepContentSubmission.length - 1]} />
                                                        <SubmitDescription />
                                                    </>
                                                    :
                                                    // there is submission but not approved yet
                                                    <SubmitDescription />
                                            )
                                    )
                            ) :
                                // there is no submission
                                <SubmitDescription />

                        }



                    </div>
                    <div className='col-xl-6'>
                        <StepActivity activities={stepContentSubmission[stepContentSubmission.length - 1]?.activities} />
                    </div>
                </div>
            </div>
        </div>
    );
};

const SubmissionContent = ({ submission }: any) => {

    async function downloadSubmissionFile(path: any) {
        if (!path) {
            return;
        }
        const response = await axios.get(`${API_URL}${path}`, { responseType: 'arraybuffer' });

        fileDownload(response.data, `${Date.now()}-Submission.pdf`)
    }
    return (
        <div className='card my-3'>
            <div className="card-header m-0">
                <div className="card-title p-0">
                    <h3 className="p-0 card-label">
                        Last Submission
                    </h3>
                </div>
            </div>

            <div className='card-body'>

                <div>
                    <p>
                        {
                            submission ?
                                submission.content.map((sub: any, index: number) => {
                                    return (
                                        <>
                                            <div key={index}>
                                                <span>{sub}</span>
                                            </div>
                                        </>
                                    )
                                }) : 'No submission'
                        }
                    </p>
                    <div className='d-flex flex-row gap-3 mb-7'>
                        <div>
                            <img
                                className='mt-1 align-self-start'
                                alt='Pic'
                                width='40'
                                src={toAbsoluteUrl(
                                    '/media/icons/duotune/files/pdf.png'
                                )}
                            />
                        </div>
                        <div>
                            <p className='m-0 fw-bold'>File</p>

                            {submission.uploads.length > 0 ? (
                                submission.uploads.map((upload: any, index: number) => {
                                    return (
                                        <span key={index} className='btn p-0 m-0 text-hover-primary fw-thin' onClick={() => {
                                            downloadSubmissionFile(upload.path)
                                        }}>View</span>
                                    )

                                }
                                )
                            ) : 'No file uploaded'}

                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export const WaitingAlert = () => {
    return (
        <div style={{ minHeight: '90px' }} className='cursor-default bg-light btn btn-outline btn-outline-dashed btn-outline-default p-2 d-flex flex-column align-items-start justify-content-center'
        >
            <div className='d-flex flex-row gap-5 '>
                <img alt='banner' className='' src={toAbsoluteUrl('/media/svg/Oval.svg')} />
                <div>
                    <p className='text-align-left mb-2 p-0 fw-bold' style={{ textAlign: 'left' }}>Waiting for admin review action!</p>
                    <p className='text-secondary p-0 m-0' style={{ textAlign: 'left' }}>You will be able to see a submission screen, if the admin rejects submission.</p>
                </div>
            </div>
        </div>
    );
}

export const ApprovedAlert = () => {
    return (
        <div style={{ minHeight: '90px' }} className='card m-0 cursor-default bg-light btn btn-outline btn-outline-dashed btn-outline-default p-2 d-flex flex-column align-items-start justify-content-center'
        >
            <div className='d-flex flex-row gap-5 '>
                <img alt='banner' className='' src={toAbsoluteUrl('/media/svg/Oval.svg')} />
                <div>
                    <p className='text-align-left mb-2 p-0 fw-bold' style={{ textAlign: 'left' }}>Congratulations!</p>
                    <p className='text-secondary p-0 m-0' style={{ textAlign: 'left' }}>You completed the task. </p>
                </div>
            </div>
        </div>
    );
}

export const RejectedAlert = () => {
    return (
        <div style={{ borderColor: '#F64E60', minHeight: '90px' }} className='cursor-default bg-light-danger btn btn-outline btn-outline-dashed btn-outline-default p-2 d-flex flex-column align-items-start justify-content-center'
        >
            <div className='d-flex flex-row gap-5 '>
                <img alt='warning-banner' className='' src={toAbsoluteUrl('/media/svg/Warning.svg')} />
                <div>
                    <p className='text-align-left mb-2 p-0 fw-bold' style={{ textAlign: 'left' }}>Your submitted task has been rejected.</p>
                    <p className='text-secondary p-0 m-0' style={{ textAlign: 'left' }}>Please see rejection details on the flow and submit a revision.</p>
                </div>
            </div>
        </div>
    );
}



export default StepDevelopment;