import {KTSVG} from '_metronic/helpers'
import React, {useEffect, useState} from 'react'
import {ErrorMessage, Field, Form, Formik} from 'formik'

import DatePickerPeriods from './DatePickerPeriods.component'
import {API_URL} from 'app/consts/consts'
import axios from 'axios'
import {showErrorToaster} from 'utils/toasters'
import {Exception} from 'sass'

const CreateTeamwork = ({showError}) => {
  const [activeSteps, setActiveSteps] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [selectedStep, setSelectedStep] = useState<any>(null)
  const [description, setDescription] = useState<any>(null)
  const [startDate, setStartDate] = useState<any>(null)

  const getActiveSteps = async () => {
    setLoading(true)
    try {
      await axios.get(`${API_URL}/mentorships/active-steps`).then((response) => {
        setActiveSteps(
          response?.data?.data.map((item: any) => {
            return {
              title: item?.name,
              value: item?.id,
            }
          })
        )
      })
    } catch (e) {
      showError('wrong')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getActiveSteps()
  }, [])

  const postTeamwork = async (stepId: any) => {
    setLoading(true)
    try {
      await axios
        .post(`${API_URL}/mentorship-task-steps/${stepId}/teamwork`, {
          step: selectedStep,
          description: description,
          //            "The start date field must match the format Y-m-d H:i:s.",

          start_date: new Date(startDate).toISOString().slice(0, 19).replace('T', ' '),
        })
        .then((response) => {
          console.log(response)
          window.location.reload()
        })
        .catch((e) => {
          console.log(e)
          showError(`${e?.response.data.message}.`)
          showErrorToaster(e as string)
        })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='modal fade' tabIndex={-1} id='create-teamwork'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Teamwork</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <div className='row g-9'>
              <div className='col-md-12 col-lg-12 col-xl-12'>
                <div className='input-group input-group-solid mb-3 d-flex flex-column'>
                  <label className='form-label'>Step *</label>
                  <select
                    className='form-control w-100'
                    name='step'
                    value={selectedStep}
                    onChange={(e) => setSelectedStep(e.target.value)}
                  >
                    <option value='' label='Select an active step' />
                    {activeSteps.map((item: any) => (
                      <option value={item.value} key={item.value}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='input-group input-group-solid mb-3 d-flex flex-column'>
                  <label className='form-label'>Description *</label>
                  <DatePickerPeriods startDate={startDate} setStartDate={setStartDate} />
                </div>
                <div className='input-group input-group-solid mb-3 d-flex flex-column'>
                  <label className='form-label'>Description *</label>
                  <textarea
                    className='form-control w-100'
                    name='description'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
              Close
            </button>
            <button
              type='button'
              className='btn btn-primary'
              disabled={
                description === null ||
                selectedStep === null ||
                startDate === null ||
                loading === true
              }
              onClick={() => {
                postTeamwork(selectedStep)
              }}
            >
              {loading ? 'Creating...' : 'Create'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateTeamwork
