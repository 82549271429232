import { toAbsoluteUrl } from '_metronic/helpers';
import React from 'react';

const FailedBanner = () => {
    return (
        <div className="alert alert-danger d-flex align-items-center p-5 mb-10">
            <span className="svg-icon svg-icon-2hx svg-icon-primary me-3">
                <img alt='banner' className=''
                    src={toAbsoluteUrl('/media/svg/Warning.svg')} />
            </span>

            <div className="d-flex flex-column">
                <h5 className="mb-1">Failed!</h5>
                <span>You failed to complete the task.</span>
            </div>
        </div>
    );
};

export default FailedBanner;