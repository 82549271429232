import {KTIcon, KTSVG} from '_metronic/helpers'
import {API_URL} from 'app/consts/consts'
import {useAuth} from 'app/modules/auth'
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {convertTimestamp} from 'utils/stringDateFunctions'
interface IMentorships {
  id: number
  name: string
  mentee_user: {id: number; name: string}
  created_at: string
  program: {id: number; name: string}
}

export const MentorCompletedMentorships = () => {
  const {currentUser} = useAuth()

  const [mentorships, setMentorships] = useState<IMentorships[]>([])
  const [isMentorshipLoading, setIsMentorshipLoading] = useState<boolean>(false)
  const getMentorships = async () => {
    try {
      setIsMentorshipLoading(true)
      const response = await axios.get(
        `${API_URL}/users/${currentUser?.id}/mentorships?status=completed`
      )
      if (response.status === 200) {
        setMentorships(response.data.data)
      }
    } catch (err) {
      setMentorships([])
    } finally {
      setIsMentorshipLoading(false)
    }
  }

  useEffect(() => {
    getMentorships()
  }, [])
  return (
    <>
      <div className='card card-custom card-flush'>
        <div className='card-header'>
          <h3 className='card-title'>Completed Mentorships</h3>
        </div>
        <div className='card-body py-5'>
          <table className='table gs-7 gy-7 gx-7'>
            <tbody>
              {mentorships?.map((metorship) => (
                <TableTaskItem
                  key={metorship.id}
                  id={metorship.id}
                  programName={metorship.mentee_user.name}
                  taskName={metorship.program.name}
                  taskDate={metorship.created_at}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

const TableTaskItem = ({id, programName, taskName, taskDate}) => {
  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          <div className='symbol symbol-45px me-5'>
            <KTIcon className='symbol-label' iconName='home' />
          </div>
          <div className='d-flex justify-content-start flex-column'>
            <a href='#' className='text-gray-900 fw-bold text-hover-primary fs-6'>
              {programName}
            </a>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>{taskName}</span>
          </div>
        </div>
      </td>

      <td className='text-end'>
        <div className='d-flex justify-content-start flex-column'>
          <span className='text-gray-900 fw-bold fs-6'>
            {convertTimestamp(taskDate).date}
          </span>
          <MentorReviewModal
            id={1}
            date={1}
            rejectionReason={'mentorReview.rejectionReason'}
            finishTime={'mentorReview.finishTime'}
            completedSteps={'mentorReview.completedSteps'}
            mentorName={'mentorReview.mentorName'}
            score={4}
            taskName={'mentorReview.taskName'}
          />
        </div>
      </td>
    </tr>
  )
}

const MentorReviewModal = ({
  id,
  date,
  mentorName,
  taskName,
  score,
  rejectionReason,
  finishTime,
  completedSteps,
}) => {
  return (
    <>
      <span
        data-bs-toggle='modal'
        data-bs-target={`#kt_modal_${id}`}
        className='text-muted fw-semibold text-muted d-block fs-7 cursor-pointer text-hover-primary '
      >
        See Mentee Review
      </span>
      <div className='modal fade' tabIndex={-1} id={`kt_modal_${id}`} aria-hidden='true'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header d-flex align-items-start'>
              <div className=' d-flex flex-column'>
                <h5 className='text-start modal-title fs-6'>
                  {mentorName} | {taskName}
                </h5>
                <span className='text-start text-muted fw-semibold d-block fs-7'>
                  Task Score by Mentor: &nbsp; <Rating score={score} />
                </span>
              </div>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body text-start'>
              <div>
                <p>
                  <span className='fw-bold text-muted'>Date of Review: &nbsp;</span>
                  {date}
                </p>
              </div>
              <div>
                <span className='fw-bold'>Rejection reason:</span>
                <p>{rejectionReason}</p>
              </div>
              <div className='d-flex'>
                <div className='border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 me-6 mb-3'>
                  <span className='fs-6 text-gray-700 fw-bold'>{finishTime}</span>

                  <div className='fw-semibold text-gray-500'>Finish Time</div>
                </div>

                <div className='border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 mb-3'>
                  <span className='fs-6 text-gray-700 fw-bold'>{completedSteps}</span>

                  <div className='fw-semibold text-gray-500'>Completed Steps </div>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
const Rating = ({score}) => {
  return (
    <div className='rating'>
      {[...Array(score)].map((e, i) => (
        <div key={i} className='rating-label checked'>
          <KTSVG path='media/icons/duotune/general/gen029.svg' className='svg-icon svg-icon-1' />
        </div>
      ))}
      {[...Array(5 - score)].map((e, i) => (
        <div key={i} className='rating-label'>
          <KTSVG path='media/icons/duotune/general/gen029.svg' className='svg-icon svg-icon-1' />
        </div>
      ))}
    </div>
  )
}
