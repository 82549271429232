import { toAbsoluteUrl } from '_metronic/helpers';
import { API_URL } from 'app/consts/consts';
import { IFile } from 'app/modules/register/types/registerTypes';
import axios from 'axios';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

interface IFormBody {
    content: string[];
    files: File[] | null;
}
const initialValues: IFormBody = {
    content: [],
    files: null
};
const validationSchema = Yup.object().shape({
    context: Yup.string().required('Textarea is required'),
    files: Yup.mixed().test('fileType', 'Invalid file type. Please choose a PDF, DOC, or TXT file.', (value: any) => {
        if (!value) return false; // Allow empty file
        return ['application/pdf', 'application/msword', 'text/plain'].includes(value.type);
    })
});
const SubmitDescription = () => {
    const [isSent, setIsSent] = useState<boolean>(false);
    const { stepId, taskId } = useParams()
    const [selectedFile, setSelectedFile] = useState<IFile | null>(null);
    const submitForm = async (values: IFormBody, setSubmitting) => {
        try {
            setSubmitting(true);

            const formData = new FormData();

            values.content.forEach((content, index) => {
                formData.append(`content[${index}]`, content);
            });
            if (values.files) {
                values.files.forEach((file, index) => {
                    formData.append(`files[${index}]`, file);
                });
            }

            console.log(values);
            const response = await axios.post(`${API_URL}/steps/${stepId}/content-submissions`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).finally(() => {
                // refresh page

                window.location.reload();
            }
            );

            console.log(response);
            setIsSent(true)
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setSubmitting(false);
        }
    };
    const handleDivClick = (fileType) => {
        document?.getElementById(`${fileType}`)?.click();
    };
    const handleFileChange = async (e, fileType, setFieldValue) => {
        try {
            e.preventDefault();
            const allowedFileTypes = ['application/pdf', 'application/msword', 'text/plain'];
            if (e.target.files[0]) {
                const selectedFile = e.target.files[0];
                console.log(e.target.id);
                if (allowedFileTypes.includes(selectedFile.type)) {
                    setSelectedFile({
                        file_id: fileType,
                        file: selectedFile,
                        name: selectedFile.name,
                    });

                    setFieldValue('files', [selectedFile]);
                } else {
                    console.log('Invalid file type. Please choose a PDF, DOC, or TXT file.');
                }
            }
        } catch (e) {
            console.log('errr', e);
        }
    };
    return (
        <div className='card'>
            <div className="card-header m-0">
                <div className="card-title p-0">
                    <h3 className="p-0 card-label">
                        Submit Description
                    </h3>
                </div>
            </div>

            <div className='card-body'>
                <Formik onSubmit={(values, { setSubmitting }) => {
                    submitForm(values, setSubmitting);
                }} initialValues={initialValues}>
                    {({ setFieldValue, handleChange, handleBlur, values, isSubmitting }) => (
                        <Form>
                            <FieldArray name='context'>
                                {() => (
                                    <div>
                                        <div className="input-group input-group-solid mb-3 d-flex flex-column">
                                            {/* <Field name="content[0]" type="textarea"
                                                className="form-control w-100"
                                                style={{ backgroundColor: '#EEF1F5' }}
                                                placeholder="Mention the theoretical background. It should be one paragraph and easy to understand. "
                                                aria-describedby="basic-addon2"
                                            /> */}
                                            <textarea
                                                rows={5}
                                                name="content[0]"
                                                className="form-control w-100"
                                                style={{ backgroundColor: '#EEF1F5' }}
                                                placeholder="Mention the theoretical background. It should be one paragraph and easy to understand. "
                                                aria-describedby="basic-addon2"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.content[0]}
                                            />

                                            <ErrorMessage name="content[0]" component="div" className="text-danger" />
                                        </div>
                                        <div className="input-group input-group-solid mb-3 d-flex flex-column">
                                            {/* <Field
                                                name="content[1]"
                                                type="textarea"
                                                className="form-control w-100"
                                                style={{ backgroundColor: '#EEF1F5' }}
                                                placeholder="Create a task. It should be one paragraph and easy to understand."
                                                aria-describedby="basic-addon2"
                                            /> */}
                                            <textarea
                                                rows={5}
                                                name="content[1]"
                                                className="form-control w-100"
                                                style={{ backgroundColor: '#EEF1F5' }}
                                                placeholder="Create a task. It should be one paragraph and easy to understand."
                                                aria-describedby="basic-addon2"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.content[1]}
                                            />
                                            <ErrorMessage name="content[1]" component="div" className="text-danger" />
                                        </div>
                                        <div className="input-group input-group-solid mb-3 d-flex flex-column">
                                            {/* <Field
                                                name="content[2]"
                                                type="textarea"
                                                className="form-control w-100"
                                                style={{ backgroundColor: '#EEF1F5' }}
                                                placeholder="Provide information for the feedback method. It should be one paragraph and easy to understand."
                                                aria-describedby="basic-addon2"
                                            /> */}
                                            <textarea
                                                rows={5}
                                                name="content[2]"
                                                className="form-control w-100"
                                                style={{ backgroundColor: '#EEF1F5' }}
                                                placeholder="Provide information for the feedback method. It should be one paragraph and easy to understand."
                                                aria-describedby="basic-addon2"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.content[2]}
                                            />
                                            <ErrorMessage name="content[3]" component="div" className="text-danger" />
                                        </div>
                                    </div>
                                )}
                            </FieldArray>
                            <label
                                className='mt-3 bg-light btn btn-outline btn-outline-dashed btn-outline-default w-100 p-2 d-flex flex-row justify-content-between'
                                htmlFor='selectedFile'
                                onClick={() => handleDivClick(`task`)}
                            >
                                <div className='d-flex flex-row'>
                                    <img
                                        src={toAbsoluteUrl('/media/icons/duotune/files/fil009.svg')}
                                        className='w-50px h-40px align-self-center'
                                        alt=''
                                    />
                                    <input
                                        type="file"
                                        accept=".pdf, .doc, .docx, .txt"
                                        multiple={false}
                                        id={`task`}
                                        style={{ display: 'none' }}
                                        onChange={(e) => handleFileChange(e, `task`, setFieldValue)}
                                    />
                                    <div className='text-start align-self-end pt-3'>
                                        <label className='form-label'>Quick file uploader</label>
                                        <p className='text-secondary fs-7'>
                                            {selectedFile ? `File: ${selectedFile.file.name}` : ('Drag & Drop or choose files from computer')}
                                        </p>
                                    </div>
                                </div>
                            </label>
                            <div className="card-footer px-0">
                                <button type="submit" className="btn btn-primary" disabled={isSent}>
                                    {isSubmitting ? 'Sending...' : 'Send'}
                                </button>

                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div >
    );
};

export default SubmitDescription;