import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'
import CreateTeamwork from './components/CreateTeamwork.modal'
import axios from 'axios'
import { API_URL } from 'app/consts/consts'
import { useEffect, useState } from 'react'
import { KTIcon, KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from 'app/modules/auth'
import { showErrorToaster } from 'utils/toasters'
const CalendarMentee = () => {
  const [events, setEvents] = useState<any>([])
  const [selectedEvent, setSelectedEvent] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  var todayDate = moment().startOf('day')
  var YM = todayDate.format('YYYY-MM')

  const eventClick = (info: any) => {
    console.log(info.event)
    setSelectedEvent(info.event)
    if (info?.event?.extendedProps?.eventType === 'teamwork') {
      document.getElementById('kt_modal_teamwork_button')?.click()
    } else {
      document.getElementById('kt_modal_training_button')?.click()
    }
  }
  const eventTypeColorResolver = (eventType: string) => {
    switch (eventType) {
      case 'training':
        return '#00A3FF'
      case 'teamwork':
        return '#FFA800'
      case 'meeting':
        return '#6C7293'
      default:
        return 'purple'
    }
  }

  const getCalendarEvents = async () => {
    const firstDateOfYear = moment().startOf('year').format('YYYY-MM-DD')
    const lastDateOfYear = moment().endOf('year').format('YYYY-MM-DD')
    setLoading(true)
    try {
      await axios
        .get(`${API_URL}/calendar`, {
          params: {
            start_date: firstDateOfYear,
            end_date: lastDateOfYear,
          },
        })
        .then((response) => {
          console.log(response.data)

          setEvents(
            Object.keys(response.data)
              .map((key) => {
                return response.data[key].map((event) => {
                  const time = new Date(event.date).toISOString().split('T')[1].split('Z')[0]
                  const add40Minutes = moment(time, 'HH:mm').add(40, 'minutes').format('HH:mm')
                  return {
                    allDay: true,
                    color: eventTypeColorResolver(event.event_type),
                    title: event.title,
                    eventType: event.event_type,
                    className: 'fc-event-primary my-1 py-1 px-2 rounded-2',
                    start: new Date(event.date).toISOString(),
                    end:
                      new Date(event.date).toISOString().split('T')[0] + 'T' + add40Minutes + 'Z',
                    description: event.title,
                    trainingId: event.event_type === 'training' ? event.action.training.id : null,
                    teamworkId: event.event_type === 'teamwork' ? event.action.teamwork.id : null,
                  }
                })
              })
              .flat()
          )
        })
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getCalendarEvents()
  }, [])

  return (
    <div>
      <CreateTeamwork showError={showErrorToaster} />
      <TeamworkModal event={selectedEvent} />
      <TrainingModal event={selectedEvent} isLoading={loading} />
      <ToastContainer />

      <div className='w-100 d-flex flex-row justify-content-between mb-9'>
        <h1>Calendar</h1>
        <a
          className='btn btn-sm btn-primary'
          data-bs-toggle='modal'
          data-bs-target='#create-teamwork'
        >
          Create Teamwork
        </a>
      </div>
      <div className='card'>
        <div className='card-body'>
          <FullCalendar
            eventClick={eventClick}
            plugins={[dayGridPlugin, timeGridPlugin]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              end: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            navLinks={true} // can click day/week names to navigate views
            selectMirror={true}
            editable={true}
            dayMaxEvents={true} // allow "more" link when too many events
            events={events}
          />
        </div>
      </div>
    </div>
  )
}

const TeamworkModal = ({ event }) => {
  // current user
  const { currentUser } = useAuth()

  const [teamwork, setTeamwork] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  const getTeamwork = async (teamworkId: number) => {
    setLoading(true)
    try {
      const response = await axios.get(`${API_URL}/teamwork/${teamworkId}`)
      setTeamwork(response.data?.data)
      console.log(response.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const registerTeamwork = async (teamworkId: number) => {
    setLoading(true)
    try {
      const response = await axios.post(`${API_URL}/teamwork/${teamworkId}/join`)
      console.log(response.data)
    } catch (error) {
      showErrorToaster('Sorry, something went wrong')

      console.log(error)
      getTeamwork(teamworkId)
    } finally {
      setLoading(false)
    }
  }

  const leaveTeamwork = async (teamworkId: number) => {
    setLoading(true)
    try {
      const response = await axios.post(`${API_URL}/teamwork/${teamworkId}/leave`)
      console.log(response.data)
    } catch (error) {
      showErrorToaster('Sorry, something went wrong')

      console.log(error)
      getTeamwork(teamworkId)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (event) {
      getTeamwork(event?.extendedProps?.teamworkId)
    }
  }, [event])

  return (
    <>
      <button
        type='button'
        className='btn btn-primary'
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_teamwork'
        id='kt_modal_teamwork_button'
        style={{ display: 'none' }}
      ></button>
      <div className='modal fade' tabIndex={-1} id='kt_modal_teamwork'>
        {loading ? (
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='v-30 position-relative' style={{ minHeight: '30vh' }}>
                <div
                  className='page-loading position-absolute'
                  style={{
                    top: '50%',
                    left: '50%',
                  }}
                >
                  <span
                    className='spinner-border text-primary'
                    data-kt-app-page-loading='on'
                    role='status'
                  >
                    <span className='visually-hidden'>Loading...</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>{event?.extendedProps?.description} (Teamwork)</h5>

                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  <KTSVG
                    path='media/icons/duotune/arrows/arr061.svg'
                    className='svg-icon svg-icon-2x'
                  />
                </div>
              </div>
              <div className='modal-body'>
                <div>
                  <p>
                    Moderator: {teamwork?.owner?.name}
                    {teamwork?.owner?.id === currentUser?.id ? <span>(You)</span> : ''}
                  </p>
                </div>
                <div>
                  <h3>Description</h3>
                  <p className='text-muted'>{teamwork?.description}</p>
                </div>

                <div className='d-flex flex-row gap-3'>
                  {new Date().getTime() <= new Date(teamwork?.start_date || '').getTime() ? (
                    <span
                      className='badge p-3 fs-7 fw-semibold'
                      style={{ backgroundColor: '#50CD89', color: '#F5F5F5' }}
                    >
                      Scheduled
                    </span>
                  ) : (
                    <span
                      className='badge p-3 fs-7 fw-semibold'
                      style={{ backgroundColor: '#F23F44', color: '#fff' }}
                    >
                      Expired
                    </span>
                  )}

                  <span className='badge p-3 fs-7 fw-semibold' style={{ backgroundColor: '#EEF1F5' }}>
                    {event?.start?.toISOString().split('T')[0]}
                  </span>
                  <span className='badge p-3 fs-7 fw-semibold' style={{ backgroundColor: '#EEF1F5' }}>
                    {
                      event?.start?.toISOString().split('T')[1].split(':')[0] + ':' + event?.start?.toISOString().split('T')[1].split(':')[1]
                    }
                    {' - '}
                    {
                      moment(

                        event?.start?.toISOString().split('T')[0] + ' ' + event?.start?.toISOString().split('T')[1].split(':')[0] +
                        ':' +
                        event?.start?.toISOString().split('T')[1].split(':')[1]
                      ).add(40, 'minutes').format('HH:mm')

                    }
                    {/* {
                      event?.start?.toISOString().split('T')[1].split('Z')[0].split(':')[0] +
                      ':' +
                      event?.start?.toISOString().split('T')[1].split('Z')[0].split(':')[1] +
                      ' - ' +
                      event?.end?.toISOString().split('T')[1].split('Z')[0].split(':')[0] +
                      ':' +
                      event?.end?.toISOString().split('T')[1].split('Z')[0].split(':')[1]
                    } */}
                  </span>
                </div>
              </div>
              <div className='modal-footer'>
                {/* {teamwork?.owner?.id}
                                {currentUser?.id}
                                {teamwork?.owner?.id !== currentUser?.id ? 'No' : 'Yes'}
                                {teamwork?.participants?.filter((participant: any) => participant.id === currentUser?.id).length === 0 ? 'Not registered' : 'Registered'} */}
                <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                  Close
                </button>
                {new Date().getTime() <= new Date(teamwork?.start_date || '').getTime() &&
                  // if current user is not the owner of the teamwork
                  // and teamwork.participants does not include current user

                  (teamwork?.owner?.id !== currentUser?.id &&
                    teamwork?.participants?.filter(
                      (participant: any) => participant.id === currentUser?.id
                    ).length === 0 ? (
                    <button
                      type='button'
                      className='btn btn-primary'
                      data-bs-dismiss='modal'
                      onClick={() => registerTeamwork(teamwork?.id)}
                      disabled={new Date(teamwork?.start_date).getDate() < new Date().getDate()}
                    >
                      Register
                    </button>
                  ) : (
                    ''
                  ))}

                {
                  //if teamwork.participants includes current user show leave button
                  teamwork?.participants?.filter(
                    (participant: any) => participant.id === currentUser?.id
                  ).length > 0 ? (
                    <button
                      type='button'
                      className='btn btn-danger'
                      data-bs-dismiss='modal'
                      onClick={() => leaveTeamwork(teamwork?.id)}
                    >
                      Leave
                    </button>
                  ) : (
                    ''
                  )
                }
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

const TrainingModal = ({ event, isLoading }) => {
  const [training, setTraining] = useState<any>(null)
  //{{api_url}}/trainings/3/show
  console.log(event?.extendedProps)
  const getTraining = async (trainingId: number) => {
    try {
      const response = await axios.get(`${API_URL}/trainings/${trainingId}/show`)
      console.log(response.data)
      setTraining(response.data?.data)
    } catch (error) {
      console.log(error)
    }
  }

  // {{api_url}}/trainings/20/join

  const joinTraining = async (trainingId: number) => {
    try {
      const response = await axios.post(`${API_URL}/trainings/${trainingId}/join`)
      console.log(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  // {{api_url}}/trainings/3/leave

  const leaveTraining = async (trainingId: number) => {
    try {
      const response = await axios.post(`${API_URL}/trainings/${trainingId}/leave`)
      console.log(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    console.log(new Date("2024-06-24T18:00:00.000000Z"))
    console.log(new Date("2024-06-24T18:00:00.000000Z") < new Date())
    if (event) {
      getTraining(event?.extendedProps?.trainingId)
    }
  }, [event])

  return (
    <div>
      <button
        type='button'
        className='btn btn-primary'
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_training'
        id='kt_modal_training_button'
        style={{ display: 'none' }}
      ></button>
      <div className='modal fade' tabIndex={-1} id='kt_modal_training'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>{event?.title} (Training)</h5>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div>
                <h3>Description</h3>
                <p className='text-muted'>{training?.description}</p>
              </div>

              <div className='d-flex flex-row gap-3'>
                <span
                  className='badge p-3 fs-7 fw-semibold'
                  style={{ backgroundColor: '#50CD89', color: '#F5F5F5' }}
                >
                  Scheduled
                </span>
                <span className='badge p-3 fs-7 fw-semibold' style={{ backgroundColor: '#EEF1F5' }}>
                  {event?.start?.toISOString().split('T')[0]}
                </span>
                <span className='badge p-3 fs-7 fw-semibold' style={{ backgroundColor: '#EEF1F5' }}>
                  {
                    event?.start?.toISOString().split('T')[1].split(':')[0] + ':' + event?.start?.toISOString().split('T')[1].split(':')[1]
                  }
                  {' - '}
                  {
                    moment(

                      event?.start?.toISOString().split('T')[0] + ' ' + event?.start?.toISOString().split('T')[1].split(':')[0] +
                      ':' +
                      event?.start?.toISOString().split('T')[1].split(':')[1]
                    ).add(40, 'minutes').format('HH:mm')

                  }
                </span>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              {training?.user_is_participant === false ? ( // if training is not approved show register button
                <button
                  type='button'
                  className='btn btn-primary'
                  data-bs-dismiss='modal'
                  onClick={() => joinTraining(event?.extendedProps?.trainingId)}
                  disabled={new Date(training?.start_date) < new Date()}

                >
                  Join
                </button>
              ) : (
                ''
              )}
              {training?.user_is_participant === true ? ( // if training is approved show join button
                <button
                  type='button'
                  className='btn btn-danger'
                  data-bs-dismiss='modal'
                  onClick={() => leaveTraining(event?.extendedProps?.trainingId)}
                  disabled={isLoading}
                >
                  Leave
                </button>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CalendarMentee
