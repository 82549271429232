import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const Error409: FC = () => {
    return (
        <>
            {/* begin::Title */}
            <h1 className='fw-bolder fs-2qx text-gray-900 mb-4'>Role Error</h1>
            {/* end::Title */}

            {/* begin::Text */}
            <div className='fw-semibold fs-6 text-gray-500 mb-7'>
                You seem to be already registered in our system under a different role.
            </div>
            {/* end::Text */}

            {/* begin::Illustration */}
            <div className='mb-11'>
            </div>
            {/* end::Illustration */}

            {/* begin::Link */}
            <div className='mb-0'>
                <Link to='/dashboard' className='btn btn-sm btn-primary'>
                    Return Home
                </Link>
            </div>
            {/* end::Link */}
        </>
    )
}

export { Error409 }
