import { KTIcon, KTSVG, toAbsoluteUrl } from '_metronic/helpers'
import clsx from 'clsx'
import React, { useState } from 'react'
import { convertTimestamp } from 'utils/stringDateFunctions'

const ViewTeamwork = ({ step }) => {
  const dateTime = convertTimestamp(step?.teamwork?.start_date)
  const teamwork = step?.teamworks[step?.teamworks?.length - 1]
  return (
    <div>
      <div className='modal fade' tabIndex={-1} id='view-teamwork'>
        <div className='modal-dialog mw-900px d-flex align-items-center'>
          <div className='modal-content d-flex align-items-center'>
            <div className='modal-header w-100 d-flex justify-content-between'>
              <h3 className='modal-title'>{step?.name}</h3>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row g-9'>
                <div className='col-md-5 col-lg-12 col-xl-5'>
                  <div className='d-flex flex-column justify-content-center h-100'>
                    <div>
                      <p className='fw-bold'>Moderator: {teamwork?.owner?.name}</p>
                      <h3>Training Description</h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: teamwork?.description ? teamwork?.description : '-',
                        }}
                      ></div>
                    </div>
                    <div className='d-flex flex-row gap-3'>
                      {new Date().getTime() <= new Date(step?.started_at || '').getTime() ? (
                        <span
                          className='badge p-3 fs-7 fw-semibold'
                          style={{ backgroundColor: '#50CD89', color: '#F5F5F5' }}
                        >
                          Scheduled
                        </span>
                      ) : (
                        <span
                          className='badge p-3 fs-7 fw-semibold'
                          style={{ backgroundColor: '#F23F44', color: '#fff' }}
                        >
                          Expired
                        </span>
                      )}
                      <span
                        className='badge p-3 fs-7 fw-semibold'
                        style={{ backgroundColor: '#EEF1F5' }}
                      >
                        {convertTimestamp(teamwork?.start_date || '').date}
                      </span>
                      <span
                        className='badge p-3 fs-7 fw-semibold'
                        style={{ backgroundColor: '#EEF1F5' }}
                      >
                        {convertTimestamp(teamwork?.start_date || '').time}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col-md-7 col-lg-12 col-xl-7 d-flex align-items-center'>
                  <div className='d-flex flex-row gap-3'>
                    <a href={step?.teamwork?.join_url} className={clsx(`btn btn-lg btn-primary py-5 ${new Date().getTime() <= new Date(step?.started_at || '').getTime() ? '' : 'disabled'}`)} >
                      <div className='d-flex flex-column' style={{ height: '90%' }}>
                        <div className='card-body d-flex flex-column justify-content-center align-items-center'>
                          <img
                            className='d-flex align-self-center'
                            alt='Pic'
                            width="50"
                            height="50"
                            src={toAbsoluteUrl('/media/logos/zoomIcon.png')}
                          />
                          <span className='w-100' style={{ whiteSpace: 'nowrap' }}>
                            Join Training
                          </span>
                        </div>
                      </div>
                      <KTIcon iconName='arrow-right' className='text-white fs-1' />
                    </a>
                    <div className='card shadow-sm'>
                      <div className='card-body'>
                        <p>
                          Join Zoom Meeting <br />
                          {teamwork?.join_url}
                        </p>
                        <p>Meeting ID: {teamwork?.zoom_meeting_id}</p>
                        <p>Passcode: {teamwork?.meeting_password}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <div className='h-10px'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewTeamwork
