import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';

import { ISkill, ISkillData, RegisterProps } from '../types/registerTypes';
import { loadFilledSkills, loadSkillsList } from '../api/requests';
import { KTIcon } from '_metronic/helpers/components/KTIcon';
import { thirdRegisterStep } from '../utils/registerHelpers';
import { useAuth } from 'app/modules/auth';
const levelHandler = (level: string) => {
    switch (level) {
        case 'poor':
            return 'Poor'
        case 'fair':
            return 'Fair'
        case 'good':
            return 'Good'
        case 'very_good':
            return 'Very Good'
        case 'excellent':
            return 'Excellent'
    }
}


const SkillStep = ({ errors, touched, role }: RegisterProps) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState<boolean>(false);
    const { currentUser } = useAuth();

    const [selectedAnswers, setSelectedAnswers] = useState<ISkill[]>([]);
    const [skills, setSkills] = useState<ISkillData[] | null>(null)
    const { setFieldValue, values } = useFormikContext()

    const [length, setLength] = useState<number>(0)
    const calculateProgress = () => {
        const skillsLen = skills?.length;
        let totalQuestions;
        if (skillsLen) {
            totalQuestions = skills?.length
        } else {
            totalQuestions = 120
        }

        const answeredQuestions = Object.keys(selectedAnswers).length;
        const progress = (answeredQuestions / totalQuestions) * 100;
        return progress.toFixed(1);
    };
    const handleRadioChange = (value, id) => {
        setSelectedAnswers((prevAnswers) => {
            const updatedAnswers = [...prevAnswers];
            const existingIndex = updatedAnswers.findIndex(answer => answer.skill_id === id);
            if (existingIndex !== -1) {
                updatedAnswers[existingIndex].answer = value;
            } else {
                updatedAnswers.push({ skill_id: id, answer: value });
            }
            return updatedAnswers;
        });
    };

    useEffect(() => {
        console.log(values)
        setFieldValue('skills', selectedAnswers)
    }, [selectedAnswers, setFieldValue])

    const loadSkills = async () => {
        try {
            setLoading(true)
            const response = await loadSkillsList(role)
            if (currentUser?.id) {
                const filled = await loadFilledSkills(currentUser?.id)
                // setSelectedAnswers([...filled.data])
                // // console.log(filled)
                // // filled.map((item)=> {

                // // })
                const selectedArray: any = [];
                filled.data.forEach((item) => {
                    selectedArray.push({ skill_id: item.id, answer: item.selected_level })
                    setFieldValue(`${item.id}`, item.selected_level)
                })
                setSelectedAnswers(selectedArray)
                setFieldValue('skills', selectedArray)
            }
            if (response.data) {
                setSkills(response.data)
                localStorage.setItem('skillsLen', response.data.length.toString())
                setLength(response.data.length)
            }

            setLoading(false)
        } catch (err) {
            console.log(err)
        }
    }
    const saveAsDraft = async (e) => {
        e.preventDefault()
        setLoading(true)
        thirdRegisterStep(selectedAnswers)
            .finally(() => {
                setLoading(false)
            });
    }
    useEffect(() => {
        loadSkills()
        console.log(values)

        // ЛИБО СРАЗУ ЧЕРЕЗ СЕТ
        // setSelectedAnswers((prevAnswers) => {
        //     const updatedAnswers = [...prevAnswers];
        //     const existingIndex = updatedAnswers.findIndex(answer => answer.skill_id === id);
        //     if (existingIndex !== -1) {
        //         updatedAnswers[existingIndex].answer = value;
        //     } else {
        //         updatedAnswers.push({ skill_id: id, answer: value });
        //     }

        //     return updatedAnswers;
        // });
        // ЛИБО ПОПРОБОВАТЬ ВОТ ТАК
    }, [])


    const skillsPerPage = 5;
    const indexOfLastSkill = currentPage * skillsPerPage;
    const indexOfFirstSkill = indexOfLastSkill - skillsPerPage;
    const currentSkills = skills?.slice(indexOfFirstSkill, indexOfLastSkill);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className='m-auto h-100 d-flex flex-column justify-content-between' style={{ width: '90%' }}>
            <div className='h-100'>
                <div className='card-body d-flex align-items-end pt-0'>
                    <div className='d-flex align-items-center flex-column mt-3 w-100'>
                        <div className='d-flex justify-content-between fs-7 opacity-75 w-100 mt-auto mb-2'>
                            <span>{calculateProgress()}%</span>
                            <span>Progress</span>
                        </div>

                        <div className='h-5px mx-3 w-100 bg-primary-light mb-2 bg-opacity-50 rounded'>
                            <div
                                className='bg-success-active rounded h-5px'
                                role='progressbar'
                                style={{ width: `${calculateProgress()}%` }}
                                aria-valuenow={50}
                                aria-valuemin={0}
                                aria-valuemax={100}
                            ></div>
                        </div>
                    </div>
                </div>
                <h1 className='form-label fs-2'>Skill Assessment</h1>
                {loading ? (
                    <span className='indicator-progress position-absolute top-50' style={{ display: 'block', left: '50%' }}>
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                ) : (
                    <>
                        <form className='mt-3' style={{ height: "90%" }}>
                            <div  className='row mb-2 d-flex flex-column justify-content-between h-100' data-kt-buttons='true'>
                                <div className='col p-0'>
                                    <div className='scroll-y mh-500px w-100 p-0'>
                                        {currentSkills?.map((skill,index) => {
                                            return (
                                                <div key={index} className='mt-5'>
                                                    <label className='form-label'>{skill.title}</label>
                                                    <div className='d-flex flex-row w-100 justify-content-between'>
                                                        {skill.allowed_levels?.map((level,index) => {
                                                            return (
                                                                <div key={index} className='form-check form-check-custom form-check-solid d-flex flex-row align-items-center' onChange={() => handleRadioChange(level, skill.id)}>
                                                                    <Field
                                                                        className='form-check-input'
                                                                        type="radio"
                                                                        name={skill.id}
                                                                        value={level}
                                                                        id='kt_question1_absolutely_no' />
                                                                    <label className='form-check-label'>{levelHandler(level)}</label >
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </form>
                        {errors.skills && touched.skills ? (
                            <>
                                <div className='text-danger mt-2 text-left'>
                                    <ErrorMessage name='skills' />
                                </div>
                            </>
                        ) : null}
                        <div className='pagination mt-10 pt-10'>
                            <li className='page-item previous'>
                                <div className='page-link cursor-pointer' onClick={(e) => {
                                    if (currentPage > 1) {
                                        paginate(currentPage - 1)
                                    }
                                }}>
                                    <i className='previous'></i>
                                </div>
                            </li>
                            {Array.from({ length: Math.ceil(length / skillsPerPage) }, (_, i) => (
                                <li key={i} onClick={() => paginate(i + 1)} className={`page-item btn-sm ${currentPage === i + 1 ? 'active' : ''}`}>
                                    <div className='page-link cursor-pointer'>
                                        {i + 1}
                                    </div>
                                </li>
                            ))}
                            <li className='page-item next'>
                                <div className='page-link cursor-pointer' onClick={(e) => {
                                    if (currentPage < Math.ceil(length / skillsPerPage)) {
                                        paginate(currentPage + 1)
                                    }
                                }}>
                                    <i className='next'></i>
                                </div>
                            </li>

                        </div>
                    </>)}
                {/* <div className='d-flex justify-content-end' >
                    <button className='btn btn-lg btn-primary mt-5' onClick={(e)=>{saveAsDraft(e)}} disabled={loading}>
                        <span className='indicator-label'>
                            {loading ? 'Please wait...' : 'Save as draft'}
                        </span>
                    </button>
                </div> */}
            </div>
        </div>
    );
};

export default SkillStep;