export const countDays=(startDateStr: string | undefined, endDateStr: string | undefined) => {
    if(!(startDateStr && endDateStr)) {
        return null
    }

    const startDate = new Date(startDateStr).getTime();
    const endDate = new Date(endDateStr).getTime();
    // Calculate the difference in milliseconds
    const differenceMs = endDate - startDate;
        
    // Convert milliseconds to days
    const millisecondsInDay = 1000 * 60 * 60 * 24;
    const daysLeft = Math.ceil(differenceMs / millisecondsInDay);
    return daysLeft === 1 ? `${daysLeft} day` : `${daysLeft} days`;
}

export function convertTimestamp(timestamp: string): { date: string, time: string } {
    if(!timestamp) {
        return { date: '', time:''};
    }
    const dateObject = new Date(timestamp);

    // Extracting date components
    const day = String(dateObject.getUTCDate()).padStart(2, '0');
    const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
    const year = dateObject.getUTCFullYear();

    // Extracting time components
    const hours = String(dateObject.getUTCHours()).padStart(2, '0');
    const minutes = String(dateObject.getUTCMinutes()).padStart(2, '0');

    const formattedDate = `${day}.${month}.${year}`;
    const formattedTime = `${hours}:${minutes}`;

    return { date: formattedDate, time: formattedTime };
}

export function convertToTimestamp(date: string): string {
    console.log('1', date)
    const inputDate = new Date(date);

    // Function to add leading zeros to single-digit numbers
    const addLeadingZero = (num: number): string => {
      return num < 10 ? `0${num}` : `${num}`;
    };
    
    // Get the month in abbreviated format
    const monthAbbrev = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(inputDate);
    
    // Get the formatted date and time
    const formattedDate = `${monthAbbrev} ${inputDate.getDate()}, ${inputDate.getFullYear()} - ${addLeadingZero(inputDate.getHours())}:${addLeadingZero(inputDate.getMinutes())}`;

    return formattedDate;
}