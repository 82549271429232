import {KTIcon, KTSVG, toAbsoluteUrl} from '_metronic/helpers'
import {API_URL} from 'app/consts/consts'
import {getFileDownloadLink} from 'app/modules/register/api/requests'
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {ICurrentStep} from '../DashboardWrapper'
interface Props {
  currentStep: ICurrentStep | undefined
}
interface IProfile {
  username: string
  company: string
  email: string
}
export interface IDocs {
  file_id: number
  id: number
  name: string
  type: string
}

export function getStatusText(status: string) {
  switch (status) {
      case "todo":
          return { color: '#FFF5E8', text: 'Yet To Start', textColor: '#FFA800' };
      case "in_progress":
          return { color: '#EEF6FF', text: 'In Progress ', textColor: '#3699FF' };
      case "canceled":
          return { color: '#FFE2E5', text: 'Canceled', textColor: 'red' };
      case "done":
          return { color: '#E8FFF3', text: 'Completed', textColor: '#50CD89' };
      default:
          return { color: '#EEF6FF', text: 'In Progress', textColor: '#3699FF' };
  }
}

export const CurrentStepModal = ({currentStep}: Props) => {
  const [loadingBtns, setLoadingBtns] = useState<{[key: string]: boolean}>({})
  const [isLoading, setIsLoading] = useState<boolean>(false)

  return (
    <div className='modal fade' tabIndex={-1} id='current_step'>
      <div className='modal-dialog' style={{minWidth: '65%'}}>
        <div className='modal-content'>
          {isLoading ? (
            <div className='v-100 position-relative' style={{minHeight: '30vh'}}>
              <div
                className='page-loading position-absolute'
                style={{
                  top: '50%',
                  left: '50%',
                }}
              >
                <span
                  className='spinner-border text-primary'
                  data-kt-app-page-loading='on'
                  role='status'
                >
                  <span className='visually-hidden'>Loading...</span>
                </span>
              </div>
            </div>
          ) : (
            <div>
              <div className='modal-header'>
                <h5 className='modal-title'>Your Current Step</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  <KTIcon iconName='cross' className='fs-1' />
                </div>
              </div>
              <div className='modal-body'>
                <div className='card mb-5 mb-xl-10'>
                  <div className='card-body pt-9 pb-0'>
                    <h3>{currentStep?.name}</h3>
                    <p>{currentStep?.description}</p>
                    <div className='d-flex justify-content-start'>
                      <span
                        className='badge badge-light-success fs-8 fw-bold text-center py-4 px-4'
                        style={{
                          backgroundColor: `${getStatusText(currentStep?.status || '').color}`,
                          color: `${getStatusText(currentStep?.status || '').textColor}`,
                        }}
                      >
                        {getStatusText(currentStep?.status || '').text}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
