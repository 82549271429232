import { Suspense, createContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from './_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from './_metronic/layout/core'
import { MasterInit } from './_metronic/layout/MasterInit'
import { AuthInit } from './app/modules/auth'
import { ThemeModeProvider } from './_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { ErrorBoundary } from 'react-error-boundary'
import * as Sentry from "@sentry/react";
import { GlobalProvider } from './GlobalState';



const App = () => {

  const reloadPage = () => {
    window.location.reload();
  }

  const authItem = localStorage.getItem('kt-auth-react-v');
  if (authItem) {
    const authObject = JSON.parse(authItem);
    if (authObject && authObject.access_token) {
      // interval 5 minutes
      setInterval(reloadPage, 300000);
    }
  }




  return (
    <GlobalProvider>
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <ThemeModeProvider>
              <AuthInit>
                <Outlet />
                <MasterInit />
              </AuthInit>
            </ThemeModeProvider>
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
    </GlobalProvider>
  )
}

export { App }
