import {KTIcon, KTSVG, toAbsoluteUrl} from '_metronic/helpers'
import {API_URL} from 'app/consts/consts'
import {getFileDownloadLink} from 'app/modules/register/api/requests'
import axios from 'axios'
import React, {useEffect, useState} from 'react'
interface Props {
  id: number
  role: string
}
interface IProfile {
  username: string
  company: string
  email: string
}
export interface IDocs {
  file_id: number
  id: number
  name: string
  type: string
}
export const ProfileModal = ({id, role}: Props) => {
  const [profile, setProfile] = useState<IProfile>({username: '', company: '', email: ''})
  const [docs, setDocs] = useState<IDocs[]>([])
  const [loadingBtns, setLoadingBtns] = useState<{[key: string]: boolean}>({})
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const getProfileInfo = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(`${API_URL}/users/${id}`)
      const fetchedFilesList = await axios.get(`${API_URL}/users/${id}/documents`)
      if (response?.data) {
        setProfile(response.data.data)
      }
      if (fetchedFilesList?.data) {
        console.log(fetchedFilesList.data)
        setDocs(fetchedFilesList.data.data)
      }
      console.log(response.data)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (id && id > 0) {
      getProfileInfo()
    }
  }, [id])
  return (
    <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
      <div className='modal-dialog' style={{minWidth: '65%'}}>
        <div className='modal-content'>
          {isLoading ? (
            <div className='v-100 position-relative' style={{minHeight: '30vh'}}>
              <div
                className='page-loading position-absolute'
                style={{
                  top: '50%',
                  left: '50%',
                }}
              >
                <span
                  className='spinner-border text-primary'
                  data-kt-app-page-loading='on'
                  role='status'
                >
                  <span className='visually-hidden'>Loading...</span>
                </span>
              </div>
            </div>
          ) : (
            <div>
              <div className='modal-header'>
                <h5 className='modal-title'>
                  {role == 'trainer' && 'Trainer Profile'}
                  {role == 'mentor' && 'Mentor Profile'}
                  {role == 'mentee' && 'Mentee Profile'}
                </h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  <KTIcon iconName='cross' className='fs-1' />
                </div>
              </div>
              <div className='modal-body'>
                <div className='card mb-5 mb-xl-10'>
                  <div className='card-body pt-9 pb-0'>
                    <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                      <div className='me-7 mb-4'>
                        <div className='w-100px h-100px symbol-fixed position-relative'>
                          <div
                            style={{borderRadius: 15}}
                            className={`w-100 h-100 d-flex flex-center rounded bg-light-secondary text-white fs-2 fw-bolder`}
                          >
                            <h2>{profile?.username.charAt(0).toUpperCase()}</h2>{' '}
                          </div>
                        </div>
                      </div>

                      <div className='flex-grow-1'>
                        <div className='d-flex row'>
                          <div className='d-flex flex-column col-xl-6'>
                            <div className='d-flex align-items-center mb-2'>
                              <p className='m-0 p-0 text-gray-800 fs-2 fw-bolder me-1'>
                                {profile.username}
                              </p>
                            </div>

                            <div className='d-flex flex-column fw-bold fs-6 pe-2'>
                              <p className='m-0 p-0 d-flex align-items-center text-gray-400 me-5 mb-2'>
                                {profile.company}
                              </p>
                            </div>
                            <div className='d-flex flex-wrap flex-stack'>
                              <div className='d-flex flex-column flex-grow-1 pe-8'>
                                <div className='d-flex flex-wrap gap-10'>
                                  <div className='d-flex flex-row gap-3 align-items-center'>
                                    <img
                                      className='d-flex align-self-center'
                                      alt='Pic'
                                      src={toAbsoluteUrl('/media/svg/MailOpened.svg')}
                                    />
                                    <span className='text-gray-400'>{profile.email}</span>
                                  </div>
                                  {/* <div className='d-flex flex-row gap-3 align-items-center'>
                                <img
                                  className='d-flex align-self-center'
                                  alt='Pic'
                                  src={toAbsoluteUrl('/media/svg/Duotone.svg')}
                                />
                                <span className='text-gray-400'></span>
                              </div> */}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='d-flex flex-wrap flex-stack col-xl-6'>
                            <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3 me-20 pr-10s'>
                              <div className='d-flex justify-content-end w-100 mt-auto mb-2'>
                                <div className='d-flex flex-row gap-5 h-100'>
                                  {docs.length > 0 &&
                                    docs?.map((doc, index) => {
                                      return (
                                        <div className='d-flex flex-row gap-3'>
                                          <img
                                            className='mt-1 align-self-start'
                                            alt='Pic'
                                            width='40'
                                            src={toAbsoluteUrl(
                                              '/media/icons/duotune/files/pdf.png'
                                            )}
                                          />
                                          <div>
                                            <p className='m-0 fw-bold'>{doc.type}</p>
                                            <span
                                              className='btn p-0 m-0 text-hover-primary fw-thin'
                                              onClick={() => {
                                                setLoadingBtns((prevLoadingBtns) => ({
                                                  ...prevLoadingBtns,
                                                  [doc.id]: true,
                                                }))
                                                getFileDownloadLink(doc?.id).finally(() => {
                                                  setLoadingBtns((prevLoadingBtns) => ({
                                                    ...prevLoadingBtns,
                                                    [doc.id]: false,
                                                  }))
                                                })
                                              }}
                                            >
                                              {' '}
                                              {loadingBtns[doc.id] ? 'Loading...' : 'View'}
                                            </span>
                                          </div>
                                        </div>
                                      )
                                    })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
