import axios from "axios";
import { API_URL } from 'app/consts/consts'
import { KTIcon, toAbsoluteUrl } from "../../../../_metronic/helpers/index";
import { useAuth } from "app/modules/auth";
import { useState } from "react";

const MenteeProfileMentorRecommendations = () => {
    const { currentUser } = useAuth();
    const [recommendations, setRecommendations] = useState<any>([])
    const getRecommendations = () => {
        axios.get(`${API_URL}/users/${currentUser?.id}/mentorships-reviews`).then((res) => {
            const modifiedResponse = res?.data?.data?.map((recommendation: any) => {
                return {
                    mentorName: recommendation?.user?.name,
                    mentorRole: '',
                    recommendationText: recommendation?.content
                }

            }
            )
            console.log(modifiedResponse)
            setRecommendations(modifiedResponse)
        }
        ).catch((error) => {
            console.log(error)
        })
    }

    getRecommendations()

    // const recommendations = [
    //     {
    //         mentorName: 'John Doe',
    //         mentorRole: 'Software Engineer',
    //         recommendationText: 'John is a great mentor. He is very helpful and has a lot of experience in the field. I would recommend him to anyone who is looking for a mentor.'
    //     },
    //     {
    //         mentorName: 'Jane Doe',
    //         mentorRole: 'Data Scientist',
    //         recommendationText: 'Jane is an amazing mentor. She is very knowledgeable and has helped me a lot in my career. I would highly recommend her to anyone who is looking for a mentor.'
    //     }
    // ]


    return (
        <>
            <div className="card card-custom card-flush my-5">
                <div className="card-header">
                    <h3 className="card-title">
                        Mentor Recommendations
                    </h3>
                </div>
                <div className="card-body py-5">
                    <div
                        className="px-5"
                    >
                        {recommendations.map((recommendation, index) => {
                            return (
                                <div key={index}>
                                    <RecommendationItem
                                        mentorName={recommendation.mentorName}
                                        mentorRole={recommendation.mentorRole}
                                        recommendationText={recommendation.recommendationText}
                                    />
                                    <div className="separator my-7"></div>
                                </div>
                            )
                        })}
                    </div>


                </div>
            </div>
        </>
    )
}

const RecommendationItem = (
    {
        mentorName,
        mentorRole,
        recommendationText
    }
) => {
    return (
        <>
            <div>
                <div
                    className="mb-5"
                >
                    <div className="d-flex align-items-center">
                        <div className="symbol symbol-45px me-5">
                            <img
                                src={toAbsoluteUrl('/media/avatars/blank.png')}
                                alt="" />
                        </div>
                        <div className="d-flex justify-content-start flex-column">
                            <a href="#" className="text-gray-900 fw-bold text-hover-primary fs-6">
                                {mentorName}
                            </a>
                            <span className="text-muted fw-semibold text-muted d-block fs-7">
                                {mentorRole}
                            </span>
                        </div>
                    </div>
                </div>
                <span
                    className="text-gray-700 fs-6 mt-2"
                >
                    {recommendationText}
                </span>

            </div>
        </>
    )
}

export default MenteeProfileMentorRecommendations;