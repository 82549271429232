import React, { useEffect, useState } from 'react';
import { IFile, IFileData, RegisterProps } from '../types/registerTypes';
import { ErrorMessage, useFormikContext } from 'formik';
import { getFileDownloadLink, loadFilesList, loadFilledDocs } from '../api/requests';
import { toAbsoluteUrl } from '_metronic/helpers/AssetHelpers';
import { useAuth } from 'app/modules/auth';
import { Link } from 'react-router-dom';
import { KTIcon } from '_metronic/helpers/components/KTIcon';




const DocumentsStep = ({ errors, touched, role }: RegisterProps) => {
    const [loadingBtns, setLoadingBtns] = useState<{ [key: string]: boolean }>({});

    const { currentUser } = useAuth();
    const { setFieldValue, values } = useFormikContext()
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
    const [docs, setDocs] = useState<IFileData[]>([]);

    const [selectedFiles, setSelectedFiles] = useState<IFile[]>([]);

    const handleDivClick = (fileType) => {
        document?.getElementById(`${fileType}`)?.click();
    };

    useEffect(() => {
        const selectedFilesArray = Object.values(selectedFiles);
        setFieldValue('documents', [...selectedFilesArray]);
        console.log(values)
    }, [selectedFiles])

    const handleFileChange = async (e, fileType) => {
        try {
            e.preventDefault();
            const allowedFileTypes = ['application/pdf', 'application/msword', 'text/plain'];
            if (e.target.files[0]) {
                const selectedFile = e.target.files[0];
                console.log(e.target.id);
                if (allowedFileTypes.includes(selectedFile.type)) {
                    setSelectedFiles((prevSelectedFiles) => ({
                        ...prevSelectedFiles,
                        [fileType]: { file_id: fileType, file: selectedFile, name: undefined },
                    }));
                } else {
                    console.log('Invalid file type. Please choose a PDF, DOC, or TXT file.');
                }
            }
        } catch (e) {
            console.log('errr', e);
        }
    };

    const loadDocs = async () => {
        try {
            setLoading(true)
            const response = await loadFilesList(role)
            const docs = response?.data.data
            console.log(docs)
            if (currentUser?.id) {
                const filled = await loadFilledDocs(currentUser?.id)
                const filledDocs = filled.data
                filledDocs.forEach((doc) => {
                    let id = doc.id
                    doc.name = 'uploaded.pdf'
                    doc.id = doc.file_id
                    doc.file_id = id;
                })
                console.log(filledDocs)


                const updatedDocs = docs.map(doc => {
                    const filledDoc = filledDocs.find(fd => fd.id === doc.id);
                    return filledDoc ? { ...filledDoc, title: doc.title } : doc;
                });

                console.log(updatedDocs);
                setDocs(updatedDocs)
                setLoading(false)
                return;
            }

            if (response) {
                setDocs(response.data.data)
            }
            setLoading(false)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        loadDocs()
    }, [])

    return (
        <div className='m-auto h-100 d-flex flex-column justify-content-between' style={{ width: '90%' }}>
            <div>
                <h1 className='form-label fs-2'>Upload Document</h1>
                {loading ? (
                    <span className='indicator-progress position-absolute top-50' style={{ display: 'block', left: '50%' }}>
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                ) : (
                    <>
                        <form className='mt-10 ms-5'>
                            <div className='row mb-2 d-flex flex-column h-50' data-kt-buttons='true'>
                                <div className='col'>
                                    {docs.map((doc) => (
                                        <div className='mt-3'>
                                            <label className='mb-3 required form-label'>{doc.title}</label>
                                            <label
                                                className='bg-light btn btn-outline btn-outline-dashed btn-outline-default w-100 p-2 d-flex flex-row justify-content-between'
                                                htmlFor='kt_account_team_size_select_1'
                                                onClick={() => handleDivClick(`${doc.id}`)}
                                            >
                                                <div className='p-2 d-flex flex-row'>
                                                    <img
                                                        src={toAbsoluteUrl('/media/icons/duotune/files/fil009.svg')}
                                                        className='w-50px h-40px align-self-center'
                                                        alt=''
                                                    />
                                                    <input
                                                        type="file"
                                                        accept=".pdf, .doc, .docx, .txt"
                                                        multiple={false}
                                                        id={`${doc.id}`}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => handleFileChange(e, `${doc.id}`)}
                                                    />
                                                    <div className='text-start align-self-end pt-3'>
                                                        <label className='form-label'>Quick file uploader</label>
                                                        <p className='text-secondary fs-7'>
                                                            {selectedFiles[doc.id] ? `File: ${selectedFiles[doc.id].file.name}` : (doc.name ? doc.name : 'Drag & Drop or choose files from computer')}
                                                        </p>
                                                    </div>
                                                </div>
                                                {doc.name ? (
                                                    <div className='h-100 align-self-center'>
                                                        <button
                                                            className='btn btn-primary'
                                                            disabled={loadingBtns[doc.id]}
                                                            style={{ zIndex: 1 }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                setLoadingBtns((prevLoadingBtns) => ({
                                                                    ...prevLoadingBtns,
                                                                    [doc.id]: true,
                                                                }));
                                                                getFileDownloadLink(doc?.file_id).finally(() => {
                                                                    setLoadingBtns((prevLoadingBtns) => ({
                                                                        ...prevLoadingBtns,
                                                                        [doc.id]: false,
                                                                    }));
                                                                });
                                                            }}
                                                        >
                                                            <KTIcon iconName='arrow-down' className='fs-3' />
                                                            {loadingBtns[doc.id] ? 'Loading...' : 'Download File'}
                                                        </button>
                                                    </div>
                                                ) : ''}
                                            </label>


                                        </div>
                                    ))}
                                </div>
                                {errors.documents ? (
                                    <>
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='documents' />
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </form>
                    </>
                )}
            </div>
        </div>
    )
};

export default DocumentsStep;