/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Outlet, Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const RegisterLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = `none`
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10' style={{ backgroundColor: "rgba(243, 245, 249, 1)" }}>
        {/* begin::Form */}
        <div className='d-flex flex-column flex-lg-row-fluid w-66 px-md-15 py-20'>
          {/* begin::Wrapper */}
          <div className='w-100'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-30'
        style={{
          backgroundColor: "#F3F5F9", boxShadow: "0px 0px 40px 0px rgba(177, 187, 208, 0.15)"
        }}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center justify-content-between py-20 px-5 px-md-15 w-100'>
          <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
            {/* begin::Logo */}
            <Link to='/' className='mb-12'>
              <img alt='Logo' src={toAbsoluteUrl('/media/svg/logo/logo2.svg')} className='w-70px' />
            </Link>
            {/* end::Logo */}

            {/* begin::Title */}
            <h1 className='fw-semibold text-center fs-5 w-379px' style={{ color: 'rgba(126, 130, 153, 1)' }}>
              Mentorship Module
            </h1>
            {/* end::Title */}
          </div>
          {/* begin::Image */}

          <img
            className='mx-auto d-none d-lg-block'
            src={toAbsoluteUrl('/media/misc/S_Basket.png')}
            alt=''
            style={{ width: '30%', position: 'absolute', bottom: 0 }}  // Positioned the image at the bottom
          />
          {/* end::Image */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export { RegisterLayout }
