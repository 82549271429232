import { ICreateAccount } from "app/modules/wizards/components/CreateAccountWizardHelper";
import { initUser, updateProfile, setInterests, addDocument, setSkills } from 'app/modules/register/api/requests'
import { ISkill } from "../types/registerTypes";

export const firstRegisterStep = async (values: ICreateAccount, role: string, newInterests: any) => {
    try {
        if (!localStorage.getItem('is_init')) {
            await initUser(values, role);
        }
        const update = await updateProfile(values);
        const interests = await setInterests(newInterests);

        if (!update || !interests) {
            return null;
        }

        return true;

    } catch (err) {
        return null;
    }
}

export const secondRegisterStep = async (values: ICreateAccount) => {
    try {
        const { documents } = values;
        const addDocs = async () => {
            if (documents) {
                const documentPromises = documents.map(async (doc) => {
                    await addDocument(doc.file, doc.file_id);
                });

                await Promise.all(documentPromises);
            } else {
                return null;
            }

            return true;
        };

        await addDocs();
    } catch (err) {
        return null;
    }
}

export const thirdRegisterStep = async (values: ISkill[]) => {
    try {
        if (values) {
            await setSkills(values);
        } else {
            return null;
        }

        return true;
    } catch (err) {
        return null;
    }
}