import { useAuth } from 'app/modules/auth'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { API_URL } from 'app/consts/consts'
import { getFileDownloadLink } from 'app/modules/register/api/requests'
import { useParams } from 'react-router-dom'
import { toAbsoluteUrl } from '_metronic/helpers'
interface IProfile {
  username: string
  company: string
  email: string
}
export interface IDocs {
  file_id: number
  id: number
  name: string
  type: string
}
const MenteeProfileCard = () => {
  const { menteeId } = useParams()
  const [profile, setProfile] = useState<IProfile>({ username: '', company: '', email: '' })
  const [docs, setDocs] = useState<IDocs[]>([])
  const [isUserLoading, setIsUserLoading] = useState<boolean>(false)
  const { currentUser } = useAuth()
  const [loadingBtns, setLoadingBtns] = useState<{ [key: string]: boolean }>({})

  const getProfileInfo = async () => {
    try {
      setIsUserLoading(true)
      const response = await axios.get(`${API_URL}/users/${menteeId}`)
      const fetchedFilesList = await axios.get(`${API_URL}/users/${menteeId}/documents`)
      if (response?.data) {
        setProfile(response.data.data)
      }
      if (fetchedFilesList?.data) {
        console.log(fetchedFilesList.data)
        setDocs(fetchedFilesList.data.data)
      }
      console.log(response.data)
    } catch (err) {
      console.log(err)
    } finally {
      setIsUserLoading(false)
    }
  }
  useEffect(() => {
    getProfileInfo()
  }, [])
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        {isUserLoading ? (
          <div className='v-100 position-relative' style={{ minHeight: '30vh' }}>
            <div
              className='page-loading position-absolute'
              style={{
                top: '50%',
                left: '50%',
              }}
            >
              <span
                className='spinner-border text-primary'
                data-kt-app-page-loading='on'
                role='status'
              >
                <span className='visually-hidden'>Loading...</span>
              </span>
            </div>
          </div>
        ) : (
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='Metornic' />
              </div>
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <p className='m-0 p-0 text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {profile.username}
                    </p>
                  </div>

                  <div className='d-flex flex-column fw-bold fs-6 pe-2'>
                    {/* <p
                    className='m-0 p-0 d-flex align-items-center text-gray-400 me-5 mb-2'
                  >
                    International Logistic / Accounting (program names)
                  </p> */}
                    <p className='m-0 p-0 d-flex align-items-center text-gray-400 me-5 mb-2'>
                      {profile.company}
                    </p>
                  </div>
                </div>

                <div className='d-flex my-4'>
                  <div className='d-flex flex-row gap-3'>
                    {docs.length > 0 &&
                      docs?.map((doc, index) => {
                        return (
                          <div className='d-flex flex-row gap-3'>
                            <img
                              className='mt-1 align-self-start'
                              alt='Pic'
                              width='40'
                              src={toAbsoluteUrl(
                                '/media/icons/duotune/files/pdf.png'
                              )}
                            />
                            <div>
                              <p className='m-0 fw-bold'>{doc.type}</p>
                              <span
                                className='btn p-0 m-0 text-hover-primary fw-thin'
                                onClick={() => {
                                  setLoadingBtns((prevLoadingBtns) => ({
                                    ...prevLoadingBtns,
                                    [doc.id]: true,
                                  }))
                                  getFileDownloadLink(doc?.id).finally(() => {
                                    setLoadingBtns((prevLoadingBtns) => ({
                                      ...prevLoadingBtns,
                                      [doc.id]: false,
                                    }))
                                  })
                                }}
                              >
                                {loadingBtns[doc.id] ? 'Loading...' : 'View'}
                              </span>
                            </div>
                          </div>
                        )
                      })}
                    {/* <img
                      className='mt-1 align-self-start'
                      alt='Pic'
                      src={toAbsoluteUrl('/media/icons/duotune/files/pdf.png')}
                    />
                    <div>
                      <p className='m-0 fw-bold'>Resume</p>
                      <a className='btn p-0 m-0 text-hover-primary fw-thin'>View</a>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap gap-10'>
                    <div className='d-flex flex-row gap-3 align-items-center'>
                      <img
                        className='d-flex align-self-center'
                        alt='Pic'
                        src={toAbsoluteUrl('/media/svg/MailOpened.svg')}
                      />
                      <span className='text-gray-400'>{profile.email}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* <div className='card-footer'>
        <KTIcon className='text-primary w-30px h-30px' iconName={'bi bi-geo-alt-fill'} />
        <KTIcon className='text-primary' iconName={'bi bi-calendar3'} />
      </div> */}
    </div>
  )
}

export default MenteeProfileCard
