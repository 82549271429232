import axios from "axios";
import { SubmitTaskValues } from "../types/types";
import { API_URL } from "app/consts/consts";

export const submitTask = async (step_id: number | string, values:SubmitTaskValues) =>{
    try {
        console.log(values)
        const response = await axios.post(`${API_URL}/mentorship-task-steps/${step_id}/submission`, {
            message: values.message,
            mentee_file: values.selectedFile
          }, {headers: {
            'Content-Type': 'multipart/form-data',
          },})
          return response.data
    } catch (err) {
        return false;
    }
}
export const getStepInfoMentee = async (id:string | undefined | number) => {
  try {
    const response = await axios.get(`${API_URL}/mentorship-task-steps/${id}`)
    return response.data
} catch (err) {
    return false;
}
}
