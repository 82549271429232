import {useEffect, useState} from 'react'
import {KTIcon, KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers/index'
import axios from 'axios'
import {API_URL} from 'app/consts/consts'
import {useAuth} from 'app/modules/auth'
import {convertTimestamp} from 'utils/stringDateFunctions'

const MenteeProfileCompletedTasks = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [tasks, setTasks] = useState<Array<any>>([])
  const {currentUser} = useAuth()
  const getTasks = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/users/${currentUser?.id}/mentorships-completed-tasks`
      )
      setTasks(response.data)
    } catch (err) {
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getTasks()
  }, [])
  const completedTasks = [
    {
      id: 1,
      programName: 'Conducting Business Organization',
      taskName: 'International Logistics',
      taskDate: '12.08.2021',
      mentorReview: {
        date: 'January 03, 2024',
        mentorName: 'John Doe',
        taskName: 'Web Development',

        score: 4,
        rejectionReason:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis',
        finishTime: '3 Months 2 Weeks',
        completedSteps: '8 Steps',
      },
    },
    {
      id: 2,
      programName: 'Pricing the product',
      taskName: 'Accounting',
      taskDate: '12.08.2021',
      mentorReview: {
        date: 'January 03, 2024',
        mentorName: 'John Doe',
        taskName: 'Web Development',

        score: 3,
        rejectionReason:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis',
        finishTime: '3 Months 2 Weeks',
        completedSteps: '8 Steps',
      },
    },
    {
      id: 3,
      programName: 'Social Media Management',
      taskName: 'Accounting',
      taskDate: '12.08.2021',
      mentorReview: {
        date: 'January 03, 2024',
        mentorName: 'John Doe',
        taskName: 'Web Development',
        score: 3,
        rejectionReason:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis',
        finishTime: '3 Months 2 Weeks',
        completedSteps: '8 Steps',
      },
    },
    {
      id: 4,
      programName: 'Web Development',
      taskName: 'International Logistics',
      taskDate: '12.08.2021',
      mentorReview: {
        date: 'January 03, 2024',
        mentorName: 'John Doe',
        taskName: 'Web Development',
        score: 2,
        rejectionReason:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis',
        finishTime: '3 Months 2 Weeks',
        completedSteps: '8 Steps',
      },
    },
  ]
  return (
    <>
      <div className='card card-custom card-flush'>
        <div className='card-header'>
          <h3 className='card-title'>Completed Tasks</h3>
        </div>
        <div className='card-body py-5'>
          <table className='table gs-7 gy-7 gx-7'>
            <tbody>
              {tasks.map((task) => (
                <TableTaskItem
                  key={task?.id}
                  id={task?.id}
                  programName={task?.mentorship?.program_name}
                  taskName={task?.name}
                  taskDate={task?.end_date}
                  mentorReview={task?.mentorReview}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

const TableTaskItem = ({id, programName, taskName, taskDate, mentorReview}) => {
  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          <div className='symbol symbol-45px me-5'>
            <KTIcon className='symbol-label' iconName='home' />
          </div>
          <div className='d-flex justify-content-start flex-column'>
            <a href='#' className='text-gray-900 fw-bold text-hover-primary fs-6'>
              {programName}
            </a>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>{taskName}</span>
          </div>
        </div>
      </td>

      <td className='text-end'>
        <div className='d-flex justify-content-start flex-column'>
          <span className='text-gray-900 fw-bold text-hover-primary fs-6'>
            {convertTimestamp(taskDate)?.date}
          </span>
          {/* <MentorReviewModal
                        id={id}
                        date={mentorReview.date}
                        rejectionReason={mentorReview.rejectionReason}
                        finishTime={mentorReview.finishTime}
                        completedSteps={mentorReview.completedSteps}
                        mentorName={mentorReview.mentorName}
                        score={mentorReview.score}
                        taskName={mentorReview.taskName}
                    /> */}
        </div>
      </td>
    </tr>
  )
}

const MentorReviewModal = ({
  id,
  date,
  mentorName,
  taskName,
  score,
  rejectionReason,
  finishTime,
  completedSteps,
}) => {
  return (
    <>
      <span
        data-bs-toggle='modal'
        data-bs-target={`#kt_modal_${id}`}
        className='text-muted fw-semibold text-muted d-block fs-7 cursor-pointer'
      >
        See Mentor Review
      </span>
      <div className='modal fade' tabIndex={-1} id={`kt_modal_${id}`} aria-hidden='true'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>
                {mentorName} | {taskName}
                <span className='text-start text-muted fw-semibold d-block fs-7'>
                  Task Score by Mentor: &nbsp; <Rating score={score} />
                </span>
              </h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body text-start'>
              <div>
                <p>
                  <span className='fw-bold text-muted'>Date of Review: &nbsp;</span>
                  {date}
                </p>
              </div>
              <div>
                <span className='fw-bold'>Rejection reason:</span>
                <p>{rejectionReason}</p>
              </div>
              <div className='d-flex'>
                <div className='border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 me-6 mb-3'>
                  <span className='fs-6 text-gray-700 fw-bold'>{finishTime}</span>

                  <div className='fw-semibold text-gray-500'>Finish Time</div>
                </div>

                <div className='border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 mb-3'>
                  <span className='fs-6 text-gray-700 fw-bold'>{completedSteps}</span>

                  <div className='fw-semibold text-gray-500'>Completed Steps </div>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Rating = ({score}) => {
  return (
    <div className='rating'>
      {[...Array(score)].map((e, i) => (
        <div key={i} className='rating-label checked'>
          <KTSVG path='media/icons/duotune/general/gen029.svg' className='svg-icon svg-icon-1' />
        </div>
      ))}
      {[...Array(5 - score)].map((e, i) => (
        <div key={i} className='rating-label'>
          <KTSVG path='media/icons/duotune/general/gen029.svg' className='svg-icon svg-icon-1' />
        </div>
      ))}
    </div>
  )
}

export default MenteeProfileCompletedTasks
