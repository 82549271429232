import React from 'react';
import { useAuth } from "app/modules/auth";
import { useEffect, useState } from "react";
import { IMentorships } from "../types/types";
import { Link } from 'react-router-dom';
import { getMentorships } from 'api/requests';
import ProgramCard from 'components/program/ProgramCard.component';
import LoadingSpinner from 'components/LoadingSpinner.component';

const Programs = () => {
    const { currentUser } = useAuth();
    const [mentorships, setMentorships] = useState<IMentorships[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false);
    useEffect(() => {
        const fetchMentorship = async () => {
            try {
                setIsLoading(true)
                const fetchedMentorships = await getMentorships(currentUser?.id)
                if (fetchedMentorships) {
                    setMentorships(fetchedMentorships.data)
                }
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
            }

        }

        fetchMentorship();
    }, [])
    return (
        <div>
            {isLoading ? (<LoadingSpinner />) :
                (
                    <div>
                        <h1 className='fw-bold'>Programs</h1>
                        <div className='row g-5 g-xl-8 '>
                            {mentorships.map((mentorship) => {
                                return <Link className='col-xl-4 cursor-pointer' to={`${mentorship.program_id}`}>
                                    <ProgramCard program_id={mentorship.program_id} name={mentorship.program_name} activeMentorship={mentorship.active_mentorship} totalMentorship={mentorship.total_mentorship} company={currentUser?.company} />
                                </Link>
                            })}
                            {mentorships.length === 0 &&
                                <div className='col-12'>
                                    <div className='card card-custom card-stretch'>
                                        <div className='card-body'>
                                            <div className='d-flex flex-column align-items-center'>
                                                <div className="symbol symbol-50px me-2">
                                                    <span className="symbol-label bg-light-primary">
                                                        <i className="ki-duotone ki-compass fs-2x text-primary">
                                                            <span className="path1">
                                                            </span>
                                                            <span className="path2">
                                                            </span>
                                                        </i>
                                                    </span>
                                                </div>
                                                <h1 className='fw-bold'>No Programs</h1>
                                                <p className='text-muted'>
                                                    There are no programs available at the moment
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                )}
        </div>
    );
};

export default Programs;