import React, { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import { useAuth } from 'app/modules/auth'

export const formatDate = (from: Date, to: Date) => {
  if (from && to) {
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'short',
      year: '2-digit',
    }

    const formattedFromDate = from.toLocaleDateString(undefined, options)
    const formattedToDate = to.toLocaleDateString(undefined, options)

    return `${formattedFromDate} – ${formattedToDate}`
  } else {
    return ''
  }
}

export const formatReturningDate = (date: Date) => {
  if (date) {
    console.log(date)
    const year = date.getUTCFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    console.log(year, month, day)
    const formattedMonth = month < 10 ? '0' + month : month
    const formattedDay = day < 10 ? '0' + day : day

    return `${year}-${formattedMonth}-${formattedDay}`
  }

  return ''
}

const DatePicker = () => {
  const { setFieldValue } = useFormikContext()
  const { currentUser } = useAuth()
  const initialStartDate = currentUser?.requested_mentorship_period_start
    ? currentUser?.requested_mentorship_period_start
    : ''
  const initialEndDate = currentUser?.requested_mentorship_period_end
    ? currentUser?.requested_mentorship_period_end
    : ''

  useEffect(() => {
    if (
      currentUser?.requested_mentorship_period_start &&
      currentUser?.requested_mentorship_period_end
    ) {
      const initialStartDate = new Date(currentUser?.requested_mentorship_period_start)
      const initialEndDate = new Date(currentUser?.requested_mentorship_period_end)
      setFieldValue('requested_mentorship_period_start', formatReturningDate(initialStartDate))
      setFieldValue('requested_mentorship_period_end', formatReturningDate(initialEndDate))
    }
  }, [])

  const initialDate = () => {
    console.log('init')
    if (
      currentUser?.requested_mentorship_period_start &&
      currentUser?.requested_mentorship_period_end
    ) {
      const initialStartDate = new Date(currentUser?.requested_mentorship_period_start)
      const initialEndDate = new Date(currentUser?.requested_mentorship_period_end)
      return formatDate(initialStartDate, initialEndDate)
    }
    console.log('after if')
    return ''
  }
  function handleApply(event, picker) {
    const startDate = new Date(picker.startDate._d)
    const endDate = new Date(picker.endDate._d)
    console.log(startDate, endDate)
    picker.element.val(formatDate(startDate, endDate))
    console.log(formatReturningDate(startDate), formatReturningDate(endDate))
    setFieldValue('requested_mentorship_period_start', formatReturningDate(startDate))
    setFieldValue('requested_mentorship_period_end', formatReturningDate(endDate))
    console.log(new Date(picker.startDate._d), new Date(picker.endDate))
  }
  return (
    <div className='align-self-start position-relative' style={{ width: 400 }}>
      <DateRangePicker
        initialSettings={{
          autoUpdateInput: false,
          minDate: new Date(),
        }}
        onApply={handleApply}
      >
        <input defaultValue={initialDate()} className='form-control form-control-white' placeholder='May 1,24 - Jun 12,24' />
      </DateRangePicker>
    </div>
  )
}

export default DatePicker
