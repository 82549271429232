
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers/index'
import React from 'react';
import MenteeProfileCard from './components/MenteeProfileCard';
import MenteeProfileMentorRecommendations from './components/MenteeProfileMentorRecommendations';
import MenteeProfileCompletedTasks from './components/MenteeProfileCompletedTasks';

const MenteeProfile = () => {
    return (
        <div>
            <MenteeProfileCard />
            <MenteeProfileCompletedTasks />
            <MenteeProfileMentorRecommendations />
        </div>
    );
};

export default MenteeProfile;