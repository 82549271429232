import { toAbsoluteUrl } from '_metronic/helpers'
import React from 'react'

interface Props {
  id: number
  name: string
  tasksLength: number
  failed?: boolean
}
const MenteeCard: React.FC<Props> = ({ name, id, tasksLength, failed }) => {
  return (
    <div className='card mb-6 cursor-pointer' style={{ borderRadius: 15 }}>
      <div className='card-body py-3 px-3'>
        <div className='d-flex flex-row gap-5 align-items-center'>
          <div className='w-40px h-40px rounded '>
            <img
              className='h-40px w-40px rounded'
              src={toAbsoluteUrl('/media/avatars/blank.png')}
              alt='metronic'
            />
          </div>
          <div className=''>
            <p className='fs-4 fw-bold text-gray-900 m-0'>{name}</p>
            {
              failed ? (
                <p style={{ color: '#F23F44' }}>Failed</p>
              ) :
                <p style={{ color: `${tasksLength === 0 ? '#F23F44' : '#50CD89'}` }}>
                  {tasksLength === 0 ? 'Assign task' : 'Task assigned'}
                </p>
            }

          </div>
        </div>
      </div>
    </div>
  )
}

export default MenteeCard
