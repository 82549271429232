import { API_URL } from "app/consts/consts";
import axios from "axios";
import { SubmitTaskReviewValues } from "types/types";

export const submitTaskReview = async (step_id: number | string, values: SubmitTaskReviewValues) => {
  const response = await axios.post(`${API_URL}/mentorship-task-steps/${step_id}/review`, {
    message: values.message,
    level: values.level
  })
  return response.data
}

export const getSteps = async (task_id: number | string) => {
  try {
    const response = await axios.get(`${API_URL}/steps?task_id=${task_id}`)
    return response.data
  } catch (err) {
    return false;
  }
}

export const getMentorships = async (id: number | undefined) => {
  try {
    const response = await axios.get(`${API_URL}/users/${id}/mentorship-stats`)
    console.log(response)
    if (!response) {
      return null
    }
    return response.data
  } catch (err) {
    return null
  }
}

export const getMentorshipsMentee = async (id: number | undefined) => {
  try {
    const response = await axios.get(`${API_URL}/users/${id}/mentorships`)
    console.log(response)
    if (!response) {
      return null
    }
    return response.data
  } catch (err) {
    return null
  }
}

export const getTrainingPrograms = async (id: number | undefined) => {
  try {
    const response = await axios.get(`${API_URL}/trainings/programs`)
    console.log(response)
    if (!response) {
      return null
    }
    return response.data
  } catch (err) {
    return null
  }
}
