import { FC, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { toAbsoluteUrl, defaultUserInfos, MessageModel, UserInfoModel } from '../../helpers'
import { IChat } from './ChatLists'
import axios from 'axios'
import { API_URL } from 'app/consts/consts'
import { aX, bu } from '@fullcalendar/core/internal-common'
import { useAuth } from 'app/modules/auth'
import _ from 'lodash'
import { getFileDownloadLinkChat, viewFileDownloadLinkChat } from 'app/modules/register/api/requests'
import { CreateZoom } from './CreateZoom.component'
import { convertTimestamp } from 'utils/stringDateFunctions'


export function getTimeAgo(timestamp: string): string {
  const now = new Date()
  const createdDate = new Date(timestamp)
  const timeDifference = now.getTime() - createdDate.getTime()
  const seconds = Math.floor(timeDifference / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  if (minutes < 2) {
    return 'Just now'
  } else if (minutes < 60) {
    return `${minutes} minutes ago`
  } else if (hours < 24) {
    return `${hours - 3} hours ago`
  } else {
    return `${days} days ago`
  }
}

type Props = {
  isDrawer?: boolean
  chat: IChat
  setIntervalId: (id: any) => void
}

let bufferMessages: Array<MessageModel> = []

const ChatInner: FC<Props> = ({ isDrawer = false, chat, setIntervalId }) => {
  const { currentUser } = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [messages, setMessages] = useState<any[]>(bufferMessages)
  const [isSending, setIsSending] = useState<boolean>()
  const [page, setPage] = useState<number>(0)
  const chatRef = useRef<HTMLDivElement>(null)
  const chatMainRef = useRef<HTMLDivElement>(null)
  const [file, setFile] = useState<any>()


  const getNewMessages = async () => {
    try {
      if (chat?.participant?.id) {
        const response = await axios.get(`${API_URL}/conversations/${chat.participant.id}?page=0`)
        const responseData: Array<any> = response.data.data
        const convertedMessages: MessageModel[] = await responseData.reverse().map((item: any) => {
          return {
            id: item.id,
            type: item.sender.id == currentUser?.id ? 'out' : 'in',
            body: item.body,
            data: item?.data,
            created_at: item.created_at,
            sender: item.sender,
          }
        })
        setMessages((prevMessages) => {
          const newMessages = convertedMessages.filter(
            (newMessage) =>
              !prevMessages.some((existingMessage) => existingMessage.id === newMessage.id)
          )
          if (newMessages.length > 0) {
            return [...prevMessages, ...newMessages]
          } else {
            return prevMessages
          }
        })
      }
    } catch (err) {
      setMessages((prevMessages) => {
        return prevMessages
      })
    }
  }
  const [loadingBtns, setLoadingBtns] = useState<{ [key: string]: boolean }>({})
  const [fileName, setFileName] = useState('')

  const getChatInfo = async () => {
    if (chat?.participant?.id) {
      setIsLoading(true)
      const response = await axios.get(
        `${API_URL}/conversations/${chat.participant.id}?page=${page}`
      )
      const responseData: Array<any> = response.data.data
      console.log(responseData)
      if (responseData.length > 0) {
        const convertedMessages: MessageModel[] = await responseData.reverse().map((item: any) => {
          return {
            id: item?.id,
            type: item?.sender?.id == currentUser?.id ? 'out' : 'in',
            body: item?.body,
            data: item?.data,
            created_at: item?.created_at,
            sender: item?.sender,
          }
        })
        setMessages((prevMessages) => {
          const newMessages = convertedMessages.filter(
            (newMessage) =>
              !prevMessages.some((existingMessage) => existingMessage.id === newMessage.id)
          )
          if (newMessages.length > 0 && !isLoading) {
            console.log('1', prevMessages)
            console.log('2', newMessages)
            return [...newMessages, ...prevMessages]
          } else {
            return prevMessages
          }
        })
      }
      setIsLoading(false)
    }
  }
  const fileInputRef = useRef<HTMLInputElement>(null)

  const sendMessage = async () => {
    setIsSending(true)

    let uploadedFile
    if (file) {
      const formData = new FormData()
      formData.append('file', file)
      const response = await axios.post(
        `${API_URL}/users/${chat.participant.id}/send-file`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      setMessages([...messages, { ...response.data.message }])
    }
    if (message) {
      const newMessage = {
        body: message,
      }
      const response = await axios.post(
        `${API_URL}/conversations/${chat.participant.id}/send-message`,
        newMessage
      )
      chatRef.current?.scrollIntoView({ behavior: 'smooth' })
      console.log(bufferMessages, messages)

      setMessages([...messages, response.data.message])

      setMessage('')
      scrollToBottom()
    }
    const fileInputElement = fileInputRef.current
    if (fileInputElement) {
      fileInputElement.value = ''
    }
    setFileName('')
    setFile(null)
    setIsSending(false)
  }

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0]
    if (file) {
      setFile(file)
      setFileName(file.name)
    }
  }

  const scrollToBottom = () => {
    chatRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  const handleScroll = () => {
    if (chatMainRef.current) {
      const { scrollTop } = chatMainRef.current
      if (scrollTop === 0) {
        console.log('123')
        setPage((prevPage) => prevPage + 1)
      }
    }
  }
  useEffect(() => {
    getNewMessages()
    const intervalId = setInterval(getNewMessages, 10000)
    setIntervalId(intervalId)
    chatMainRef.current?.addEventListener('scroll', handleScroll)
    return () => {
      clearInterval(intervalId)
      chatMainRef.current?.removeEventListener('scroll', handleScroll)
    }
  }, [])
  console.log(messages)
  useEffect(() => {
    getChatInfo()
  }, [page])
  useEffect(() => {
    console.log(messages)
  }, [])
  return (
    <div className='card-body' id={'kt_drawer_chat_messenger_body'}>
      {/* {JSON.stringify(chat)} */}
      <div
        ref={chatMainRef}
        className={clsx('scroll-y me-n5 pe-5 h-300px')}
        style={{ minHeight: '80%' }}
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies={
          isDrawer
            ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
            : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
        }
        data-kt-scroll-wrappers={
          isDrawer
            ? '#kt_drawer_chat_messenger_body'
            : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
        }
        data-kt-scroll-offset={isDrawer ? '0px' : '5px'}
      >
        {isLoading && (
          <div className='w-100 d-flex justify-content-center'>
            <span
              className='spinner-border text-primary'
              data-kt-app-page-loading='on'
              role='status'
            >
              <span className='visually-hidden'>Loading...</span>
            </span>
          </div>
        )}

        {messages.map((message, index) => {
          const state = message?.type === 'in' ? 'info' : 'primary'

          const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${message?.type === 'in' ? 'start' : 'end'
            } mb-10`
          return (
            <div key={`message${index}`} className={clsx('d-flex', contentClass, 'mb-10')}>
              <div
                className={clsx(
                  'd-flex flex-column align-items',
                  `align-items-${message?.type === 'in' ? 'start' : 'end'}`
                )}
              >
                <div className='d-flex align-items-center mb-2'>
                  {message?.type === 'in' ? (
                    <>
                      <div className='symbol  symbol-35px symbol-circle '></div>
                      <div className='ms-3'>
                        <a href='#' className='fs-5 fw-bold text-gray-900 text-hover-primary me-1'>
                          {chat?.participant?.name}
                        </a>
                        <span className='text-muted fs-7 mb-1'>
                          {getTimeAgo(message?.created_at)}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='me-3'>
                        <span className='text-muted fs-7 mb-1'>
                          {getTimeAgo(message?.created_at)}
                        </span>
                        <a href='#' className='fs-5 fw-bold text-gray-900 text-hover-primary ms-1'>
                          You
                        </a>
                      </div>
                      <div className='symbol  symbol-35px symbol-circle '></div>
                    </>
                  )}
                </div>
                {message?.data?.zoom_meeting_id ? (
                  <>
                    <a
                      className={clsx(
                        'p-5 cursor-pointer',
                        'text-dark fw-semibold mw-lg-400px',
                        `text-${message?.type === 'in' ? 'start' : 'end'}`
                      )}
                      href={message?.data?.join_url}
                      style={{ backgroundColor: '#CCE8E8', borderRadius: '20px' }}
                      data-kt-element='message-text'
                    >
                      <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                        <img src={toAbsoluteUrl('/media/logos/zoomIcon.png')} height='50' width='50' />
                        <div>
                          <p
                            className='text-left fw-bolder mt-2'
                            style={{ textAlign: 'left', maxWidth: '150px' }}
                          >
                            {message?.body}
                          </p>
                          <p>
                            {convertTimestamp(message?.data?.start_date).date} /
                            {convertTimestamp(message?.data?.start_date).time}
                          </p>
                        </div>
                      </div>
                    </a>
                  </>
                ) : (
                  <div
                    className={clsx(
                      'p-5',
                      'text-dark fw-semibold mw-lg-400px',
                      `text-${message?.type === 'in' ? 'start' : 'end'}`
                    )}
                    style={{ backgroundColor: '#CCE8E8', borderRadius: '20px' }}
                    data-kt-element='message-text'
                    dangerouslySetInnerHTML={{ __html: message?.body }}
                  ></div>
                )}
                {message?.data?.file_upload_id && (
                  <div className='d-flex flex-row gap-3 mx-3'>
                    <img
                      className='mt-1 align-self-start'
                      alt='Pic'
                      width='40'
                      src={toAbsoluteUrl(
                        '/media/icons/duotune/files/pdf.png'
                      )}
                    />
                    <div>
                      <p className='m-0 fw-bold'>File</p>
                      <span
                        className='btn p-0 m-0 text-hover-primary fw-thin'
                        onClick={() => {
                          setLoadingBtns((prevLoadingBtns) => ({
                            ...prevLoadingBtns,
                            [message?.data?.file_upload_id]: true,
                          })) // set loading to true
                          viewFileDownloadLinkChat(message?.data?.file_upload_id).finally(() => {
                            setLoadingBtns((prevLoadingBtns) => ({
                              ...prevLoadingBtns,
                              [message?.data?.file_upload_id]: false,
                            }))
                          })
                        }}
                      >
                        {loadingBtns[message?.data?.file_upload_id] ? 'Loading...' : 'View'}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )
        })}
        <div ref={chatRef} />
      </div>

      <div
        className='card-footer p-0'
        id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
      >
        <textarea
          className='form-control form-control-flush mb-3'
          rows={2}
          data-kt-element='input'
          placeholder='Type a message'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        {/* File input for selecting files */}

        <div className='d-flex flex-stack w-100 justify-content-between mt-3'>
          <div>
            <button
              className='btn'
              onClick={() => {
                document?.getElementById('file')?.click()
              }}
            >
              <img src={toAbsoluteUrl('/media/Clip.png')} />
              {fileName}
            </button>
            <input
              ref={fileInputRef}
              className='btn btn-sm btn-primary'
              style={{ display: 'none' }}
              type='file'
              id='file'
              accept='.pdf,.docx'
              onChange={handleFileSelect}
            />
            <button className='btn p-0' data-bs-toggle='modal' data-bs-target='#create-zoom'>
              <img src={toAbsoluteUrl('/media/Close1.png')} />
            </button>
          </div>

          <button
            className='btn btn-primary'
            type='button'
            data-kt-element='send'
            onClick={() => {
              sendMessage()
            }}
          >
            {isSending ? 'Sending...' : 'Send'}
          </button>
        </div>
      </div>
      <CreateZoom
        otherUserId={chat?.participant?.id}
        sendMessage={sendMessage}
        setMessages={setMessages}
        messages={messages}
      />
    </div>
  )
}

export { ChatInner }
