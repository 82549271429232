import { getTimeAgo } from '_metronic/partials'
import { INotification } from '_metronic/partials/layout/activity-drawer/NotificationModal'
import { API_URL } from 'app/consts/consts'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

export const LatestNotification = (notification) => {
  console.log(notification.notification === undefined)
  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5' style={{ display: 'block' }}>
        <h3 className='card-title text-secondary'>Latest Notification</h3>
      </div>
      <div className='card-body pt-0'>

        <div>
          {notification?.notification === undefined ? (<div className='col-12'>
            <div className='card card-custom card-stretch'>
              <div className='card-body'>
                <div className='d-flex flex-column align-items-center'>
                  <div className="symbol symbol-50px me-2">
                    <span className="symbol-label bg-light-primary">
                      <i className="ki-duotone ki-compass fs-2x text-primary">
                        <span className="path1">
                        </span>
                        <span className="path2">
                        </span>
                      </i>
                    </span>
                  </div>
                  <h1 className='fw-bold'>
                    No Notification
                  </h1>
                  <p className='text-muted'>
                    You have no notifications yet
                  </p>
                </div>
              </div>
            </div>
          </div>) :
            <h3>
              {
                JSON.parse(notification?.notification?.message)
              }
            </h3>
          }
          {/* <p className=' text-secondary'>Admin rejected your step development. </p> */}
        </div>
      </div>
      <div className="card-footer">
        <div className='w-100 d-flex justify-content-between'>
          {notification?.notification !== undefined && <span className='badge  p-4' style={{ backgroundColor: '#F1FAFF', color: '#00A3FF' }}>{getTimeAgo(notification?.notification?.date)}</span>}
          {/* <span className='badge px-4' style={{backgroundColor:'#F5F8FA', color: '#A1A5B7'}}>Details</span> */}

        </div>
      </div>
    </div>
  )
}
