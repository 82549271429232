import { toAbsoluteUrl } from '_metronic/helpers';
import React from 'react';

const RejectedBanner = () => {
    return (
        <div style={{ borderColor: '#F64E60' }} className='cursor-default bg-light-danger btn btn-outline btn-outline-dashed btn-outline-default p-2 d-flex flex-column align-items-start'
        >
            <div className='d-flex flex-row gap-5 '>
                <img alt='warning-banner' className='' src={toAbsoluteUrl('/media/svg/Warning.svg')} />
                <div>
                    <p className='text-align-left mb-2 p-0 fw-bold' style={{ textAlign: 'left' }}>Your submitted task has been rejected.</p>
                    <p className='text-secondary p-0 m-0' style={{ textAlign: 'left' }}>Please see rejection details on the flow and submit a revision.</p>
                </div>
            </div>
        </div>
    );
};

export default RejectedBanner;