import axios from 'axios'
import { KTIcon, KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers/index'
import { API_URL } from 'app/consts/consts'
import { useAuth } from 'app/modules/auth'
import { useEffect, useState } from 'react'
import { convertTimestamp } from 'utils/stringDateFunctions'

interface ITrainings {
  id: number
  task: { name: string; program: { name: string } }
  created_at: string
}
interface ITask {
  task: {
    id: number
    name: string
    end_date: string
    program: {
      id: number
      name: string
    }
  }
}
const TrainerProfileCompletedTasks = () => {
  const { currentUser } = useAuth()
  const [tasks, setTasks] = useState<ITask[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const getTasks = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(`${API_URL}/users/141/instructional-design-completed-tasks`)
      setTasks(response?.data?.data)
    } catch (err) {
      setTasks([])
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getTasks()
  }, [])
  return (
    <>
      <div className='card card-custom card-flush'>
        <div className='card-header'>
          <h3 className='card-title'>Delivered Training</h3>
        </div>
        <div className='card-body py-5'>
          {isLoading ? (
            <div className='v-100 position-relative' style={{ minHeight: '30vh' }}>
              <div
                className='page-loading position-absolute'
                style={{
                  top: '50%',
                  left: '50%',
                }}
              >
                <span
                  className='spinner-border text-primary'
                  data-kt-app-page-loading='on'
                  role='status'
                >
                  <span className='visually-hidden'>Loading...</span>
                </span>
              </div>
            </div>
          ) : (
            <table className='table gs-7 gy-7 gx-7'>
              <tbody>
                {tasks?.map((task) => (
                  <TableTaskItem
                    key={task.task.id}
                    id={task.task.id}
                    programName={task.task.program.name}
                    taskName={task.task.name}
                    taskDate={task.task.end_date}
                  />
                ))}
                {
                  tasks?.length === 0 && (
                    <div className='col-12'>
                      <div className='card card-custom card-stretch'>
                        <div className='card-body'>
                          <div className='d-flex flex-column align-items-center'>
                            <div className="symbol symbol-50px me-2">
                              <span className="symbol-label bg-light-primary">
                                <i className="ki-duotone ki-compass fs-2x text-primary">
                                  <span className="path1">
                                  </span>
                                  <span className="path2">
                                  </span>
                                </i>
                              </span>
                            </div>
                            <h1 className='fw-bold'>No Data</h1>
                            <p className='text-muted'>
                              There are no completed tasks available at the moment
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  )
}

const TableTaskItem = ({ id, programName, taskName, taskDate }) => {
  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          <div className='symbol symbol-45px me-5'>
            <KTIcon className='symbol-label' iconName='home' />
          </div>
          <div className='d-flex justify-content-start flex-column'>
            <a href='#' className='text-gray-900 fw-bold text-hover-primary fs-6'>
              {programName}
            </a>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>{taskName}</span>
          </div>
        </div>
      </td>

      <td className='text-end'>
        <div className='d-flex justify-content-start flex-column'>
          <span className='text-gray-900 fw-bold text-hover-primary fs-6'>
            {convertTimestamp(taskDate).date}
          </span>
          {/* <MentorReviewModal
            id={id}
            date={mentorReview.date}
            rejectionReason={mentorReview.rejectionReason}
            finishTime={mentorReview.finishTime}
            completedSteps={mentorReview.completedSteps}
            mentorName={mentorReview.mentorName}
            score={mentorReview.score}
            taskName={mentorReview.taskName}
          /> */}
        </div>
      </td>
    </tr>
  )
}

const MentorReviewModal = ({
  id,
  date,
  mentorName,
  taskName,
  score,
  rejectionReason,
  finishTime,
  completedSteps,
}) => {
  return (
    <>
      <span
        data-bs-toggle='modal'
        data-bs-target={`#kt_modal_${id}`}
        className='text-muted fw-semibold text-muted d-block fs-7 cursor-pointer'
      >
        See Mentor Review
      </span>
      <div className='modal fade' tabIndex={-1} id={`kt_modal_${id}`} aria-hidden='true'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>
                {mentorName} | {taskName}
                <span className='text-start text-muted fw-semibold d-block fs-7'>
                  Task Score by Mentor: &nbsp; <Rating score={score} />
                </span>
              </h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body text-start'>
              <div>
                <p>
                  <span className='fw-bold text-muted'>Date of Review: &nbsp;</span>
                  {date}
                </p>
              </div>
              <div>
                <span className='fw-bold'>Rejection reason:</span>
                <p>{rejectionReason}</p>
              </div>
              <div className='d-flex'>
                <div className='border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 me-6 mb-3'>
                  <span className='fs-6 text-gray-700 fw-bold'>{finishTime}</span>

                  <div className='fw-semibold text-gray-500'>Finish Time</div>
                </div>

                <div className='border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 mb-3'>
                  <span className='fs-6 text-gray-700 fw-bold'>{completedSteps}</span>

                  <div className='fw-semibold text-gray-500'>Completed Steps </div>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Rating = ({ score }) => {
  return (
    <div className='rating'>
      {[...Array(score)].map((e, i) => (
        <div key={i} className='rating-label checked'>
          <KTSVG path='media/icons/duotune/general/gen029.svg' className='svg-icon svg-icon-1' />
        </div>
      ))}
      {[...Array(5 - score)].map((e, i) => (
        <div key={i} className='rating-label'>
          <KTSVG path='media/icons/duotune/general/gen029.svg' className='svg-icon svg-icon-1' />
        </div>
      ))}
    </div>
  )
}

export default TrainerProfileCompletedTasks
