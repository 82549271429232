import {toAbsoluteUrl} from '_metronic/helpers'
import clsx from 'clsx'

type Props = {
  className?: string
  bgImage?: string
  title?: string
  active: number
}
const DashboardBackgroundTiles = ({
  className,
  bgImage = toAbsoluteUrl('/media/patterns/vector-1.png'),
  title = 'Properties',
  active,
}: Props) => {
  return (
    <div
      className='card card-flush bgi-no-repeat bgi-size-cover bgi-position-x-end mb-5'
      style={{
        height: 200,
        backgroundColor: '#F1416C',
      }}
    >
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>{active}</span>

          <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>{title}</span>
        </div>
      </div>
    </div>
  )
}

export {DashboardBackgroundTiles}
