import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers/index'
import React from 'react'
import TrainerProfileCard from './components/TrainerProfileCard'
import TrainerProfileMentorRecommendations from './components/TrainerProfileMentorRecommendations'
import TrainerProfileCompletedTasks from './components/TrainerProfileCompletedTasks'

const TrainerProfile = () => {
  return (
    <div>
      <TrainerProfileCard />
      <TrainerProfileCompletedTasks />
      <TrainerProfileMentorRecommendations />
    </div>
  )
}

export default TrainerProfile
