import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Programs from './pages/TrainerPrograms.page';
import ProgramInfo from './pages/TrainerProgramInfo.page';

const TrainerProgramWrapper = () => {
    return (
        <Routes>
            <Route
                path='/*'
                element={<Programs />}>
            </Route>
            <Route
                path='/:programId'
                element={<ProgramInfo />} >
            </Route>
        </Routes>
    );
};

export default TrainerProgramWrapper;