import { KTSVG, toAbsoluteUrl } from '_metronic/helpers';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { IMentorshipAdditional } from '../types/types';
import { API_URL } from 'app/consts/consts';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { getMentorshipTaskStepInfo, submitReview } from '../api/requests';
import { useParams } from 'react-router-dom';
import { Step } from 'types/types';

function getDateAgo(date: string) {
    const createdDate = new Date(date);
    const currentDate = new Date();
    const differenceMs = currentDate.getTime() - createdDate.getTime();
    const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
    if (differenceDays === 0) {
        return 'Today'
    }
    const dayText = differenceDays === 1 ? 'day' : 'days';
    return `${differenceDays} ${dayText} ago`
}

async function downloadSubmissionFile(id: number) {
    if (!id) {
        return;
    }
    const response = await axios.get(`${API_URL}/submission/${id}/get-file`);
    console.log(response)
    // fileDownload(response.data, 'upload.pdf')
    window.open(response?.data?.url, '_blank')
}
const ApproveReview: React.FC<{ mentorship: IMentorshipAdditional | null, step: Step | null, setStep: Dispatch<SetStateAction<Step | null>>, setIsTaskSubmitted: Dispatch<SetStateAction<string>>, setIsToastError: (message: string) => void }> = ({ mentorship, step, setStep, setIsTaskSubmitted, setIsToastError }) => {
    const [isLoadingBtn, setIsLoadingBtn] = useState<boolean>(false)
    let { mentorshipTaskStepId } = useParams();
    const [isApproving, setIsApproving] = useState<boolean>(false)
    const [isRejecting, setIsRejecting] = useState<boolean>(false)
    // const fetchInfo = async () => {
    //     const fetchedStep = await getMentorshipTaskStepInfo(mentorshipTaskStepId)
    //     console.log(fetchedStep.data)
    //     setStep(fetchedStep.data)
    // }
    const handleApprove = async () => {
        setIsApproving(true)
        try {
            submitReview(step?.submissions[0].id, true).finally(async () => {
                const fetchedStep = await getMentorshipTaskStepInfo(mentorshipTaskStepId)
                setIsApproving(false)
                setIsTaskSubmitted('Task was successfully approved')
                setStep(fetchedStep.data)
            });
        } catch (err) {
            setIsToastError("Sorry, something went wrong.")
        }
    }
    const handleReject = async () => {
        setIsRejecting(true)
        try {
            submitReview(step?.submissions[0].id, false).finally(async () => {
                const fetchedStep = await getMentorshipTaskStepInfo(mentorshipTaskStepId)
                setIsRejecting(false)
                setIsTaskSubmitted('Task was successfully rejected')
                setStep(fetchedStep.data)
            });
        } catch (err) {
            setIsToastError("Sorry, something went wrong.")
        }
    }
    return (
        <div>
            <div className="card-header">
                <div className="card-title">
                    <h3 className="card-label">
                        Waiting for review
                    </h3>
                </div>
            </div>

            <div className='card-body'>
                <div className='d-flex flex-row gap-5 mb-5'>
                    <img className='h-35px w-35px rounded align-self-start' style={{ borderRadius: 15 }} alt='Pic' src={toAbsoluteUrl('/media/avatars/blank.png')} />
                    <div>
                        <p className='m-0 text-color-primary'>{mentorship?.mentee_user.name}</p>
                        <p className='m-0 fw-bold'>{getDateAgo(step?.submissions[0].created_at)}</p>
                    </div>
                </div>
                <div>
                    <h2>Mentee’s Submission
                    </h2>
                    <p>{step?.submissions[0].message}</p>
                    {
                        step?.submissions[0].file_exist &&
                        <div className='d-flex flex-row gap-3 mb-7'>
                            <div>
                                <img
                                    className='mt-1 align-self-start'
                                    alt='Pic'
                                    width='40'
                                    src={toAbsoluteUrl(
                                        '/media/icons/duotune/files/pdf.png'
                                    )}
                                />
                            </div>
                            <div>
                                <p className='m-0 fw-bold'>Submission</p>
                                <span className='btn p-0 m-0 text-hover-primary fw-thin' onClick={() => {
                                    setIsLoadingBtn(true)
                                    downloadSubmissionFile(step?.submissions[0].id).finally(() => setIsLoadingBtn(false))
                                }}>{isLoadingBtn ? 'Loading...' : 'View'}</span>
                            </div>
                        </div>
                    }

                    <div className='d-flex flex-row gap-10'>
                        <button className='btn btn-primary d-flex flex-row gap-3 justify-content-center align-items-center' onClick={handleApprove}>
                            <KTSVG path="/media/icons/duotune/arrows/arr012.svg" className="svg-icon-muted svg-icon-1hx" />
                            <p className='p-0 m-0'>{isApproving ? 'Approving...' : 'Approve'}</p>
                        </button >
                        <div className='btn btn-danger d-flex flex-row gap-3 justify-content-center align-items-center' onClick={handleReject}>
                            <KTSVG path="/media/icons/duotune/arrows/arr011.svg" className="svg-icon-muted svg-icon-1hx" />
                            <p className='p-0 m-0'>{isRejecting ? 'Rejecting...' : 'Reject'}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ApproveReview;