import React, { useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { getMentorshipInfo } from '../api/requests';
import { useAuth } from 'app/modules/auth';
import LoadingSpinner from 'components/LoadingSpinner.component';
import TodoListMenteeCard from 'components/program/TodoMenteeList.component';

interface ProgramInfo {
    id: number;
    mentee_user: { id: number, name: string };
    mentor_user: { id: number, name: string };
    program: { id: number, name: string };
    status: string;
    current_mentorship_task_step_id: number;
    assigned_tasks: Array<any>;
}
const ProgramMentorships: React.FC = () => {
    const { programId } = useParams();
    const { currentUser } = useAuth()

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [programInfo, setProgramInfo] = useState<ProgramInfo[]>([])

    useEffect(() => {
        const fetchMentorship = async () => {
            setIsLoading(true)
            const fetchedMentorships = await getMentorshipInfo(currentUser?.id, programId)
            if (fetchedMentorships) {
                setProgramInfo(fetchedMentorships.data)
            }
            setIsLoading(false);
        }

        fetchMentorship();
    }, [])

    const toDoTasks = useMemo(() => {
        return programInfo?.filter(task => task.status === "waiting_task" || task.status === "todo");
    }, [programInfo])
    const inProgressTasks = useMemo(() => {
        return programInfo.filter(task => task.status === "in_progress");
    }, [programInfo])
    const completedTasks = useMemo(() => {
        return programInfo.filter(task => task.status === "completed");
    }, [programInfo])
    console.log(programInfo)
    return (
        <div>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <TodoListMenteeCard completedTasks={completedTasks} inProgressTasks={inProgressTasks} programInfo={programInfo} toDoTasks={toDoTasks} />
            )}
        </div>
    )
}

export default ProgramMentorships