import { toAbsoluteUrl } from '_metronic/helpers'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getMentorship, getMentorshipTaskStepInfo } from '../api/requests'
import ApproveReview from '../components/ApproveReview.components'
import { IMentorshipAdditional } from '../types/types'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import StepActivity from 'components/program/StepActivity.component'
import { showErrorToaster, showSuccessToaster } from 'utils/toasters'
import LoadingSpinner from 'components/LoadingSpinner.component'
import SubmitReview from 'components/program/SubmitReview.components'
import ProgramTasks from '../components/ProgramTasks.modal'
import { countDays } from 'utils/stringDateFunctions'
import { Step } from 'types/types'
import ContactMentee from 'components/chat-contact/ContactMentee.component'
import DoneBanner from '../components/DoneBanner.component'
import FailedBanner from 'app/pages-mentee/program/components/FailedBanner.component'

const StepInfo = () => {
  const { mentorshipTaskStepId, mentorshipId } = useParams()

  const [mentorship, setMentorship] = useState<IMentorshipAdditional | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [step, setStep] = useState<Step | null>(null)

  useEffect(() => {
    const fetchInfo = async () => {
      setIsLoading(true)
      const fetchedStep = await getMentorshipTaskStepInfo(mentorshipTaskStepId)
      if (fetchedStep.data) {
        setStep(fetchedStep.data)
      }
      const fetchedMentorships = await getMentorship(mentorshipId)
      if (fetchedMentorships.data) {
        setMentorship(fetchedMentorships.data)
      }
      setIsLoading(false)
    }

    fetchInfo()
  }, [])

  const [isToastSuccess, setIsToastSuccess] = useState<string>('')
  const [isToastFalse, setIsToastFalse] = useState<string>('')
  useEffect(() => {
    if (isToastSuccess !== '') {
      showSuccessToaster(isToastSuccess)
    }
  }, [isToastSuccess])
  useEffect(() => {
    if (isToastFalse !== '') {
      showErrorToaster(isToastFalse)
    }
  }, [isToastFalse])
  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className='w-100 row g-5 g-xl-8 row mb-10'>
            <div className='col-xl-6 d-flex flex-column'>
              <h1>{step?.name}</h1>
              <span className='text-secondary'>{step?.task?.name}</span>
              <span className='text-secondary'>{mentorship?.program.name}</span>
            </div>
            <div className='col-xl-6 d-flex flex-row align-items-center justify-content-sm-start justify-content-xl-end p-0'>
              <span
                className='bg-light-danger mx-lg-5 color-danger rounded d-flex align-items-center p-3 d-flex justify-content-end mx-3'
                style={{ color: 'red' }}
              >
                Due in {countDays(step?.started_at, step?.estimated_end_date)}
              </span>
              <ContactMentee
                menteeId={mentorship?.mentee_user.id}
                menteeName={mentorship?.mentee_user.name}
              />
            </div>
          </div>
          <div className='separator my-10'></div>

          <div className='row g-5 g-xl-8 mb-15 w-100'>
            <div className='col-xl-6 d-flex flex-column '>
              <p style={{ color: 'red' }}>Remaining Revisions:{step?.remaining_submission_count}</p>

              <h1>Step Description</h1>
              <div dangerouslySetInnerHTML={{ __html: step ? step?.description : '-' }}></div>
            </div>
            <div className='col-xl-6 d-flex justify-content-sm-start justify-content-xl-end'>
              <div className='card' style={{ width: '30%', maxHeight: 200 }}>
                <div className='px-10 card-body d-flex align-self-center justify-content-center'>
                  <img
                    className='d-flex align-self-center'
                    alt='Pic'
                    src={toAbsoluteUrl('/media/svg/general/Group.svg')}
                  />
                </div>
                <div className='card-footer p-0 m-0'>
                  <span
                    className='btn btn-primary h-100 w-100'
                    data-bs-toggle='modal'
                    data-bs-target='#view-all-tasks'
                  >
                    All Tasks
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='row g-5 g-xl-8 mb-7'>
            {step?.phase === 'pending_submission' && (
              <div className='col-xl-6'>
                <div className=' cursor-default bg-light btn btn-outline btn-outline-dashed btn-outline-default p-2 d-flex flex-column align-items-start'>
                  <div className='d-flex flex-row gap-5 '>
                    <img className='' alt='oval' src={toAbsoluteUrl('/media/svg/Oval.svg')} />
                    <div>
                      <p className='text-align-left mb-2 p-0 fw-bold' style={{ textAlign: 'left' }}>
                        Waiting for mentee submission!
                      </p>
                      <p className='text-secondary p-0 m-0' style={{ textAlign: 'left' }}>
                        You will be able to see a submission screen, if the mentee submit this task.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {step?.phase === 'pending_submission_reply' && (
              <div className='col-xl-6 card mt-10'>
                <ApproveReview
                  mentorship={mentorship}
                  step={step}
                  setStep={setStep}
                  setIsTaskSubmitted={setIsToastSuccess}
                  setIsToastError={setIsToastFalse}
                />
              </div>
            )}
            {(step?.phase === 'pending_review_both' || step?.phase === 'pending_review_mentor') && (
              <div className='col-xl-6 card mt-10'>
                <SubmitReview
                  user={'Mentor'}
                  setIsToastSuccess={setIsToastSuccess}
                  setIsToastError={setIsToastFalse}
                />
              </div>
            )}
            {step?.phase === 'closed' && (
              <div className='col-xl-6'>
                {
                  step.failed ? (
                    <FailedBanner />
                  ) : <DoneBanner />
                }

              </div>
            )}
            {step?.phase === null && <div className='col-xl-6'></div>}
            <div className='col-xl-6'>
              <StepActivity activities={step?.activities} />
            </div>
          </div>

          <ProgramTasks mentorship={mentorship} />
        </div>
      )}
      <ToastContainer />
    </div>
  )
}

export default StepInfo
