import { KTSVG, toAbsoluteUrl } from '_metronic/helpers'
import { downloadActivity, getFileDownloadLink } from 'app/modules/register/api/requests'
import React, { useEffect, useState } from 'react'
import { convertTimestamp } from 'utils/stringDateFunctions'

const ActivityInfo = ({ activity }) => {
    const dateTime = convertTimestamp(activity?.created_at)
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [link, setLink] = useState<string>();
    const [disabled, setIsDisabled] = useState<boolean>(false);
    useEffect(() => {
        console.log('123')
        const getLink = async () => {
            if (activity?.action?.submission) {
                setIsDisabled(true);
                const url = await downloadActivity(activity?.action?.submission?.id)
                console.log('url' + url)
                setLink(url);
                setIsDisabled(false);
            }
        }
        getLink()
    }, [activity])
    return (
        <div className="modal" tabIndex={-1} id="activity-info">
            <div className="modal-dialog d-flex align-items-center">
                <div className="modal-content d-flex align-items-center">
                    <div className="modal-header w-100 d-flex justify-content-between">
                        <h1 className="modal-title">{activity?.description}</h1>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <div className="modal-body w-100">
                        <div className="tab-content">
                            <div id="kt_project_targets_card_pane" className="tab-pane fade show active">
                                <div className="row g-9">
                                    <div className="col-10 mb-8 mt-5">
                                        {activity?.action?.submission?.approved && (
                                            <p className='mt-0' style={{ color: `${activity?.action?.submission?.approved ? '#50CD89' : "#F64E60"}` }}>{activity?.action?.submission?.approved ? "Approved" : "Rejected"}</p>
                                        )}
                                        <p>{activity?.description}</p>
                                    </div>
                                </div>
                                <div className='d-flex flex-row gap-3'>
                                    <span className='badge p-3 fs-7 fw-semibold' style={{ backgroundColor: '#EEF1F5' }}>{dateTime.date}</span>
                                    <span className='badge p-3 fs-7 fw-semibold' style={{ backgroundColor: '#EEF1F5' }}>{dateTime.time}</span>
                                    {activity?.action?.submission &&
                                        <div className='d-flex flex-row gap-3'>
                                            <img
                                                className='mt-1 align-self-start'
                                                alt='Pic'
                                                width='40'
                                                src={toAbsoluteUrl(
                                                    '/media/icons/duotune/files/pdf.png'
                                                )}
                                            />
                                            <div>
                                                <p className='m-0 fw-bold'>Submission</p>
                                                <a
                                                    target='_blank'
                                                    href={link ? link : '#'}
                                                    className={`btn p-0 m-0 text-hover-primary fw-thin ${(!link || disabled) ? 'disabled' : ''}`}
                                                > {isLoading ? 'Loading...' : 'View'}
                                                </a>

                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ActivityInfo
