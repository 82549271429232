import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProgramMentorships from './pages/MentorMentorships.page';
import MenteeInfo from './pages/MentorMenteeInfo';
import Programs from './pages/MentorPrograms.page';
import ApproveTask from './pages/MentorStepInfo';


const MentorProgramWrapper = () => {
    return (
        <Routes>
            <Route
                path='/*'
                element={<Programs />}>
            </Route>
            <Route
                path='/:programId'
                element={<ProgramMentorships />}>
            </Route>
            <Route
                path='/:programId/mentorship/:mentorshipId/mentee/:menteeId'
                element={<MenteeInfo />}>
            </Route>
            <Route
                path='/:programId/mentorship/:mentorshipId/mentee/:menteeId/mentorship-task-steps/:mentorshipTaskStepId'
                element={<ApproveTask />}>
            </Route>
        </Routes>
    );
};

export default MentorProgramWrapper;